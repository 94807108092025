import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
  userTypedRepairReasons,
  selectedRepairReasonObject,
  eligibilityResponseObject,
} from "../../ducks/redux/actions/dashboardReducer.actions";
import {
  userChosenPropertyId,
  userChosenHighLevelDescription,
  userChosenUPRN,
  userChosenSOR,
  userChosenSTATUS,
  userChosenEligibilityErrorCode,
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  isEditState,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenDuplicateQuestion,
  userChosenEmergencyQuestion,
} from "../../ducks/redux/actions/userChoices";
import { PremisesType } from "../../models/Customer";
import * as api from "../../api";
import { useTranslation } from "react-i18next";
import {
  EligibilityErrorCode,
  MaintenanceResponsibility
} from "../../models/Property";
import Loader from "react-loader-spinner";
import { RouteComponentProps } from "react-router-dom";
import {
  getHeatingHotWaterCase,
  getMaintResp,
  getRepairResp,
} from "../../ducks/redux/actions/getAddress";
import Message from "../../storybook/Message";
import { isNullOrUndefined } from "../../utils/formatting";

export interface RepairReasonProps extends RouteComponentProps<any> {
  suggestions: string[];
  setButtonDisabled: (a: boolean) => void;
  locatioAlert: string[];
  getMandatoryData: (args: any) => void;
}
const RepairReason: React.FC<RepairReasonProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  let suggestionList;
  const [eligible, setEligible] = React.useState("none");
  const [errorCode, setErrorCode] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [userInput, setUserInput] = useState();
  const [inputValue, setInputValue] = useState("");
  const [isOrbisBlockMsg, setIsOrbisBlockMsg] = React.useState(false);
  const [isSelectedValue, setSelectedValue] = React.useState(false);
  const [repairReasonEvent, setRepairReasonEvent] = React.useState(null);

  const selectedRepairReasonData = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonObject
  );
  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );

  const [fetchedRepairReasonsData, setData] = useState(props.suggestions);

  //Check Repair Eligibility
  let premisesType = "";
  let maintsResp = "FMFULL";

  let repairResp = "";
  let additionalRepairInfo = false; //!!asset?.description //??
  let isRepairEligible = true;
  let isCustomerView = false; // to do discuss
  let uprn = ""; // asset store - filter
  let sorCode: string | null = "";
  let isInDefects = false; //got
  let heatingRepairContext: string | null = "";


  const premisesid = useSelector(
    (state: any) => state?.dashboardReducer?.formState?.tracks[0]?.assetId
  );

  //fetch typed object
  const fetchedRepairReasonsSelection = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.fetchedRepairReasonsSelect
  );
  const addressesData = useSelector(
    (state: any) => state?.addresses?.customerassociatedassets
  );

  const selectedproperty = useSelector(
    (state: any) => state?.dashboardReducer?.formState?.tracks[0]?.assetType
  );

  const userChosenHighLevelDescriptionBackButton = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenHighLevelDescription
  );

  const RepairLocationId = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenRepairLocationId
  );

 

  useEffect(() => {

    if (props.history.action === "POP" || userChosenHighLevelDescriptionBackButton) {
      setInputValue(userChosenHighLevelDescriptionBackButton);     
      repairReasonChangeAction("pageInit", userChosenHighLevelDescriptionBackButton); 
    }

  }, []);


  useEffect(() => {
    props.getMandatoryData(inputValue);
  }, [inputValue]);

  const getPremisesData = async (value: string) => {
    try {
      setIsLoading(true);
      const selectedReason = fetchedRepairReasonsSelection.filter(
        (reason: any) => reason.description === value
      );
      dispatch(selectedRepairReasonObject(selectedReason));
      //call api and set params values
      const eligibilityResponse = await api.getAssetsByid(premisesid);
      dispatch(userChosenPropertyId(eligibilityResponse.id));
      if (eligibilityResponse && selectedReason?.length > 0) {
        // setIsLoading(false)
        dispatch(eligibilityResponseObject(eligibilityResponse));
        premisesType = eligibilityResponse?.assetType;

        maintsResp =
          eligibilityResponse?.maintenanceResponsibilities?.find(
            (resp: any) => resp.type === "MAINTSRESP"
          )?.value ?? "";
        repairResp =
          eligibilityResponse?.maintenanceResponsibilities?.find(
            (resp: any) => resp.type === selectedReason[0]?.element
          )?.value ?? "";

        if (selectedReason[0]?.additionalData?.length > 0) {
          additionalRepairInfo =
            selectedReason[0]?.additionalData[0]?.description != ""
              ? true
              : false;
        }
        else {
          additionalRepairInfo = false
        }

        //start logic to get isEligibleRepair
        const asset = selectedReason[0].additionalData.find(
          (item: any) =>
            item.assetType === selectedproperty &&
            item.tenancyTypes?.some(
              (tt: any) =>
                tt.toLowerCase() ===
                eligibilityResponse?.tenancyType?.toLowerCase()
            )
        );

        isRepairEligible = asset?.isEligible;
        // end
        isCustomerView = true; // as discussed true for customer search
        uprn = eligibilityResponse.uprn;
        sorCode = selectedReason[0]?.sors[0]?.sorCode;
        const status =
          eligibilityResponse.statuses &&
          eligibilityResponse.statuses.isInDefects;
        isInDefects = status;
        dispatch(userChosenSOR(sorCode));
        dispatch(userChosenSTATUS("Unappointed,Appointed,In Progress"));

        dispatch(getMaintResp(maintsResp));
        dispatch(getRepairResp(repairResp));
        
        // Implementation for Heating or hot water repair US
        let maintenanceResponsibilities: MaintenanceResponsibility[] = [];
        if (value === "Heating or hot water repair" || sorCode === "COMHIU") {
          try {
            // get maintenanceResponsibilities for all assets
            addressesData.forEach((element: any) => {
              const promises = element.map((item: any) => {
                return api.getAssetsByid(item.id);
              });
              Promise.all(promises).then((results) => {
                results.forEach((item: any) => {
                  const responsibilities = heatingAttributeFromResponsibilities(
                    item.maintenanceResponsibilities
                  );
                  const rep = {
                    type: item.assetType,
                    value: responsibilities,
                  };
                  maintenanceResponsibilities.push(rep);
                });

                if (
                  maintenanceResponsibilities &&
                  maintenanceResponsibilities.length > 0
                ) {
                  const propertyAttribute =
                    maintenanceResponsibilities.filter((item) => {
                      return item.type === PremisesType.Property
                        ? item.value
                        : "";
                    })[0]?.value ?? "";
                  const dividedBlockAttribute =
                    maintenanceResponsibilities.filter((item) => {
                      return item.type === PremisesType.DividedBlock
                        ? item.value
                        : "";
                    })[0]?.value ?? "";
                  const blockAttribute =
                    maintenanceResponsibilities.filter((item) => {
                      return item.type === PremisesType.Block ? item.value : "";
                    })[0]?.value ?? "";
                  const estateAttribute =
                    maintenanceResponsibilities.filter((item) => {
                      return item.type === PremisesType.EstateArea;
                    })[0]?.value ?? "";

                  // call sor determination api
                  const sorDeterminationResponse = api.getSorDetermination({
                    propertyAttribute,
                    dividedBlockAttribute,
                    blockAttribute,
                    estateAttribute,
                    context: eligibilityResponse.assetType as PremisesType,
                  });
                  Promise.resolve(sorDeterminationResponse).then(async (results) => {
                    if (results) {
                      if (sorCode === "COMHIU" && results?.repairContext !== "N/A") {
                        heatingRepairContext = results.repairContext;
                        dispatch(userChosenSOR(sorCode));
                        setEligible("none");
                        checkRepairEligibility(selectedReason);
                      }
                      else if (results?.errorCode) {
                        if (results.errorCode === "E32") {
                          setErrorCode("SSR047");
                          setEligible("block");
                          if (isSelectedValue) { props.setButtonDisabled(false) }
                          dispatch(userChosenSOR(""));
                          setIsLoading(false);
                          dispatch(getHeatingHotWaterCase(results.errorCode));
                        } else {
                          setErrorCode("SSR049");
                          setEligible("block");
                          props.setButtonDisabled(true);
                          setIsLoading(false);
                          dispatch(userChosenSOR(results.sor));
                        }
                      } else {
                        sorCode = results.sor;
                        dispatch(userChosenSOR(results?.sor));
                        setEligible("none");
                        checkRepairEligibility(selectedReason);
                      }
                  // call Enhanced Repair Eligibility api
                  const advancedPayload = {
                    "isEmergency": selectedReason[0].priority == "Emergency" ?? false,
                    "sor": results.sor || undefined,
                    "channel": "CustomerSelfService"
                  };
                  const response = await api.postEnhancedRepairEligibility(advancedPayload);                  
                  if(response?.isEligible==false && response?.errorCode != null && response?.errorCode == "E35"){
                    setIsOrbisBlockMsg(true);
                    setErrorCode("");
                    dispatch(userChosenSOR(results?.sor));
                  } 
                  if(response?.isEligible==false || response?.isEligible==true) {
                    dispatch(userChosenSOR(results?.sor));
                  }
                }                   
                  });
                }
              });
            });
          } catch (e) {
            setIsLoading(false);
          }
        } else {
          //call eligibility api
          checkRepairEligibility(selectedReason);
        }
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  const checkRepairEligibility = async (selectedReason: any) => {
    try {
      //call api and set errorMessage
      const eligibilityResponse = await api.getRepairEligibility(
        premisesType,
        maintsResp,
        repairResp,
        additionalRepairInfo,
        isRepairEligible,
        isCustomerView,
        sorCode,
        uprn,
        isInDefects,
        heatingRepairContext
      );
      dispatch(userChosenEligibilityErrorCode(eligibilityResponse));
      if (eligibilityResponse != null && eligibilityResponse.length > 0) {
        setIsLoading(false);
        if (isSelectedValue) { props.setButtonDisabled(false) }
        const isEligible = eligibilityResponse[0].isEligible;
        const error = isEligible
          ? eligibilityResponse[0].errorCode != EligibilityErrorCode.SSR037
            ? eligibilityResponse[0].errorCode != EligibilityErrorCode.E23
              ? ""
              : eligibilityResponse[0].errorCode
            : eligibilityResponse[0].errorCode
          : eligibilityResponse[0].errorCode;
        switch (error) { 
          case EligibilityErrorCode.E3:
            setErrorCode(error);
            props.setButtonDisabled(true);
            setEligible("none");
            break;
          case EligibilityErrorCode.E4:
          case EligibilityErrorCode.E8:
          case EligibilityErrorCode.SSR037:
          case EligibilityErrorCode.E21:
          case EligibilityErrorCode.E22:
          case EligibilityErrorCode.E23:
          case EligibilityErrorCode.E24:
          case EligibilityErrorCode.E25:
          case EligibilityErrorCode.SSR005:
          case EligibilityErrorCode.SSR069:
            setErrorCode(error);
            props.setButtonDisabled(true);
            setEligible("block");
            break;
          default:
            if (isSelectedValue) { props.setButtonDisabled(false) }
            break;
        }

        if (!isRepairEligible) {
          setEligible("block");
        } else {
          setEligible("none");
          if (isSelectedValue) { props.setButtonDisabled(false) }
        }

        if (!isEligible) {
          if (
            error == EligibilityErrorCode.E2 &&
            selectedReason[0]?.additionalData[0]?.description != ""
          ) {
            setEligible("none");
            if (isSelectedValue) { props.setButtonDisabled(false) }
          } else {
            setEligible("block");
            props.setButtonDisabled(true);
            if (error == EligibilityErrorCode.E2)
              setErrorCode(EligibilityErrorCode.E2);
          }
        } else {
          if (
            eligibilityResponse[0].errorCode === EligibilityErrorCode.SSR037 ||
            eligibilityResponse[0].errorCode === EligibilityErrorCode.E23
          ) {
            setEligible("block");
          }
          if (isSelectedValue) { props.setButtonDisabled(false) }
        }
      }
    } catch (e) {
      props.history.push("/genericerror");
      setIsLoading(false);
      console.error(e);
    }
  };

  const inputTextChangeHandler = (event: any, newValue: any) => {    

    setSelectedValue(true);
    setRepairReasonEvent(event);
    
    if (EditState === true) {
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(userChosenEmergencyQuestion(""));
      dispatch(isEditState(false));
      dispatch(userChosenVandalismAdditionalInformation(""));
      dispatch(userChosenVandalismQuestion("No"));
      dispatch(userChosenAdditionalDescription(""));
      dispatch(userChosenDuplicateQuestion(""));
    }
    setUserInput(newValue);
    dispatch(userChosenHighLevelDescription(newValue));
    setIsLoading(true);
    setIsOrbisBlockMsg(false);
    setData(props.suggestions);
  };


  const suggestionClickHandler = (event: any, newInputValue: any) => {
    repairReasonChangeAction(event?.type, newInputValue);
  };

  useEffect(() => {
    suggestionClickHandler(repairReasonEvent, userInput);
  }, [isSelectedValue, repairReasonEvent, userInput]);

  const repairReasonChangeAction = (eventType: any, newInputValue:any) => {
    if (EditState === true) {
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(userChosenEmergencyQuestion(""));
      dispatch(isEditState(false));
      dispatch(userChosenVandalismAdditionalInformation(""));
      dispatch(userChosenVandalismQuestion("No"));
      dispatch(userChosenAdditionalDescription(""));
      dispatch(userChosenDuplicateQuestion(""));
    }
    
    // NOTE 
    // Keyboard type: suggestionClickHandler, which is fine
    // User Select from Drop down: suggestionClickHandler => inputTextChangeHandler => suggestionClickHandler

    // ie. Continue will be enabled for appropriate messageing when page is initialized
    props.setButtonDisabled((!isNullOrUndefined(eventType) && eventType != "pageInit"));
    if(!isNullOrUndefined(eventType) && (eventType != "click" || isSelectedValue == true)) {
      setSelectedValue(false)
      setInputValue(newInputValue);
      if (newInputValue != "") {
        props.setButtonDisabled(true)
        setData(props.suggestions);
        dispatch(userTypedRepairReasons(newInputValue));
        dispatch(userChosenSOR(""));
        setErrorCode("");
        setEligible("none");
        getPremisesData(newInputValue);
      } else {
        // ie. Continue will be enabled for appropriate messageing when HLD is blank
        props.setButtonDisabled(false)
      }
    }
  }
  
  if (
    props.locatioAlert &&
    props.locatioAlert.length > 1 &&
    !inputValue &&
    (RepairLocationId == 0 ||
      RepairLocationId === "No option selected global-txt")
  ) {
    suggestionList = (
      <div data-testid="alert-warning" role="alert" placeholder="alert-message">
        <Message className="error-msg" data-testid="locatioAlert_0">
          {props.locatioAlert[0]}
        </Message>
      
        <Message className="error-msg" data-testid="locatioAlert_1">
          {props.locatioAlert[1]}
        </Message>
      </div>
    );
  } 
  else if(isOrbisBlockMsg){
    props.setButtonDisabled(true);
    suggestionList = (
      <Message className="warning-msg" data-testid="OrbisBlock_warning">
      {t("OrbisBlockMsg1")}<br/>
      {t('OrbisBlockMsg2')} <strong>{t('OrbisBlockNumber')}</strong> {t('OrbisBlockMsg3')}
    </Message>
    );
  }
  else if (
    props.locatioAlert &&
    props.locatioAlert.length == 1 &&
    (!inputValue ||
      RepairLocationId == 0 ||
      RepairLocationId === "No option selected global-txt")
  ) {
    suggestionList = (
      <Message className="error-msg" data-testid="alert-warning">
      <span data-testid="locatioAlert">{props.locatioAlert}</span>
    </Message>
    );
  } else if (!fetchedRepairReasonsData.length && inputValue) {
    props.setButtonDisabled(true);
    suggestionList = (
      <Message className="error-msg">
      {t("Repair_Reason_NoMatch_Message")}
      </Message>
    );
  } else if (errorCode == EligibilityErrorCode.SSR037 && !inputValue) {
    let errorCodeArray: string[] = [];
    errorCodeArray = t(errorCode).split("?");
    let errorCodeValue = errorCodeArray[0];
    errorCodeArray = errorCodeArray.slice(1);
    let items = errorCodeArray.map((item, index) =>
      errorCode == EligibilityErrorCode.SSR037 ? (
        <strong>
          <li key={index}>{item}</li>
        </strong>
      ) : (
        <li key={index}>{item}</li>
      )
    );
    suggestionList = (
      <div
        data-testid="alert-warning"
        style={{ display: eligible }}
        className="alert alert-warning error-reason global-txt top-btm-padding"
        role="alert" placeholder="alert-message"
      >
        <span className="global-error-icon covid-error">
          <i className="fas fa-exclamation me-2"></i>
        </span>
        {errorCodeValue}
        {errorCode == EligibilityErrorCode.SSR037 ? (
          <ol>{items}</ol>
        ) : (
          <ul>{items}</ul>
        )}
      </div>
    );
  } else if (
    (errorCode == EligibilityErrorCode.SSR037 ||
      errorCode == EligibilityErrorCode.E21) &&
    inputValue
  ) {
    let errorCodeArray: string[] = [];
    errorCodeArray = t(errorCode).split("?");
    let errorCodeValue = errorCodeArray[0];
    errorCodeArray = errorCodeArray.slice(1);
    let items = errorCodeArray.map((item, index) =>
      errorCode == EligibilityErrorCode.SSR037 && [3, 4, 5].includes(index) ? (
        <strong>
          <li key={index}>{item}</li>
        </strong>
      ) : index === 2 && errorCode == EligibilityErrorCode.SSR037 ? (
        <li key={index}>
          {item.split(":")[0]}:<strong>{item.split(":")[1]}</strong>
        </li>
      ) : (
        <li key={index}>{item}</li>
      )
    );
    suggestionList = (
      <div
        data-testid="alert-warning"
        style={{ display: eligible }}
        className="warning mb-8"
        role="alert"
        placeholder="alert-message"
      >
        <div className="w-full px-2 py-2 bg-red-20 flex items-center">
          <div className="px-4 pr-8">
            <i className="fas fa-exclamation-circle me-2 text-brick-100 w-6 h-6 bolder-font"></i>
          </div>
          <div className="flex items-center text-black">
            <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
              {errorCodeValue}
              {errorCode == EligibilityErrorCode.SSR037 ? (
                <ol>{items}</ol>
              ) : (
                <ul>{items}</ul>
              )}
            </p>

          </div>
        </div>
      </div>
    );
  } else if (errorCode == EligibilityErrorCode.E23 && !inputValue) {
    suggestionList = (
      <div
        data-testid="alert-warning"
        style={{ display: eligible }}
        className="alert alert-warning error-reason global-txt"
        role="alert" placeholder="alert-message"
      >
        <span className="global-error-icon covid-error">
          <i className="fas fa-exclamation me-2"></i>
        </span>
        <span className="remove-margin-left">{t(errorCode)}</span>
      </div>
    );
  } else if (errorCode && inputValue) {
    suggestionList = (
      <Message className="error-msg" data-testid="alert-warning">
      {t(errorCode)}
      </Message>
    );
  } else if (isLoading) {
    suggestionList = (
      <Loader
        type="ThreeDots"
        color="#00BFFF"
        height={50}
        width={50}
        timeout={5000}
      />
    );
  } 

  return (
    <>
      <div className="pt-24">
        <h6 className="pb-12">{t("Repair_Reason_Main_Header_Text")}</h6>
      
      {fetchedRepairReasonsData && (
        <Autocomplete
          data-testid="high-level-repair"
          onChange={inputTextChangeHandler}
          inputValue={inputValue}
          onInputChange={suggestionClickHandler}
          id="high-level-repair"
          freeSolo
          disableClearable
          filterOptions={(options, state) => props.suggestions}
          options={props.suggestions}
          size="small"
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input {...params.inputProps}  
                placeholder={t("repair_reason_placeholder")}
                className="txtBox-Input m-0"
                data-hj-allow =  ""
              />
            </div>
          )}
        />
      )}
      {suggestionList}
      </div>
    </>
  );
};

export const heatingAttributeFromResponsibilities = (
  responsibilities: MaintenanceResponsibility[]
) => {
  if (!responsibilities) return "";

  const elementsToCheck: readonly string[] = [
    "HEAT",
    "HEATGAS",
    "HEATOIL",
    "HEATELEC",
  ];
  return responsibilities
    .filter((resp) => elementsToCheck.includes(resp.type))
    .map((resp) => resp.value)
    .filter((value) => {
      const valueLowerCase = value?.toLowerCase();
      return (
        !!value &&
        valueLowerCase !== "null" &&
        valueLowerCase !== "unknown" &&
        valueLowerCase !== "none"
      );
    })
    .join("+");
};

export default RepairReason;
