import React, { useEffect, useState } from "react";
import logo from "../../assets/img/reduce-bill.png";
import catalystlogo from "../../assets/image/catalyst.jpg";
import { RepairStatusField } from "../../models/Repair/Repair";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PremisesType } from "../../models/Customer/Premises";
import Repair, { RepairsResponse } from "../../models/Repairs";
import * as api from "../../api";
import "../../compositions/Customer360View/index.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import {
  AssocitaedAssetsType,
  RelatedPremisesType,
} from "../../models/Property/AdminUnits";
import { formatDate } from "../../utils/formatting";
import Tenancies from "../../models/Customer/Tenancies";
import Customer, {
  CustomerResponse,
  TenancySummary,
} from "../../models/Customer";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import CustomerIdentifierModel from "../../models/Customer/CustomerIdentifierModel";
import { updateDashboardForm } from "../../ducks/redux/actions/dashboardReducer.actions";
import {
  appConfigCovidToggle,
  appConfigVandalismToggle,
  appConfigToggle,
  getCustomerId,
  selectedTenacyType,
  workOrderRepairObject,
  appConfigCaseCardToggle,
  saveCustomerphoneNumber,
  getReferenceNumber,
  getPrefferenceNumber,
  getHeatingHotWaterCase
} from "../../ducks/redux/actions/getAddress";
import { BalanceType } from "../../models/Accounts/BalanceType";
import AccountDetails, {
  TransactDeatils,
} from "../../models/Accounts/AccountDetails";
import LoadingSpinner from "../../storybook/LoadingSpinner";
import { trackEvent, trackException } from "../../appInsights/TelemetryService";
import { v4 } from "uuid";
import {
  userChosenPropertyId,
  userChosenPropertyType,
  userChosenPropertyAddress,
  userChosenRepairLocationId,
  userChosenRepairLocation,
  userChosenHighLevelDescription,
  userChosenSupplementaryQuestions,
  userChosenEmergencyQuestion,
  userChosenDuplicateQuestion,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenCovidQuestion,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenContactDetails,
  userChosenUPRN,
  userChosenSOR,
  userChosenSTATUS,
  userChosenEligibilityErrorCode,
  loginTime,
  loginStatus,
  initialLoginTime,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  userProfileETag,
  userProfileData,
  resumeDraftRepair,
  userChosenIsReporter,
  userChosenIsError,
  myMessageData,
  userChosenResumeRepair,
  loadDuplicateRepairPage,
  userCreatedNewRepair,
  getAllAssetIdentifiers,
  validateCatalystCustomer,
  validateCatalystDisabledArea,
  catalystWorkOrderToggleValue,
  clearMediaFiles,
  selectedTabValue,
  showMenuTabs,
  showFooter,
  getBlockIdentifier,
  assetRegion,
  userNavigatedFrom,
  userChosenAccountNumber,
  validateCaseCatalystToggle
} from "../../ducks/redux/actions/userChoices";
import { RepairChannel } from "../../models/Repair/RepairChannel";
import { defaultSearchArea, defaultSearchRepairStatus, defaultSearchSortByOrder, repairDefaultAssocitaedAsset, repairHistoryData, repairSearchTerm } from "../../ducks/redux/actions/historicRepair";
import MyMessagesCard from "../MyMessages/MyMessagesCard";
import CaseCard from "../Cases/CaseCard";
import { CASE_SELECTED_TENANCY } from "../../ducks/redux/actionTypes";
import { clearReferralInformation, comingFromCaseJourneySuccess, comingFromRepairJourneySuccess, saveSelectedSlotDate, setTriageRepair } from "../../ducks/redux/actions/FinancialReferral";
import {
  CASE_SELECTED_ANSWERS,
  CLEAR_CASE_STATE,
} from "../../ducks/redux/actionTypes";
import HighRise from "../HighRise";
import Message from "../../storybook/Message"
import Button from "../../storybook/Button";
import SuccessMessage from "../SuccessMessage";


enum UserNavigatedStreams {
  CaseFinalConfirmation = "CaseFinalConfirmation",
};

interface Customer360ViewProps extends RouteComponentProps<any> { }
const Customer360View: React.FC<Customer360ViewProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isAccount, setIsAccount] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState("none");
  const [balanceCss, setBalanceCss] = React.useState("color-black pb-20")
  const [isArrear, setIsArrear] = React.useState(false)

  let assetBlockPropertyIds: any = [];
  const [financialReferralToggle, setFinancialReferralToggle] = useState("true")

  enum TenancyTypeMappingEnum {
    AGENCY = "AGENCY",
    COM1 = "COM1",
    HMBY2010 = "HMBY2010",
    OFFICE = "OFFICE",
    USENOCCU = "USENOCCU",
  }
  const RepairBlockMap = {
    Property: " property",
    "Divided block": " block",
    Block: " block",
    Estate: " estate",
  };
  const [defaultAssociatedAsset, setDefaultAssociatedAsset] = useState("");
  const [area, setArea] = useState(false);
  const [isCustInfo, setIsCustInfo] = useState(false);
  const customerIdValue = useSelector(
    (state: any) => state.addresses?.customerid
  );
  const blockAssetId = useSelector((state: any) => state.userChoicesReducer?.blockIdentifier);
  const array: Repair[] = [];
  const [custResponse, setCustResponse] = useState<CustomerResponse>();
  const [customerContact, setCustomerContact] = useState<Customer>();
  const [accountDetails, setAccountDetails] = useState<AccountDetails>();
  const arrayArea: Array<{ key: string; value: string }> = [];
  const [associatedAssetList, setAssociatedAssetsList] = useState(arrayArea);
  const [showMyMessages, setShowMyMessages] = useState();
  const [catalystCustomer, setCatalystCustomer] = useState(false);
  const [catalystCaseCardToggle, setCatalystCaseCardToggle] = useState("false");
  const [catalystWorkOrderToggle, setCatalystWorkOrderToggle] =
    React.useState("false");
  const [catalystRepairDisabledAreas, setCatalystRepairDisabledAreas] = useState(false);
  const [isUserRegistered, setIsUserRegistered] = useState(false);

  const isCatalyst = useSelector(
    (state: any) => state.userChoicesReducer?.isCatalystCustomer
  );
  const [assetIdentifiersAll, setassetIdentifiersAll] = useState("");
  const loggedInStatus = useSelector(
    (state: any) => state?.userChoicesReducer?.loginStatus
  );
  let PropertyId = useSelector(
    (state: any) => state.userChoicesReducer?.userChosenPropertyId
  );

  let userNavigatedStream = useSelector(
    (state: any) => state.userChoicesReducer?.userNavigatedFrom
  );

  const [navigatedFromStream,setNavigatedFromStream] = useState(userNavigatedStream);



  const [assetProperty, setAssetProperty] = useState(PropertyId);

  const queryStringSearch = useLocation().search;

  const appConfigValue = useSelector(
    (state: any) => state?.addresses?.toggleValue
  );

  const appCaseCardconfigValue = useSelector(
    (state: any) => state?.addresses?.caseCardtoggleValue
  )

  const getFinancialReferralToggleValue = async() => {
    try {
        const response = await api.GetAppConfigToggleValue("IsMyPeabodyCatalystFinancialEnquiry");
        setFinancialReferralToggle(response.value)
      }catch(e:any){
          props.history.push("/GenericError");   
      }
  }


  const getCaseCardRequiredToggle = async () => {
    try {
      const response = await api.GetAppConfigToggleValue(
        "mypeabodycatalystcases"
      );
      setCatalystCaseCardToggle(response?.value);      
      dispatch(validateCaseCatalystToggle(response?.value))
    } catch (e: any) {
      console.log(e);
    }
  };

  const getWorkOrderRequiredToggle = async () => {
    try {
      const response = await api.GetAppConfigToggleValue(
        "mypeabodycatalystworkorders"
      );
      setCatalystWorkOrderToggle(response?.value);
      dispatch(catalystWorkOrderToggleValue(response?.value));
    } catch (e: any) {
      console.log(e);
    }
  };


  const [repairList, setRepairList] = useState(array);

  let newAssociatedAssets: any[] = [];
  const customerIdentifierModel = new CustomerIdentifierModel();

  useEffect(()=>{
    if(navigatedFromStream === UserNavigatedStreams.CaseFinalConfirmation) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  })

  useEffect(() => {
    let timerId:any;
    (async () => {
      trackEvent("Customer360View");
      dispatch(showMenuTabs(true));
      dispatch(showFooter(true));
      dispatch(selectedTabValue("home"))
      clearReduxStore();
      try {
        const paramError =
          new URLSearchParams(queryStringSearch).get("error") || "";
        if (paramError === "access_denied" || paramError === "server_error") {
          const paramResetPassword =
            new URLSearchParams(queryStringSearch).get("state") || "";
          const paramError =
            new URLSearchParams(queryStringSearch).get("error_description") ||
            "";
          const _customerId = localStorage.getItem("customerId") || "";

          let error = "AADB2C99002";
          if (paramError.includes(error)) {
            dispatch(userChosenIsError(true));
            timerId = setTimeout(() => {
              props.history.push("/genericerror");
            }, 1000);
            return;
          }
          if (
            !isUndefinedNullOrEmpty(_customerId) &&
            paramResetPassword === "changepassword"
          ) {
            customerIdentifierModel.customerId = _customerId;
            dispatch(updateDashboardForm(_customerId));
            dispatch(getCustomerId(_customerId));
            dispatch(loginStatus(true));
            getCustomer360DetailsBind(_customerId);
            history.push("/customer360View");
          } else {
            history.push("/startpage");
            return;
          }
        }

        if (
          isUndefinedNullOrEmpty(loggedInStatus) &&
          paramError != "access_denied" &&
          paramError != "server_error"
        ) {
          const authCode =
            new URLSearchParams(queryStringSearch).get("code") || "";
          if (!isUndefinedNullOrEmpty(authCode)) {
            dispatch(showMenuTabs(true));
            dispatch(showFooter(true));
            try {
              localStorage.setItem("corelationId", v4());
              localStorage.setItem("sessionId", v4());

              if (authCode === "signout") {
                return;
              }
              setIsLoading(false);
              let responseData: string = "";
              const state =
                new URLSearchParams(queryStringSearch).get("state") || "";
              if (state === "changepassword") {
                responseData = await api.GetCustomerId(
                  authCode,
                  "changepassword"
                );
              } else if (state === "registration") {
                setIsUserRegistered(true);
                responseData = await api.GetCustomerId(
                  authCode,
                  "registration"
                );
              } else {
                responseData = await api.GetCustomerId(authCode, "login");
              }
              Promise.all([responseData]).then(async (responseResults) => {
                if (responseResults[0]) {
                  const utcLogintime = new Date().toISOString();
                  if (isUndefinedNullOrEmpty(responseResults[0])) {
                    dispatch(loginStatus(false));
                    return;
                  }
                  customerIdentifierModel.customerId = responseResults[0];
                  dispatch(loginTime(utcLogintime));
                  dispatch(initialLoginTime(utcLogintime));
                  dispatch(
                    updateDashboardForm(customerIdentifierModel?.customerId)
                  );
                  dispatch(getCustomerId(customerIdentifierModel.customerId));
                  dispatch(loginStatus(true));
                  localStorage.setItem(
                    "customerId",
                    customerIdentifierModel?.customerId
                  );
                  dispatch(workOrderRepairObject(null));
                  getCustomer360DetailsBind(customerIdentifierModel.customerId);
                }
              });
              setIsLoading(true);
            } catch (e: any) {
              const _channel = process.env.REACT_APP_X_CHANNEL_IDENTIFIER;
              const _customerId = localStorage.getItem("customerId") || "";
              const _corelationId = localStorage.getItem("corelationId") || "";
              const _sessionId = localStorage.getItem("sessionId") || "";
              trackException(
                e,
                _channel,
                _customerId,
                _corelationId,
                _sessionId
              );
              console.error(e);
              timerId = setTimeout(() => {
                props.history.push("/genericerror");
              }, 1000);
            }
          }
          else {
            props.history.push("/startpage");
          }
        } else {
          if (loggedInStatus && !isUndefinedNullOrEmpty(customerIdValue)) {
            getCustomer360DetailsBind(customerIdValue);
            dispatch(workOrderRepairObject(null));
          }
        }
      } catch (e) {
        console.error(e);
        timerId = setTimeout(() => {
          props.history.push("/genericerror");
        }, 1000);
      }
    })();
    dispatch(userNavigatedFrom(""));
    return () => {
      clearTimeout(timerId);
    }; 
  }, []);

  useEffect(() => {
    dispatch(workOrderRepairObject(null));
    dispatch(userChosenResumeRepair(false));
    dispatch(resumeDraftRepair(false));
    dispatch(loadDuplicateRepairPage(false));
    dispatch(userCreatedNewRepair(null));
    dispatch(repairDefaultAssocitaedAsset(null));
    if (isCatalyst !== undefined) {
      setCatalystCustomer(isCatalyst)
    }
    let timer = setTimeout(() => {
      getCaseCardRequiredToggle();
      getWorkOrderRequiredToggle();
      getFinancialReferralToggleValue();
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleChangeAssociate = (e: { target: { value: any } }) => {
    setDefaultAssociatedAsset(e.target.value);
    dispatch(userChosenPropertyId(e.target.value));
    setAssetProperty(e.target.value);
    fetchGetRepairsData(e.target.value);
    checkforLandlordValues(e.target.value);
  };

  const checkforLandlordValues = async (assetId: string) => {
    setIsLoading(false)
    let isCatalystRegistrationAreas = false;
    const assetrResponse = await api.getAssetsByid(assetId);
    const appConfigLandlordValues = await api.GetAppConfigToggleValue(
      "LandlordValues"
    );

    const appCatalystRepairDisabledAreasValues = await api.GetAppConfigToggleValue(
      "CatalystRepairDisabledAreas"
    );

    const appCatalystRegistrationAreasValues = await api.GetAppConfigToggleValue(
      "CatalystRegistrationAreas"
    );
    dispatch(assetRegion(assetrResponse?.region));
    
    if (!isUndefinedNullOrEmpty(assetrResponse?.region) && appCatalystRegistrationAreasValues.value.toLowerCase().includes(assetrResponse?.region.toLowerCase())) {
      isCatalystRegistrationAreas = true;
    }

    let appconfigLandlordValues = appConfigLandlordValues?.value;
    let associatesdata;
    try {
      associatesdata = await api.getCaseAssetAssociate(assetId);
      let currentLandlord =
        associatesdata?.associates?.landLord?.organisation?.name;
      let previousLandLord =
        associatesdata?.associates?.previousLandLord?.organisation?.name;
      if (isCatalystRegistrationAreas && (
        appconfigLandlordValues.includes(currentLandlord) ||
        appconfigLandlordValues.includes(previousLandLord))
      ) {
        const isCatalystDisabledArea = !isUndefinedNullOrEmpty(assetrResponse?.region) && appCatalystRepairDisabledAreasValues.value.toLowerCase().includes(assetrResponse?.region.toLowerCase());
        setCatalystRepairDisabledAreas(isCatalystDisabledArea);
        setCatalystCustomer(true);
        dispatch(validateCatalystCustomer(true));
        dispatch(validateCatalystDisabledArea(isCatalystDisabledArea));
      } else {
        setCatalystCustomer(false);
        dispatch(validateCatalystCustomer(false));
        dispatch(validateCatalystDisabledArea(false));
      }
      setIsLoading(true)
    } catch (e: any) {
      console.log(e);
    }


  };

  const handleChangeOnBack = (e: any) => {
    setDefaultAssociatedAsset(e);
    dispatch(userChosenPropertyId(e));
    setAssetProperty(e);
    fetchGetRepairsData(e);
  };

  const getCustomer360DetailsBind = (customerId: string) => {
    (async () => {
      try {
        if (customerId !== null && customerId !== undefined) {
          setIsLoading(false);
          const customerResponse = await api.getCustomer(customerId);
          localStorage.setItem("external-identifier", customerResponse?.["external-identifier"]);
          const tenancyResponse = await api.getTenancies(customerId);
          const customerContactParamsEntry: Customer = new Customer();
          customerContactParamsEntry.workPhone =
            customerResponse?.addresses.telecomsAddresses.find(
              (a) => a.telecomsAddressType === "workPhone"
            )?.number || "";
          customerContactParamsEntry.mobilePhone =
            customerResponse?.addresses.telecomsAddresses.find(
              (a) => a.telecomsAddressType === "mobilePhone"
            )?.number || "";
          customerContactParamsEntry.homePhone =
            customerResponse?.addresses.telecomsAddresses.find(
              (a) => a.telecomsAddressType === "homePhone"
            )?.number || "";
          customerContactParamsEntry.email =
            customerResponse?.addresses.digitalAddresses.find(
              (a) => a.digitalAddressType === "email"
            )?.address || "";

          if (
            customerContactParamsEntry.mobilePhone == "" ||
            customerResponse?.person.name.givenName == "" ||
            customerContactParamsEntry.email == ""
          ) {
            setIsCustInfo(true);
          } else {
            setIsCustInfo(false);
          }
          setCustomerContact(customerContactParamsEntry);
          setCustResponse(customerResponse);
          dispatch(userProfileETag(customerResponse?.eTag));
          dispatch(
            userProfileData({ customerResponse, customerContactParamsEntry })
          );
          dispatch(
            saveCustomerphoneNumber(customerResponse.addresses.telecomsAddresses)
          );
          const activeTenanciesSummaries =
            customerResponse?.tenanciesSummaries.filter(
              (ts) =>
                ts.tenancySummary.statuses.find(
                  (s) => s.type === "isTenancyActive"
                )?.value === true
            );

          const tenancySummaryList: TenancySummary[] = [];
          if (tenancyResponse.length > 1) {
            tenancyResponse.map((ts: any) => {
              let tenancySummaryEntry: TenancySummary[] =
                activeTenanciesSummaries.filter(
                  (x) => x.tenancySummary.tenancyId === ts.tenancyIdentifier
                );

              if (tenancySummaryEntry.length > 0) {
                if (
                  ts.tenancyGroup != "Non-residential" &&
                  ts.tenancyGroup != "Commercial"
                ) {
                  tenancySummaryList.unshift(tenancySummaryEntry[0]);
                } else {
                  tenancySummaryList.push(tenancySummaryEntry[0]);
                }
              }
            });
          } else {
            tenancySummaryList.push(activeTenanciesSummaries[0]);
          }
          const tenancyType =
            tenancySummaryList[0]?.tenancySummary?.tenancyType;
          if (tenancyType) {
            if (
              tenancyType == TenancyTypeMappingEnum.AGENCY ||
              tenancyType == TenancyTypeMappingEnum.COM1 ||
              tenancyType == TenancyTypeMappingEnum.HMBY2010 ||
              tenancyType == TenancyTypeMappingEnum.OFFICE ||
              tenancyType == TenancyTypeMappingEnum.USENOCCU
            ) {
              setIsVisible("none");
            } else {
              setIsVisible("block");
            }
          }

          if (tenancySummaryList.length > 0) {
            let accountid =
              tenancySummaryList[0]?.tenancySummary?.paymentReference;

            if (accountid != null) {
              dispatch(userChosenAccountNumber(accountid));
              getAccountDetails(accountid);
            }
          }
          const activeTenanciesPremisesIds: Tenancies[] = tenancySummaryList
            .map((ts: any) => ts.tenancySummary.premisesIds)
            .flat();

          const promises = activeTenanciesPremisesIds.map(async (item: any) => {
            return await api.getAssociatedAssets(item);
          });
          const propertiesIds: string[] = [];
          const premisesList: RelatedPremisesType[] = [];
          Promise.all(promises).then((results) => {
            activeTenanciesPremisesIds?.map(async (assetId: any) => {
              results.forEach((element) => {
                const resultF = element.filter(
                  (addressData) => addressData.id === assetId
                );

                if (resultF !== null) newAssociatedAssets.push(resultF);
              });
            });
            const associatedAssetsList: AssocitaedAssetsType[] = [];
            newAssociatedAssets?.map((addressData: any) => {
              addressData?.map((address: any) => {
                const associatedAssetsTypeEntry: AssocitaedAssetsType =
                  new AssocitaedAssetsType();

                associatedAssetsTypeEntry.key = address?.id;
                associatedAssetsTypeEntry.value =
                  address?.addresses?.postalAddresses[0]?.formattedAddress;
                associatedAssetsList.push(associatedAssetsTypeEntry);

              });
            });
            if (associatedAssetsList.length > 1) {
              setArea(true);
            } else {
              setArea(false);
            }
            if (PropertyId !== "") {
              handleChangeOnBack(PropertyId)

            }
            else {
              handleChangeOnBack(associatedAssetsList[0].key)
              setDefaultAssociatedAsset(associatedAssetsList[0].key);
              dispatch(userChosenPropertyId(associatedAssetsList[0].key));
              setAssetProperty(associatedAssetsList[0].key);
            }
            setAssociatedAssetsList(associatedAssetsList);
            checkforLandlordValues(associatedAssetsList[0]?.key);
          });

          const myMessagesResponse = await api.GetAppConfigToggleValue(
            "IsMyMessagesRequired"
          );
          if (myMessagesResponse) {
            setShowMyMessages(myMessagesResponse?.value);
          }

          const isCaseCardRequired = await api.GetAppConfigToggleValue("IsCaseCardRequired");

          if (isCaseCardRequired) {
            dispatch(appConfigCaseCardToggle(isCaseCardRequired?.value));
          }


          const responseValue = await api.GetAppConfigToggleValue(
            "IsRepairFullDescriptionRequired"
          );
          if (responseValue) {
            dispatch(appConfigToggle(responseValue?.value));
          }

          const covidResponseToggle = await api.GetAppConfigToggleValue(
            "isCovidQuestionRequired"
          );
          if (covidResponseToggle) {
            dispatch(appConfigCovidToggle(covidResponseToggle?.value));
          }

          const getvandalismToggleConfig = await api.GetAppConfigToggleValue(
            "isVandalismQuestionRequired"
          )
          if (getvandalismToggleConfig) {
            let vandalismToggleValue: boolean = getvandalismToggleConfig?.value.toLowerCase() == 'true' ? true : false
            dispatch(appConfigVandalismToggle(vandalismToggleValue));
          }

          const status =
            RepairStatusField.Appointed +
            "," +
            RepairStatusField.PendingApproval +
            "," +
            RepairStatusField.OnHold +
            "," +
            RepairStatusField.Cancelled +
            "," +
            RepairStatusField.Completed +
            "," +
            RepairStatusField.Unappointed +
            "," +
            RepairStatusField.InProgress +
            "," +
            RepairStatusField.InDiagnosis +
            "," +
            RepairStatusField.ReportedToEMA +
            "," +
            RepairStatusField.CatalystTier2Triage +
            "," +
            RepairStatusField.CatalystEMATriage +
            "," +
            RepairStatusField.CatalystDefectTriage;
          dispatch(
            userChosenPropertyId(
              tenancySummaryList[0]?.tenancySummary?.premisesIds[0]
            )
          );

          setAssetProperty(tenancySummaryList[0]?.tenancySummary?.premisesIds[0]);

          const associatedAssets = await api.getAssociatedAssets(
            tenancySummaryList[0]?.tenancySummary?.premisesIds[0]
          );
          associatedAssets.forEach((element) => {
            const premisesEntry: RelatedPremisesType =
              new RelatedPremisesType();
            premisesEntry.premisesType = element.assetType as PremisesType;
            premisesEntry.premisesTypeId = element.id;
            premisesList.push(premisesEntry);
            propertiesIds.push(element.id);
          });

          const areaOptions = premisesList.map((f) => ({
            key: f.premisesType,
            value: f.premisesTypeId,
          }));

          areaOptions.forEach((element) => {
            if (element.key === "Property" || element.key === "Block") {
              assetBlockPropertyIds.push(element.value);
            }
            if(element.key === "Block"){
              dispatch(getBlockIdentifier(element.value))
            }
          });

          let assetIDsIntTxt = "assetIdentifiers=";
          let assetIDsRemTxt = assetBlockPropertyIds.join("&assetIdentifiers=");
          let assetIDsAll = assetIDsIntTxt.concat(assetIDsRemTxt);
          setassetIdentifiersAll(assetIDsAll);
          dispatch(getAllAssetIdentifiers(assetIDsAll));

          let tenancyStartDate =
            tenancySummaryList[0]?.tenancySummary?.tenancyStartDate;
          if (tenancyStartDate == undefined) {
            tenancyStartDate = "";
          }
          const newList: RelatedPremisesType[] = premisesList.filter(
            (item) => item.premisesType === PremisesType.Property
          );
          if (newList.length > 0) {
            const queryParamsFirst = {
              propertiesIds: propertiesIds.filter(
                (item) => item === newList[0].premisesTypeId
              ),
              orderBy: "raisedDate desc",
              raisedSince: tenancyStartDate,
              priority: "",
              status,
              limit: 1,
            };
            const worksOrdersRepairFirst = await api.getWorksOrdersApi(
              queryParamsFirst
            );

            const queryParamsSecond = {
              propertiesIds: propertiesIds.filter(
                (item) => item !== newList[0].premisesTypeId
              ),
              orderBy: "raisedDate desc",
              raisedSince: tenancyStartDate,
              priority: "",
              status,
              limit: 1,
            };
            if (queryParamsSecond.propertiesIds.length > 0) {
              const worksOrdersRepairSecond = await api.getWorksOrdersApi(
                queryParamsSecond
              );
              if (worksOrdersRepairFirst.totalCount > 0) {
                worksOrdersRepairSecond.worksOrders.push(
                  worksOrdersRepairFirst.worksOrders[0]
                );
              }
              Promise.all([worksOrdersRepairSecond]).then((results) => {
                results.map((items) => {
                  const data = new RepairsResponse(items);

                  setRepairList(data.models);
                });
              });
            }
            else {
              Promise.all([worksOrdersRepairFirst]).then((results) => {
                results.map((items) => {
                  const data = new RepairsResponse(items);

                  setRepairList(data.models);
                });
              });
            }
          } else {
            const queryParams = {
              propertiesIds,
              orderBy: "raisedDate desc",
              raisedSince: tenancyStartDate,
              priority: "",
              status,
              limit: 1,
            };
            const worksOrdersRepair = await api.getWorksOrdersApi(queryParams);
            Promise.all([worksOrdersRepair]).then((results) => {
              results.map((items) => {
                const data = new RepairsResponse(items);
                setRepairList(data.models);
              });
            });
          }
          setIsLoading(true);
        }
      } catch (e) {
        console.error(e);
        const timerId = setTimeout(() => {
          props.history.push("/genericerror");
        }, 1000);
      }
    })();
  };

  const getAccountDetails = (accountid: string) => {
    (async () => {
      try {
        if (accountid != null) {
          const accountBalance = await api.getAccount(accountid);
          if (accountBalance != null) {
            const accountDetails: AccountDetails = new AccountDetails();
            const transactDetails: TransactDeatils = new TransactDeatils();

            accountBalance["balance"].forEach((balance: any) => {
              if (balance["balance-type"] == BalanceType.Livebalanced) {
                let liveBalanced = balance["monetary-amount"]["value"];
                const i = parseInt(liveBalanced);

                accountDetails.LiveBalanceType =
                  i != null ? (i > 0 ? " in arrears" : " in credit") : "";

                let number =
                  liveBalanced != null
                    ? Math.abs(liveBalanced.toString().replace(/,/g, ""))
                    : 0.0;
                accountDetails.LiveBalance = number.toFixed(2);
                if (liveBalanced == 0) {
                  accountDetails.LiveBalanceType = "";
                }
                if (i != null && i > 0) {
                  setIsArrear(true)
                  setBalanceCss("error-color pb-20")
                }
                else {
                  setIsArrear(false)
                  setBalanceCss("color-black pb-20")
                }
              }
            });
            const transactions = await api.getTransactionsSearch(
              accountid,
              3,
              0,
              "",
              "",
              ""
            );
            const getTransactionList = transactions.transactions;
            const firstBalance = getTransactionList[0]?.amount.net.value;
            const paymentCode = getTransactionList[0]?.["payment-code"];
            const transactionDate =
              getTransactionList[0]?.dates["transaction-date"];
            const secondBalance = getTransactionList[1]?.amount.net.value;
            const secondPaymentCode = getTransactionList[1]?.["payment-code"];
            const secondTransactionDate =
              getTransactionList[1]?.dates["transaction-date"];
            const thirdBalance = getTransactionList[2]?.amount.net.value;
            const thirdPaymentCode = getTransactionList[2]?.["payment-code"];
            const thirdTransactionDate =
              getTransactionList[2]?.dates["transaction-date"];

            transactDetails.LiveBalance =
              firstBalance != null
                ? firstBalance < 0
                  ? Math.abs(firstBalance)
                  : firstBalance
                : 0.0;
            transactDetails.transactiondate = transactionDate;
            transactDetails.TransactionBalanceType =
              paymentCode != null
              ? paymentCode == "DR"
              ?  "Debit"
              :  "Credit"
            : "";
            transactDetails.LiveBalanceType =
              paymentCode != null
                ? paymentCode != "DR"
                  ? "Payment received"
                  : "Charge applied"
                : "";
            if (firstBalance == 0) {
              transactDetails.TransactionBalanceType = "";
              transactDetails.LiveBalanceType = "";
            }
            accountDetails.transactDeatils.push(transactDetails);
            const transactDeatils1: TransactDeatils = new TransactDeatils();
            transactDeatils1.LiveBalance =
              secondBalance != null
                ? secondBalance < 0
                  ? Math.abs(secondBalance)
                  : secondBalance
                : 0.0;
            transactDeatils1.transactiondate = secondTransactionDate;
            transactDeatils1.TransactionBalanceType =
              secondPaymentCode != null
              ? secondPaymentCode == "DR"
              ?  "Debit"
              :  "Credit"
            : "";
            transactDeatils1.LiveBalanceType =
              secondPaymentCode != null
                ? secondPaymentCode != "DR"
                  ? "Payment received"
                  : "Charge applied"
                : "";
            if (secondBalance == 0) {
              transactDeatils1.TransactionBalanceType = "";
              transactDeatils1.LiveBalanceType = "";
            }
            accountDetails.transactDeatils.push(transactDeatils1);
            const transactDeatils2: TransactDeatils = new TransactDeatils();
            transactDeatils2.LiveBalance =
              thirdBalance != null
                ? thirdBalance < 0
                  ? Math.abs(thirdBalance)
                  : thirdBalance
                : 0.0;
            transactDeatils2.transactiondate = thirdTransactionDate;
            transactDeatils2.TransactionBalanceType =
              thirdPaymentCode != null
              ? thirdPaymentCode == "DR"
              ?  "Debit"
              : "Credit"
            : "";
            transactDeatils2.LiveBalanceType =
              thirdPaymentCode != null
                ? thirdPaymentCode != "DR"
                  ? "Payment received"
                  : "Charge applied"
                : "";
            if (thirdBalance == 0) {
              transactDeatils2.TransactionBalanceType = "";
              transactDeatils2.LiveBalanceType = "";
            }
            accountDetails.transactDeatils.push(transactDeatils2);
            setAccountDetails(accountDetails);
            setIsAccount(true);
          } else {
            setIsAccount(false);
          }
        }
      } catch (e) {
        setIsAccount(false);
        console.error(e);
      }
    })();
  };

  const fetchGetRepairsData = (defaultAssociatedAsset: string) => {
    let status =
      RepairStatusField.Appointed +
      "," +
      RepairStatusField.PendingApproval +
      "," +
      RepairStatusField.OnHold +
      "," +
      RepairStatusField.Cancelled +
      "," +
      RepairStatusField.Completed +
      "," +
      RepairStatusField.Unappointed +
      "," +
      RepairStatusField.InProgress +
      "," +
      RepairStatusField.InDiagnosis +
      "," +
      RepairStatusField.ReportedToEMA;

    (async () => {
      try {
        const propertiesIds: string[] = [];
        const premisesList: RelatedPremisesType[] = [];

        const AssetList = await api.getAssociatedAssets(defaultAssociatedAsset);
        dispatch({ type: CASE_SELECTED_TENANCY, payload: AssetList });

        AssetList.forEach((element) => {
          const premisesEntry: RelatedPremisesType = new RelatedPremisesType();
          premisesEntry.premisesType = element.assetType as PremisesType;
          premisesEntry.premisesTypeId = element.id;
          premisesList.push(premisesEntry);
          propertiesIds.push(element.id);
        });
        let tenancyStartDate = "";
        const activeTenanciesSummaries =
          custResponse?.tenanciesSummaries.filter(
            (ts) =>
              ts.tenancySummary.statuses.find(
                (s) => s.type === "isTenancyActive"
              )?.value === true
          );

        if (
          activeTenanciesSummaries != undefined &&
          activeTenanciesSummaries != null
        ) {
          let TenancySummaryEntry = activeTenanciesSummaries.find(
            (st) => st.tenancySummary.premisesIds[0] === defaultAssociatedAsset
          );

          if (TenancySummaryEntry != undefined && TenancySummaryEntry != null) {
            let paymentReference =
              TenancySummaryEntry?.tenancySummary?.paymentReference;
            if (paymentReference != undefined && paymentReference != null) {
              getAccountDetails(paymentReference);
            }
            if (TenancySummaryEntry?.tenancySummary.tenancyStartDate != null) {
              tenancyStartDate =
                TenancySummaryEntry?.tenancySummary.tenancyStartDate;
            }
          }

          const tenancyType =
            activeTenanciesSummaries[0]?.tenancySummary?.tenancyType;
          if (tenancyType) {
            if (
              tenancyType == TenancyTypeMappingEnum.AGENCY ||
              tenancyType == TenancyTypeMappingEnum.COM1 ||
              tenancyType == TenancyTypeMappingEnum.HMBY2010 ||
              tenancyType == TenancyTypeMappingEnum.OFFICE ||
              tenancyType == TenancyTypeMappingEnum.USENOCCU
            ) {
              setIsVisible("none");
            } else {
              setIsVisible("block");
            }
          }
        }
        const newList: RelatedPremisesType[] = premisesList.filter(
          (item) => item.premisesType === PremisesType.Property
        );
        if (newList.length > 0) {
          const queryParamsFirst = {
            propertiesIds: propertiesIds.filter(
              (item) => item === newList[0].premisesTypeId
            ),
            orderBy: "raisedDate desc",
            raisedSince: tenancyStartDate,
            priority: "",
            status,
            limit: 1,
          };
          const worksOrdersRepairFirst = await api.getWorksOrdersApi(
            queryParamsFirst
          );
          const queryParamssecond = {
            propertiesIds: propertiesIds.filter(
              (item) => item !== newList[0].premisesTypeId
            ),
            orderBy: "raisedDate desc",
            raisedSince: tenancyStartDate,
            priority: "",
            status,
            limit: 1,
          };
          if (queryParamssecond.propertiesIds.length > 0) {
            const worksOrdersRepairSecond = await api.getWorksOrdersApi(
              queryParamssecond
            );
            if (worksOrdersRepairFirst.totalCount > 0) {
              worksOrdersRepairSecond.worksOrders.push(
                worksOrdersRepairFirst.worksOrders[0]
              );
            }
            Promise.all([worksOrdersRepairSecond]).then((results) => {
              results.map((items) => {
                const data = new RepairsResponse(items);
                setRepairList(data.models);
              });
            });
          }
          else {
            Promise.all([worksOrdersRepairFirst]).then((results) => {
              results.map((items) => {
                const data = new RepairsResponse(items);
                setRepairList(data.models);
              });
            });
          }
        } else {
          const queryParams = {
            propertiesIds,
            orderBy: "raisedDate desc",
            raisedSince: tenancyStartDate,
            priority: "",
            description: "",
            status,
            limit: 1,
          };
          const worksOrdersRepair = await api.getWorksOrdersApi(queryParams);
          Promise.all([worksOrdersRepair]).then((results) => {
            results.map((items) => {
              const data = new RepairsResponse(items);
              setRepairList(data.models);
            });
          });
        }
      } catch (e) {
        console.error(e);
        const timerId = setTimeout(() => {
          props.history.push("/genericerror");
        }, 1000);
      }
    })();
  };

  const callViewAccountHandler = async () => {
    dispatch(selectedTabValue("viewaccount"))
    dispatch(userNavigatedFrom(""));
    props.history.push("/viewaccount");
  };

  const manageProfileHandler = () => {
    dispatch(selectedTabValue("manageprofile"));
    dispatch(userNavigatedFrom(""));
    props.history.push("/manageprofile");
  };

  const callPageHandler = async () => {
    dispatch(showMenuTabs(false));
    dispatch(showFooter(false));
    dispatch(userNavigatedFrom(""));
    dispatch(workOrderRepairObject(null));
    props.history.push("/repairproperty");
  };
  const callHistoricRepairsPageHandler = () => {
    dispatch(workOrderRepairObject(null));
    dispatch(userNavigatedFrom(""));
    dispatch(selectedTabValue("historicRepairs"))
    props.history.push("/historicRepairs");
  };
  const clearReduxStore = () => {
    dispatch(workOrderRepairObject(null));
    dispatch(userChosenPropertyId(""));
    dispatch(userChosenPropertyType(""));
    dispatch(userChosenPropertyAddress(""));
    dispatch(userChosenRepairLocationId(0));
    dispatch(userChosenRepairLocation(""));
    dispatch(userChosenUPRN(""));
    dispatch(userChosenSOR(""));
    dispatch(userChosenSTATUS(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
    dispatch(userChosenIsReporter(false));
    dispatch(userChosenIsError(false));
    dispatch(repairHistoryData(""));
    dispatch(repairSearchTerm(""));
    dispatch(defaultSearchRepairStatus(""));
    dispatch(defaultSearchArea(""));
    dispatch(defaultSearchSortByOrder(""));
    dispatch(clearMediaFiles([]));
    dispatch(showMenuTabs(true));
    dispatch(showFooter(true));
    dispatch(repairDefaultAssocitaedAsset(null));
    dispatch(getHeatingHotWaterCase(null));
  };
  const handleFinancialReferral = () => {
    dispatch(clearReferralInformation({}))
    props.history.push('/enquiryconsent')
  }
  const clearStoreAfterFinishingJourney = () => {
    dispatch(userChosenHighLevelDescription(""));
    dispatch(userChosenSupplementaryQuestions(""));
    dispatch(userChosenEmergencyQuestion(""));
    dispatch(userChosenDuplicateQuestion(""));
    dispatch(userChosenAdditionalDescription(""));
    dispatch(userChosenVandalismQuestion("No"));
    dispatch(userChosenVandalismAdditionalInformation(""));
    dispatch(userChosenCovidQuestion(""));
    dispatch(userChosenVulnerabilityQuestion("No"));
    dispatch(userChosenVulnerabilityAdditionalInformation(""));
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(selectedTenacyType(""));
    dispatch(getReferenceNumber(""));
    dispatch(getPrefferenceNumber(false));    
    dispatch(saveSelectedSlotDate(""));
    dispatch({ type: CLEAR_CASE_STATE });
    dispatch({ type: CASE_SELECTED_ANSWERS });
  }

  const removeCasesAndRepairData = () => {
    dispatch(comingFromCaseJourneySuccess(false))
    dispatch(comingFromRepairJourneySuccess(false))
    dispatch(setTriageRepair(false));
    clearStoreAfterFinishingJourney()
  }


  return (
    <div role="button" onClick={removeCasesAndRepairData} onKeyPress={removeCasesAndRepairData}>
      <div className="heading-Peabody">
        <h1>{t("Welcome_to_My_Peabody")}</h1>
        <h6 className="padding-paragraph">
          {" "}
          {t("Customer360Title")}{" "}
        </h6>
      </div>

      <div className="padding-repair">
      <SuccessMessage {...props} />
        {isLoading ? (
          <div>

            {isUserRegistered && (
              <Message data-testid="messageSuccess" className="success-msg">
                {t("Register_Success_Msg")}
              </Message>)
            }

            <div className="search-addressdd">

              {area ? (

                <>

                  <select
                    data-testid="repairs-address"
                    className="text-select repairs-search-address1"
                    value={defaultAssociatedAsset}
                    id={"repairs-search-address" + 1}
                    onChange={handleChangeAssociate}
                    title="repair address"
                  >
                    {associatedAssetList.map((e, key) => {
                      dispatch(userChosenPropertyId(defaultAssociatedAsset));
                      return (
                        <option key={key} value={e.key}>
                          {" "}
                          {e.value.length > 84
                            ? e.value.slice(0, 84) + ".."
                            : e.value}
                        </option>
                      );
                    })}
                  </select>

                </>
              ) : (
                <div
                  data-testid="repairs-search-address1"
                  id="repairs-search-address1"
                  className="repairs-search-address1"
                >
                  {associatedAssetList.map((e, key) => {
                    return (
                      <div className="text-tenancy">
                        {e.value}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 mb-30" data-testid="work-order">
              {!catalystRepairDisabledAreas || (catalystRepairDisabledAreas && catalystWorkOrderToggle === "true") ?
                (
                  <div className="body-card row m-0 border-terracotta display-column">

                    <h4>
                      {t("Repairs_card")}{" "}
                    </h4>

                    {!repairList.length ? (
                      <div className="col-md-12 p-0">
                        <div className="pt-24 pb-20">
                          <strong>{t("NO_repairs_listed")}</strong>
                        </div>
                        <p>
                          {t("NO_repairs")}
                        </p>
                      </div>
                    ) : (
                      repairList.map((el, key) => (
                        <div key={key} className="col-md-12 p-0 repair-divider">
                          <h5 className="pt-24 pb-20">
                            {t("recent_repair")}
                            <span className="text-transformation">
                              {RepairBlockMap[el.area]}
                            </span>
                          </h5>

                          <div className="record-repair justify-between">
                            <h6>{t("historic_repairs_dates")}</h6>
                            <p>{formatDate(el.raisedDate)}</p>
                          </div>

                          <div className="record-repair justify-between pt-16 pb-16">
                            <h6>{t("historic_repairs_status")}</h6>
                            <p>
                              {" "}
                              {el.status === RepairStatusField.Unappointed ||
                                el.status === "Reported to EMA"
                                ? RepairStatusField.Assignedcontractor
                                : (el.status === RepairStatusField.CatalystTier2Triage || el.status === RepairStatusField.CatalystDefectTriage || el.status === RepairStatusField.CatalystEMATriage) ? RepairStatusField.RepairStatusFieldCatalyst : el.status}
                            </p>
                          </div>

                          <div className="record-repair justify-between">
                            <h6>{t("historic_repairs_description")} </h6>
                            <p>
                              {" "}
                              {el.trade == "Unknown" &&
                                el.channel != RepairChannel.CustomerSelfService &&
                                el.channel != RepairChannel.ERGO &&
                                (appConfigValue == "true" ||
                                  appConfigValue == "false")
                                ? t("RepairDescription_ErrorMessage")
                                : el.channel ===
                                  RepairChannel.CustomerSelfService ||
                                  (el.channel === RepairChannel.ERGO &&
                                    (appConfigValue == "true" ||
                                      appConfigValue == "false"))
                                  ? el.description?.split(".")[0]
                                  : el.trade}
                            </p>
                          </div>
                        </div>


                      ))
                    )}

                    <div className="col-md-12 marginTop-auto">
                      <div className="row pe-0 repair-btn">

                        {repairList.length ? (
                          <div
                            className="col pt-30 pe-0"
                            id="common-btn-id"
                          >
                            <Button onClick={callHistoricRepairsPageHandler} id="cust360-btn1" className="secondary-ghost-btn"> {t("View_Repair")}</Button>
                          </div>
                        ) :
                          (<div className="col text-end pt-30 pe-0">
                            <Button data-testid="repairs-Create" onClick={callPageHandler} id={"repairs-Create" + 1} className="secondary-ghost-btn">{t("Create_repair")}</Button>
                          </div>
                          )
                        }
                        {repairList.length ? (
                          <div className="col text-end pt-30 pe-0">
                            <Button data-testid="repairs-Create" onClick={callPageHandler} id={"repairs-Create" + 1} className="secondary-ghost-btn">{t("Create_repair")}</Button>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

              <div
                data-testid="Balance_Card"
                className="body-card row m-0 border-blue display-column"
              >
                {isAccount ? (
                  <>
                    <h4>
                      {t("Balance_card")}{" "}
                    </h4>
                    <div className="col-md-12 p-0">
                      <h5 className="pt-24 pb-20">
                        {t("your_rent_service_account")}
                      </h5>

                      {!accountDetails?.transactDeatils[0].LiveBalance ||
                        !accountDetails?.transactDeatils[0].transactiondate ? (
                        <h5 className="error-color">
                          {t("CR047")}
                        </h5>

                      ) : (
                        <>
                          <h5 className={balanceCss}>
                            {
                              isArrear ? (
                                <>
                                  (£
                                  {accountDetails?.LiveBalance == "0"
                                    ? "0.00"
                                    : parseFloat(
                                      accountDetails?.LiveBalance
                                    ).toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                    })}
                                  ) </>

                              ) : (
                                <>
                                  £
                                  {accountDetails?.LiveBalance == "0"
                                    ? "0.00"
                                    : parseFloat(
                                      accountDetails?.LiveBalance
                                    ).toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                    })} </>
                              )
                            }
                            {accountDetails?.LiveBalanceType}
                            {" "}
                          </h5>
                          {accountDetails?.transactDeatils[0].LiveBalance ? (
                            <>
                              <div className="record-repair justify-between">
                                <h6>

                                  {" "}
                                  {
                                    accountDetails?.transactDeatils[0]
                                      .LiveBalanceType
                                  }{" "}
                                </h6>
                                <h6>
                                {Number((String(accountDetails?.transactDeatils[0].LiveBalance)).replace(/[^-.0-9]+/g,''))<0
                                       ? "-" : "+"}
                                  £
                                  {accountDetails?.transactDeatils[0]
                                    .LiveBalance == 0
                                    ? "0.00"
                                    : Number((String(accountDetails?.transactDeatils[0].LiveBalance)).replace(/[^-.0-9]+/g,''))> 0 ? accountDetails?.transactDeatils[0].LiveBalance.toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                      }
                                    ): (-1 * Number((String(accountDetails?.transactDeatils[0].LiveBalance)).replace(/[^-.0-9]+/g,''))).toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                      }
                                    )}
                                </h6>
                              </div>
                              <div className="record-repair justify-between">
                                <p>
                                  {formatDate(
                                    accountDetails?.transactDeatils[0]
                                      .transactiondate || ""
                                  )}
                                </p>
                                <p>

                                  {
                                    accountDetails?.transactDeatils[0]
                                      .TransactionBalanceType
                                  }
                                  {" "}
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {accountDetails?.transactDeatils[1].LiveBalance ? (
                            <>
                              <div className="record-repair justify-between pt-16">
                                <h6>

                                  {" "}
                                  {
                                    accountDetails?.transactDeatils[1]
                                      .LiveBalanceType
                                  }{" "}
                                </h6>
                                <h6>
                                {Number((String(accountDetails?.transactDeatils[1].LiveBalance)).replace(/[^-.0-9]+/g,'')) <0
                                       ? "-" : "+"}
                                  £
                                  {accountDetails?.transactDeatils[1]
                                    .LiveBalance == 0
                                    ? "0.00"
                                    : Number((String(accountDetails?.transactDeatils[1].LiveBalance)).replace(/[^-.0-9]+/g,''))> 0 ? accountDetails?.transactDeatils[1].LiveBalance.toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                      }
                                    ): (-1 * Number((String(accountDetails?.transactDeatils[1].LiveBalance)).replace(/[^-.0-9]+/g,''))).toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                      }
                                    )}
                                </h6>
                              </div>
                              <div className="record-repair justify-between">
                                <p>
                                  {formatDate(
                                    accountDetails?.transactDeatils[1]
                                      .transactiondate || ""
                                  )}
                                </p>
                                <p>
                                  {
                                    accountDetails?.transactDeatils[1]
                                      .TransactionBalanceType
                                  }
                                  {" "}
                                </p>
                              </div>


                            </>
                          ) : (
                            ""
                          )}

                          {accountDetails?.transactDeatils[2].LiveBalance ? (
                            <>
                              <div className="record-repair justify-between  pt-16">
                                <h6>
                                  {
                                    accountDetails?.transactDeatils[2]
                                      .LiveBalanceType
                                  }{" "}
                                </h6>
                                <h6>
                                {Number((String(accountDetails?.transactDeatils[2].LiveBalance)).replace(/[^-.0-9]+/g,'')) <0
                                       ? "-" : "+"}
                                  £
                                  {accountDetails?.transactDeatils[2]
                                    .LiveBalance == 0
                                    ? "0.00"
                                    : Number((String(accountDetails?.transactDeatils[2].LiveBalance)).replace(/[^-.0-9]+/g,''))> 0 ? accountDetails?.transactDeatils[2].LiveBalance.toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                      }
                                    ): (-1 * Number((String(accountDetails?.transactDeatils[2].LiveBalance)).replace(/[^-.0-9]+/g,''))).toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                      }
                                    )}
                                </h6>
                              </div>
                              <div className="record-repair justify-between">
                                <p>
                                  {formatDate(
                                    accountDetails?.transactDeatils[2]
                                      .transactiondate || ""
                                  )}
                                </p>
                                <p>
                                  {
                                    accountDetails?.transactDeatils[2]
                                      .TransactionBalanceType
                                  }
                                  {" "}
                                </p>
                              </div>

                            </>
                          ) : (
                            ""
                          )}

                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="p-0"
                      data-testid="No_Balance_Card"
                    >
                      <h4>
                        {t("Balance_card")}{" "}
                      </h4>
                    </div>
                    <div className="col-md-12 p-0">
                      <h5 className="pt-24 pb-20">
                        {t("your_rent_service_account")}
                      </h5>
                      <p>
                        {t("no_rent_service")}
                      </p>
                    </div>
                  </>
                )}
                <div className="col-md-12 marginTop-auto">
                  <div className="row pe-0 repair-btn">
                    

                  <div style={{ display: isVisible }} className="col pt-30 pe-0">
                    <Button id="view-account" data-testid="view-account" onClick={callViewAccountHandler} className="secondary-ghost-btn">{t("View_Accounts")}</Button>
                  </div>
                  <div className="col text-end pt-30 pe-0">
                    {((!isCatalyst && financialReferralToggle === "true")|| financialReferralToggle==="false") && 
                      <Button id="repairs-Create" data-testid="repairs-Create" className="secondary-ghost-btn" onClick={handleFinancialReferral}>
                        {t("Financial_FI001")}{" "} 
                      </Button>
                    }
                  </div>

                
                </div>
                </div>
              </div>
              {showMyMessages === 'true' && <MyMessagesCard {...props} associatedAssetList={assetIdentifiersAll} />}
              {loggedInStatus && blockAssetId && <HighRise {...props} />}
              <div className="body-card row m-0 border-yellow display-column">
                <div className="p-0">
                  <h4>
                    {" "}
                    {t("Your_details")}{" "}
                  </h4>
                  <div className="col-md-12 p-0">
                    <h5 className="pt-24 pb-20">
                      {t("personal_details")}
                    </h5>

                    <div className="record-repair justify-between pb-16">
                      <h6> {t("Name")} </h6>
                      <p data-hj-suppress="">
                        {custResponse?.person.name.title}{" "}
                        {custResponse?.person.name.givenName}{" "}
                        {custResponse?.person.name.surname}
                      </p>
                    </div>

                    <div className="record-repair justify-between pb-16">
                      <h6> {t("Email")}</h6>
                      <p>
                        {customerContact?.email ? customerContact?.email : "-"}
                      </p>
                    </div>

                    <div className="record-repair justify-between pb-16">
                      <h6> {t("Mobile")}</h6>
                      <p>
                        {customerContact?.mobilePhone
                          ? customerContact?.mobilePhone
                          : "-"}
                      </p>
                    </div>

                    {customerContact?.homePhone ? (
                      <div className="record-repair justify-between pb-16">
                        <h6> {t("Home")}</h6>
                        <p>{customerContact?.homePhone}</p>
                      </div>
                    ) : (
                      ""
                    )}


                    {customerContact?.workPhone ? (
                      <div className="record-repair justify-between pb-16">
                        <h6> {t("Business")}</h6>
                        <div>{customerContact?.workPhone}</div>
                      </div>
                    ) : (
                      ""
                    )}

                  </div>
                    {!isCustInfo ? (
                      <div></div>
                    ) : (

                      <div className="record-repair justify-between ">
                        <p className="text-start ps-0">
                          {t("missing_information_profile")}
                        </p>
                      </div>

                    )}
                </div>
                <div className="col-md-12 marginTop-auto">
                  <div className="row pe-0 repair-btn">
                    <div className="col ps-0 pt-30">
                      <a
                        id="manage_profile"
                        data-testid="manage_profile"
                        className="secondary-ghost-btn"
                        onClick={manageProfileHandler}
                      >
                        {t("Edit_your_details")}
                      </a>
                    </div>
                  </div>
                </div>

              </div>
              <div className="body-card row m-0 border-lightblue display-column">

                <h4>{catalystCustomer ? t("SSCP006") : t("Save_bills")}</h4>
                <div className="col-md-12 p-0">

                  <img src={catalystCustomer ? catalystlogo : logo} alt="Volunterring" className="pt-24 w-100" />

                  <p className="pt-24">{catalystCustomer ? t("SSCP007") : t("Saving_money")}</p>
                </div>

                <div className="col-md-12 marginTop-auto">
                  <div className="row pe-0 repair-btn">
                    <div className="col ps-0 pt-30">

                      {catalystCustomer ?
                        <Button data-testid="button-Getting" id={"button-Getting1" + 1} onClick={() =>
                          window.open(
                            "https://www.peabody.org.uk/your-home/",
                            "_blank"
                          )
                        } className="secondary-ghost-btn">{t("Find_out_more")}</Button>
                        : <Button id={"button-Getting1" + 1} data-testid="button-Getting" onClick={() =>
                          window.open(
                            "https://www.peabody.org.uk/resident-services/benefits-and-budgeting/book-your-free-energy-advice-session",
                            "_blank"
                          )
                        } className="secondary-ghost-btn">{t("Find_out_more")}</Button>
                      }
                    </div>
                  </div>
                </div>

              </div>
              {appCaseCardconfigValue == "true" &&
                assetProperty &&
                !catalystCustomer && <CaseCard {...props} />}
              {appCaseCardconfigValue == "true" &&
                assetProperty &&
                catalystCustomer &&
                catalystCaseCardToggle === "true" && <CaseCard {...props} />}

            </div>
          </div>
        ) : (
          <div className="SpinnerWrapper">
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default Customer360View;
