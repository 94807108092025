import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AssetDetails from "../../models/Customer/AssetDetails";
import { Container } from "@material-ui/core";
import * as api from "../../api";
import "../../compositions/ReapirAsset/index.scss";
import {
  saveCustomerTenaciesResponse,
  selectedTenacyType,
  saveIsEligibilityRepair,
} from "../../ducks/redux/actions/getAddress";
import {
  userChosenPropertyType,
  userChosenUPRN,
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  isEditState,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenDuplicateQuestion,
  userChosenEmergencyQuestion,
  userChosenRepairLocationId,
  userChosenHighLevelDescription,
} from "../../ducks/redux/actions/userChoices";
import { updateDashboardForm } from "../../ducks/redux/actions/dashboardReducer.actions";
import houseIcon from "../../assets/img/Home.png";
import blockIcon from "../../assets/img/Building.png";
import estateIcon from "../../assets/img/estate.png";
import dividedBlockIcon from "../../assets/img/divided-block.png";
import Loader from "react-loader-spinner";
import { RouteComponentProps } from "react-router-dom";
import Button from "../../storybook/Button";
import BackModal from "../Modal/BackModal";
import ExitModal from "../Modal/ExitModal";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import HeaderJourney from "../../storybook/Header/HeaderJourney";
import Message from "../../storybook/Message";

interface RepairAssetProps extends RouteComponentProps<any> {
  /* cId: any, 
  setErrorCheck: any */
}

const RepairAsset: React.FC<RepairAssetProps> = (props) => {
  enum AssetTypeMappingEnum {
    Social = "Property",
    "Peabody Property" = "Property",
    "Non-residential" = "Property",
    Commercial = "Property",
    "Market" = "Property",
    Leasehold = "Block",
    Freehold = "Block",
  }
  const BlockMap = {
    Property: "My Property",
    "Divided Block": "My Divided Block",
    Block: "My Block",
    Estate: "My Estate",
    "Estate Area": "My Estate",
  };
  const [isLoading, setIsLoading] = useState(true);
  let tenanciesId = "";
  let assetType = "";
  let premisesId = "";
  let isPremisesElegible = true;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [addresses, setAddresses] = useState<AssetDetails>();
  const [eligible, setEligible] = React.useState("none");
  const [errorCode, setErrorCode] = useState("");
  const [buttondisable, setButtonDisable] = useState(false);
  const assets = (state: any) => state?.addresses?.customerassociatedassets;
  const addressesData = useSelector(assets);
  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );
  const tenancies = (state: any) => state?.addresses?.customertenancies;
  const tenanciesData = useSelector(tenancies);
  if (tenanciesData != null) {
    tenanciesId = tenanciesData[0]?.tenancySummary?.tenancyId;
  }
  const customerID = useSelector((state: any) => state.addresses?.customerid);
  const selectedAssetType = useSelector(
    (state: any) => state.addresses?.selectedTenancy
  );
  const selectedstate = useSelector(
    (state: any) => state
  );

  const singleTenancy = useSelector(
    (state: any) => state?.userChoicesReducer?.singleTenancy
);

  const getAssetIcon = (assetType: any) => {
    var icon = houseIcon;
    if (assetType == "Property") icon = houseIcon;
    if (assetType == "Block") icon = blockIcon;
    if (assetType == "Divided Block") icon = dividedBlockIcon;
    if (assetType.includes("Estate")) icon = estateIcon;
    return icon;
  };
  //To track div selection for RepairProperty--Start here---
  const [assetTypeValue, setAssetTypeValue] = useState("");
  //-----------Ends here-----------//

  const SetErrorDetails = () => {
    setIsLoading(false);
    setErrorCode("SSR001");
    setEligible("block");
    setButtonDisable(true);
  };

  useEffect(() => {
    let responseTenancies;
    if (customerID === null || customerID === undefined || customerID === "") {
      props.history.push("/startpage");
      return;
    }
    (async function () {
      try {
        if (addressesData.length > 0) {
          setAddresses(addressesData);
        }
        const responseTenancies = await api.getTenancies(customerID);
        dispatch(saveCustomerTenaciesResponse(responseTenancies));
        if (responseTenancies) {
          setIsLoading(false);
        } else {
          SetErrorDetails();
        }
        var defaultSelectedTenancies = responseTenancies.filter(
          (ts: { tenancyIdentifier: string }) =>
            ts.tenancyIdentifier === tenanciesId
        );
        let assettypeKey: string = "";
        if (defaultSelectedTenancies) {
          assettypeKey = defaultSelectedTenancies[0]?.tenancyGroup;
          assetType =
            AssetTypeMappingEnum[
              assettypeKey as keyof typeof AssetTypeMappingEnum
            ];
        }
        if (!isUndefinedNullOrEmpty(selectedAssetType)) {
          assetType = selectedAssetType;
        }
        addressesData?.forEach((element: any) => {
          if (element.length > 0) {
            element.forEach((item: any) => {
              if (item.assetType === assetType) {
                premisesId = item.id;
                if (!assetTypeValue) {
                  callSelection(assetType, item.id, item.uprn);
                }
              }
            });
          }
        });
        checkPrimiseEligilibility(premisesId, assettypeKey);
      } catch (e) {
        SetErrorDetails();
        console.error(e);
      }
    })();
  }, []);

  const backButtonHandler = () => {
    sessionStorage.removeItem("presentTrackSelectionRepairAsset");
    if (tenanciesData.length > 1) {
      props.history.push("/repairproperty");
    } else {
      props.history.push("/customer360View");
    }
  };

  const exitButtonHandler = () => {
    props.history.push("/customer360View");
  };
  const userChoices = useSelector((state: any) => state.userChoicesReducer);
  const callPageHandler = () => {
    props.history.push("/repairlocation");
  };

  const checkPrimiseEligilibility = async (
    premisesId: any,
    tenancyGroup: any
  ) => {
    try {
      const responsePremisesEligibility = await api.postPremisesEligibility(
        premisesId,
        tenancyGroup
      );

      if (responsePremisesEligibility?.length > 0) {
        setIsLoading(false);
        setButtonDisable(false);
        isPremisesElegible = Boolean(
          JSON.parse(responsePremisesEligibility[0]?.isEligible)
        );
        const errorCode = responsePremisesEligibility[0]?.errorCode;
        if (errorCode === "E4" || errorCode === "E5" || errorCode === "E24") {
          setErrorCode(errorCode);
          setEligible("block");
          setButtonDisable(true);
        } else {
          setErrorCode(responsePremisesEligibility[0]?.errorCode);
          if (!isPremisesElegible) {
            setEligible("block");
            setButtonDisable(true);
          } else {
            setEligible("none");
            setButtonDisable(false);
          }
        }
        dispatch(saveIsEligibilityRepair(isPremisesElegible));
      } else {
        SetErrorDetails();
      }
    } catch (e) {
      SetErrorDetails();
      console.error(e);
    }
  };

  const GetTenancyGroup = async (customerID: any, premisesId: any) => {
    const responseTenancyGroup = await api.getTenanciesGroup(
      customerID,
      premisesId
    );
    let assetType =
      responseTenancyGroup.length > 0
        ? responseTenancyGroup[0]?.tenancyGroup
        : "";
    setIsLoading(true);
    checkPrimiseEligilibility(premisesId, assetType);
  };
  const updateSelection = (
    assetType: string,
    assetId: string,
    uprn: string
  ) => {
    if (EditState === true) {
      dispatch(userChosenRepairLocationId(0));
      dispatch(userChosenHighLevelDescription(""));
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(userChosenEmergencyQuestion(""));
      dispatch(isEditState(false));
      dispatch(userChosenVandalismAdditionalInformation(""));
      dispatch(userChosenVandalismQuestion("No"));
      dispatch(userChosenAdditionalDescription(""));
      dispatch(userChosenDuplicateQuestion(""));
    }
    setAssetTypeValue(assetType);
    setButtonDisable(true);
    dispatch(selectedTenacyType(assetType));
    dispatch(userChosenPropertyType(assetType));
    if (assetId && customerID) {
      GetTenancyGroup(customerID, assetId);
    }
    localStorage.setItem("userChosenPropertyType", assetType);
    dispatch(userChosenUPRN(uprn));
    dispatch(
      updateDashboardForm({
        assetType,
        assetId,
        customerID,
      })
    );
  };
  const callSelection = (
    assetType: string,
    assetId: string,
    uprn: string
  ) => {
    setAssetTypeValue(assetType);
    setButtonDisable(true);
    dispatch(selectedTenacyType(assetType));
    dispatch(userChosenPropertyType(assetType));
    if (assetId && customerID) {
      GetTenancyGroup(customerID, assetId);
    }
    localStorage.setItem("userChosenPropertyType", assetType);
    dispatch(userChosenUPRN(uprn));
    dispatch(
      updateDashboardForm({
        assetType,
        assetId,
        customerID,
      })
    );
  };

  let _className = "col-sm-12 col-md-12 col-lg-3 property-card column";
  let fieldsArray: any[] = [];
  addresses?.forEach((element: any) => {
    element.forEach((item: any) => {
      if (item.assetType != "Scheme") {
        _className =
          item.assetType == assetTypeValue
          ? "col-sm-12 col-md-12 col-lg-3 property-card active-property column"
          : "col-sm-12 col-md-12 col-lg-3 property-card column";
        fieldsArray.push(
          <div
            data-testid={item.assetType}
            className={_className}
            id={"repair-asset-main-div" + item.id}
            onClick={() => {
              updateSelection(item.assetType, item.id, item.uprn);
            }}
          >
              <div
                
                id={"repair-asset-div1" + item.id}
              >
                <div  id={"repair-asset-div2" + item.id}>
                  <div
                    id={"repair-asset-div3" + item.id}
                  >
                    <div
                      className="d-flex"
                      id={"repair-asset-div4" + item.id}
                    >
                      <img
                        src={getAssetIcon(item.assetType)}
                        alt=""
                        className=""
                      ></img>
                      <h5 id={item.id}>
                      {/* @ts-ignore */}
                      {BlockMap[item.assetType]}
                    </h5>
                    </div>
                    
                  </div>
                  <p
                    id={"repair-asset-p" + item.id}
                  >
                    {item.addresses?.postalAddresses[0]?.formattedAddress
                      .split(",")
                      .map((step: any, i: number) => (
                        <>
                          {i === 0 ? (
                            <h6>{step}</h6>
                          ) : (
                            <p>{step}</p>
                          )}
                          {"\n "}
                        </>
                      ))}
                  </p>
                </div>
              </div>
          </div>
        );
      }
    });
  });

  return (
    <>
    <HeaderJourney></HeaderJourney>
    <div className="container-fluid parent-footer p-0" id="back-modal-container">
      
      <div id="repair-asset-divs" className="padding-repair">
        <h1
          id="repair-asset-h1"
          className="pt-40 header-size"
        >
          {t("repair_report_label")}
        </h1>
        {isLoading ? (
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={100}
          width={50}
        />
      ) : (
        
        <div
          style={{ display: eligible }}
        >
          <Message className="error-msg">
          {t(errorCode)}
          </Message>
        </div>
        
      )}
       <div className="row mb-4 m-0 box" id="options">
          {fieldsArray}
       </div>

      </div>
      
      <div
        id="repair-asset-btn"
      >
      </div>
      
      <div className="col-md-12 marginTop-auto">
        <div className="row m-0 footer-actionbtn case-footer">
          <div className="col btn-top padding-repair mb-0">
          {!singleTenancy && (
          <BackModal id="back-modal-backmodal" back={backButtonHandler} />
          )}
          <ExitModal
          id="repair-asset-exitmodal"
          exit={exitButtonHandler}
        />
          </div>
          <div className="col text-end padding-repair">
          <Button
          id="btn_repairasset"
          disabled={buttondisable}
          onClick={callPageHandler}
          className="primary-btn"
        >
          {t("continuen_button")}{" "}
        </Button>
          </div>
        </div>
      </div>    
    </div>
    </>
  );
};
export default RepairAsset;
