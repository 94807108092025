import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import questions from "../../utils/translation/en.json";
import Button from "../../storybook/Button";
import savedAnswerKeys from "./schema.json";
import BackModal from "../Modal/BackModal";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../api";
import Loader from "react-loader-spinner";
import "../../compositions/ConfirmationPage/index.scss";
import moment from "moment";
import {
  userChosenPropertyId,
  userChosenPropertyType,
  userChosenPropertyAddress,
  userChosenRepairLocationId,
  userChosenRepairLocation,
  userChosenHighLevelDescription,
  userChosenSupplementaryQuestions,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenSTATUS,
  userChosenEligibilityErrorCode,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  updatePrevConfirmedState,
  isEditState,
  userChosenResumeRepair,
  loadDuplicateRepairPage,
  userCreatedNewRepair,
  clearMediaFiles,
} from "../../ducks/redux/actions/userChoices";
import {
  getContractorName,
  workOrderRepairObject,
  getDeterminedContractorDetails,
  getContractorPayload,
} from "../../ducks/redux/actions/getAddress";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import { RepairPriority } from "../../models/Repair/CreateRepair";
import { repairHistoryData } from "../../ducks/redux/actions/historicRepair";
import { CatalistTriageContractorType } from "../../models/Repair/Contractor";
import { FeatureFlags } from "../contexts/FeatureFlags";
import HeaderJourney from "../../storybook/Header/HeaderJourney";
import Message from "../../storybook/Message";
import { comingFromRepairJourneySuccess, setTriageRepair } from "../../ducks/redux/actions/FinancialReferral";

const ConfirmationDetails: React.FC<any> = (props) => {
  const savedResponses = useSelector((state: any) => state.userChoicesReducer);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { featureMedia }: any = useContext(FeatureFlags);


  enum RepairCauseId {
    "General Wear and Tear" = 1,
    "Vandalism or Damage" = 4,
  }

  enum AssetType {
    "Property" = "Property",
    "Block" = "Block",
  }

  enum EmergencySelection {
    "SelectedEmergencyTypeYes" = "Yes, I can provide access",
    "SelectedEmergencyTypeNo" = "No, I can provide access",
  }

  const determinedcontractordetails = useSelector(
    (state: any) => state.addresses?.determinedcontractordetails
  );

  const userChosenSupplementaryQuestionsVal = useSelector(
    (state: any) => state.userChoicesReducer.userChosenSupplementaryQuestions
  );
  const userChosenVandalismQuestionVal = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVandalismQuestion
  );

  const userChosenCovidQuestionVal = useSelector(
    (state: any) => state.userChoicesReducer.userChosenCovidQuestion
  );
  const covidConfigValue = useSelector(
    (state: any) => state?.addresses?.covidtoggleValue
  );

  const isCatalyst = useSelector(
    (state: any) => state.userChoicesReducer?.isCatalystCustomer
  );

  const eligibilityResponseObject = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.eligibilityResponseObject
  );

  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [closeAfterSaving, setCloseAfterSaving] = useState(false);
  const[BlockMsg,setBlockMsg]=useState<any>();
  const [blockLoading,setBlockLoading] =useState(false);

  const orbisDuplicate = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );


  useEffect(() => {
    callPostRepairApi();
    const parsedData = Object.entries(savedAnswerKeys).reduce(
      (prev: any, curr: any) => {
        let mainData: any = {};
        const combinedData = Object.entries(curr[1]);
        combinedData.forEach(([key, value]: any) => {
          if (savedResponses[value] || key === "urlPath") {
            mainData = {
              ...mainData,
              [curr[1]["urlPath"]]: {
                ...mainData[curr[1]["urlPath"]],
                [key]:
                  key === "urlPath"
                    ? value
                    : {
                      key: value,
                      value: savedResponses[value],
                    },
              },
            };
          }
        });
        return {
          ...prev,
          ...mainData,
        };
      },
      {}
    );
    dispatch(updatePrevConfirmedState(parsedData));
  }, []);

  const callPostRepairApi = async () => {
    setDisabled(true);
    const advancedPayload = {
      "isEmergency": orbisDuplicate.isEmergency,
      "sor": userChosenSOR,
      "isPotentialDuplicate": userChosenDuplicateQuestion.split("&")[2] ? true : (userChosenDuplicateQuestion=== t("duplicateNoneofTheAbove")) ? true : false,
      "isVulnerable": orbisDuplicate.isEmergency ? false : userChosenVulnerabilityQuestion==="Yes" ? true : false,
      "channel": "CustomerSelfService"
    }   
    try {
      setBlockLoading(true);
    const enhancedApiResponse = await api.postEnhancedRepairEligibility(advancedPayload);
    setBlockMsg(enhancedApiResponse?.isEligible) 
    if(enhancedApiResponse?.isEligible==false){
     setDisabled(true);
    }  
    else {
      setDisabled(false);
    }
    setBlockLoading(false);
  }
  catch(e) {
    console.error(e);
    setIsLoading(false);
    setBlockLoading(false);
    setTimeout(() => {
      props.history.push("/genericerror");
    }, 1000);
  }
  }
  
  const exitButtonHandler = () => {
    dispatch(clearMediaFiles([]));
    dispatch(userChosenPropertyId(""));
    dispatch(userChosenPropertyType(""));
    dispatch(userChosenPropertyAddress(""));
    dispatch(userChosenRepairLocationId(0));
    dispatch(userChosenRepairLocation(""));
    dispatch(userChosenHighLevelDescription(""));
    dispatch(userChosenSupplementaryQuestions(""));
    dispatch(userChosenAdditionalDescription(""));
    dispatch(userChosenVandalismQuestion("No"));
    dispatch(userChosenVandalismAdditionalInformation(""));
    dispatch(userChosenCovidQuestion(""));
    dispatch(userChosenContactDetails(""));

    dispatch(userChosenSTATUS(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
    dispatch(workOrderRepairObject(null));
    dispatch(repairHistoryData(""));
    dispatch(userChosenResumeRepair(false));
    dispatch(loadDuplicateRepairPage(false));
    dispatch(userCreatedNewRepair(null));


    props.history.push("/customer360View");
  };

  const saveDraftAndExit = () => {
    props.history.replace("/customer360View");
  };
  const backButtonHandler = () => {
    props.history.push("/customerdetails");
  };
  const callPageHandler = () => {

    if (
      userChosenDuplicateQuestion != "" ||
      heatinghotwatercase != undefined ||
      userChosenVulnerabilityAdditionalInformation != ""
    ) {
      isTriageCase = true;
    } else {
      isTriageCase = false;
    }
    let isCatalystTriageTier2Ema = false;
    let siteIdentifier = [];
    if (determinedcontractordetails) {
      const contractorDetail = determinedcontractordetails?.[0];
      siteIdentifier = contractorDetail?.contractorSites ?? contractorDetail?.contractorSites[0];
      isCatalystTriageTier2Ema = ((siteIdentifier[0] === CatalistTriageContractorType.CATALYST_TRIAGE_TIER2) || (siteIdentifier[0] === CatalistTriageContractorType.CATALYST_TRIAGE_EMA));
    }
    if (isTriageCase || isCatalystDefectTriageCase || isCatalystTriageTier2Ema) {
      dispatch(userChosenPropertyId(""));
      dispatch(comingFromRepairJourneySuccess(true))
      submitForm(siteIdentifier[0]);
    } else {
      dispatch(comingFromRepairJourneySuccess(true))
      submitForm("");
    }
  };

  const redirect = (url: string) => {
    dispatch(isEditState(true));
    props.history.push(url);
  };

  const renderData = (item: any, index: any) => {
    return (
      <>
      <div className= {`${index % 2 === 0 ? 'confirm-bg-grey' : ''} row `} >
        <div className="col-11"
          id={"confirmation-tr1" + item[0]}
        >
          <h6 className="confirm-span1 padding-1"
            id={"confirmation-tr1-td1" + item[0]}>

            {/* @ts-ignore */}
            {questions?.translation[item[0]] === t("Customer_Details")
              ? t("Customer_Details_Text_Two")
              : //  @ts-ignore
              questions?.translation[item[0]]}

          </h6>
          <p className="confirm-span2 padding-1"
            id={"confirmation-tr1-td2" + item[0]}
          >
            <div
              id={"confirmation-tr1-div2" + item[0]}
            >
              {" "}
              {!savedResponses[item[1]?.reduxKeyQs]
                ?
                savedResponses[item[1]?.reduxKey].split("@")[0]
                : ""
              }
              {savedResponses[item[1]?.reduxKeyQs]
                ?
                savedResponses[item[1]?.reduxKeys]
                : ""
              }             
              {"\n"}
              {savedResponses[item[1]?.reduxKeyQs]}
              <br></br>
              {savedResponses[item[1]?.reduxKeyselect]}
            </div>
          </p>
        </div>
        <div className="col-1"
          id={"confirmation-tr1-td3" + item[0]}
        >
          <span
            id={"confirmation-tr1-div3" + item[0]}
            className="text-center"
          >
            {" "}
            <i
              data-testid="confirmation-icon"
              id={"confirmation-tr1-icon" + item[0]}
              className="fas fa-edit text-teal"
              onClick={() => {
                redirect(`/${item[1]?.urlPath}`);
              }}
            ></i>
          </span>
        </div>
        </div>
      </>
    );
  };

  const workOrderObject = useSelector(
    (state: any) => state?.addresses?.workOrderRepair
  );

  const customerID = useSelector((state: any) => state.addresses?.customerid);

  const vandalismConfigValue = useSelector(
    (state: any) => state?.addresses?.vandalismtoggleValue
  );
  const userChosenUPRN = useSelector(
    (state: any) => state.userChoicesReducer.userChosenUPRN
  );
  const userChosenSOR = useSelector(
    (state: any) => state.userChoicesReducer.userChosenSOR
  );

  const userMaintResp = useSelector((state: any) => state.addresses.maintresp);
  const userRepairResp = useSelector(
    (state: any) => state.addresses.repairresp
  );
  const userVandalismChoice = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVandalismQuestion
  );

  const userChosenDuplicateQuestion = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenDuplicateQuestion
  );
  const userChosenEmergencyQuestion = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenEmergencyQuestion
  );

  const selectedproperty = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyType
  );

  const priority = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonObject[0]
        ?.priority
  );

  const heatinghotwatercase = useSelector(
    (state: any) => state?.addresses?.heatinghotwatercase
  );
  let isTriageCase = false;

  const isCatalystDefectTriageCase = isCatalyst && eligibilityResponseObject?.statuses.isInDefects === true

  const userChosenVulnerabilityQuestion = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVulnerabilityQuestion
  );

  const userChosenVulnerabilityAdditionalInformation = useSelector(
    (state: any) =>
      state.userChoicesReducer.userChosenVulnerabilityAdditionalInformation
  );

  const userChosenVandalismAdditionalInformationBackButton = useSelector(
    (state: any) =>
      state.userChoicesReducer.userChosenVandalismAdditionalInformation
  );

  let isContractorEMA = false;

  const workOrderUpdate = async () => {
    try {
      setIsLoading(true);
      const contractorDetail = determinedcontractordetails?.[0];
      const isEMA = contractorDetail?.statuses?.isEMA;

      workOrderObject.isDraft = true;
      if (isEMA) {
        workOrderObject.isEMA = true;
      }

      const siteIdentifier = contractorDetail?.contractorSites
        ? contractorDetail.contractorSites[0]
        : null;
      workOrderObject.contractor = siteIdentifier;

      userChosenSupplementaryQuestionsVal == "Yes"
        ? (workOrderObject.isSupplementary = true)
        : userChosenSupplementaryQuestionsVal == "No"
          ? (workOrderObject.isSupplementary = false)
          : (workOrderObject.isSupplementary = null);

      userChosenVandalismQuestionVal == "Yes" && eligibilityResponseObject?.statuses.isInDefects === true && vandalismConfigValue === true
        ? (workOrderObject.isVandalism = true)
        : userChosenVandalismQuestionVal == "No" && eligibilityResponseObject?.statuses.isInDefects === true && vandalismConfigValue === true
          ? (workOrderObject.isVandalism = false)
          : (workOrderObject.isVandalism = null);

      userChosenCovidQuestionVal == "Yes" && covidConfigValue
        ? (workOrderObject.isCovidSelfIsolated = true)
        : userChosenCovidQuestionVal == "No" && covidConfigValue
          ? (workOrderObject.isCovidSelfIsolated = false)
          : (workOrderObject.isCovidSelfIsolated = null);

      userChosenDuplicateQuestion == ""
        ? (workOrderObject.isPotentialDuplicate = false)
        : (workOrderObject.isPotentialDuplicate = true);
      userChosenVulnerabilityQuestion != "" &&
        userChosenVulnerabilityQuestion != null &&
        userChosenVulnerabilityQuestion == "Yes"
        ? (workOrderObject.isVulnerable = true)
        : userChosenVulnerabilityQuestion == "No"
          ? (workOrderObject.isVulnerable = false)
          : (workOrderObject.isVulnerable = null);
      userChosenVulnerabilityAdditionalInformation != ""
        ? (workOrderObject.VulnerableResponseText =
          userChosenVulnerabilityAdditionalInformation)
        : (workOrderObject.VulnerableResponseText = null);

      userChosenSOR != ""
        ? (workOrderObject.sorCode = userChosenSOR)
        : (workOrderObject.sorCode = "NA");

      if (userVandalismChoice == "Yes" && eligibilityResponseObject?.statuses.isInDefects === true && vandalismConfigValue === true) {
        workOrderObject.crimeReferenceNumber =
          userChosenVandalismAdditionalInformationBackButton;
        workOrderObject.repairCause = "Vandalism or Damage";
        workOrderObject.repairCauseId =
          RepairCauseId[
          workOrderObject.repairCause as keyof typeof RepairCauseId
          ];
      } else {
        workOrderObject.crimeReferenceNumber = "";
        workOrderObject.repairCause = "General Wear and Tear";
        workOrderObject.repairCauseId =
          RepairCauseId[
          workOrderObject.repairCause as keyof typeof RepairCauseId
          ];
      }
      workOrderObject.isSubmitted = true;
      const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
      const woRequestParameters =
        _worksOrderRequest.updateWorkOrderParameter(workOrderObject);
      const apiResponse = await api.updateWorkOrderRepair(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      );

      dispatch(workOrderRepairObject(workOrderObject));
    } catch (e) {
      console.error(e);
      const timerId = setTimeout(() => {
        props.history.push("/genericerror");
      }, 1000);
    } finally {
      setIsLoading(false);
    }
  };

  const submitForm = async (siteCode: string) => {
    try {
      setIsLoading(true);
      if (
        userChosenDuplicateQuestion != "" ||
        heatinghotwatercase != undefined ||
        userChosenVulnerabilityAdditionalInformation != ""
      ) {
        isTriageCase = true;
      } else {
        isTriageCase = false;
      }

      const contractorDetail = determinedcontractordetails?.[0];
      const isEMA = contractorDetail?.statuses?.isEMA;

      workOrderUpdate();

      if (!isTriageCase && !isCatalystDefectTriageCase && siteCode != CatalistTriageContractorType.CATALYST_TRIAGE_TIER2 && siteCode != CatalistTriageContractorType.CATALYST_TRIAGE_EMA) {
        const isIntegrated = contractorDetail?.statuses?.isIntegrated;
        dispatch(setTriageRepair(false));
        const isBuilder = contractorDetail?.statuses?.isBuilder;
        if ((
          (priority === RepairPriority.Emergency &&
          selectedproperty !== AssetType.Property
        ) || (userChosenEmergencyQuestion ===
 
          EmergencySelection.SelectedEmergencyTypeYes ||
          isEMA ||
          !isIntegrated ||
          isBuilder))
        ){
          dispatch(userChosenPropertyId(""));
          props.history.push("/customer360View");
        } else {
          dispatch(clearMediaFiles([]));
          props.history.push("/appointmentscheduler");
        }
      }
      else {

        dispatch(setTriageRepair(true));

        props.history.push('/customer360View')

      }

    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async function () {
      try {
        setDisabled(true);
        setIsLoading(true);

        if (
          userChosenDuplicateQuestion != "" ||
          heatinghotwatercase != undefined ||
          userChosenVulnerabilityAdditionalInformation != ""
        ) {
          isTriageCase = true;
        }
        else {
          isTriageCase = false;
        }

        if (!isTriageCase) {
          let contractorId: string = "";
          isContractorEMA = false;
          const isRecall = false;

          let region = eligibilityResponseObject?.region;
          let uprn = userChosenUPRN;
          let maintenanceResponsibility: string = userMaintResp;
          let repaiResponsiblity: string = userRepairResp;

          const repairId = workOrderObject?.id;
          let repairCauseId;
          if (eligibilityResponseObject?.statuses.isInDefects === true && vandalismConfigValue === true) {
            repairCauseId = userVandalismChoice == "Yes" ? 4 : 1;
          }
          else
            repairCauseId = 1;


          const sorCode = userChosenSOR;
          const raisedDate = new Date();
          const repairTime = raisedDate.getHours().toString();
          const priorityCode = workOrderObject?.isEmergency ? "EME" : "NA";
          const raisedFormattedDate = moment(new Date()).format("YYYY-MM-DD");

          let determinedContractor: any = null;
          if (isCatalystDefectTriageCase) {
            determinedContractor = [{
              "id": CatalistTriageContractorType.CATALYST_TRIAGE_DEFECT,
              "name": "Catalyst Defect Triage",
              "statuses": {
                "isIntegrated": false,
                "isBuilder": false,
                "isSubContractor": false,
                "isEMA": false,
                "isActive": true
              },
              "contractorSites": [
                "CATALYST_TRIAGE_DEFECT"
              ],
              "emaType": "",
              "contacts": [
                {}
              ],
              "_links": [],
              "parentIdentifier": null
            }]
          }
          else {
            determinedContractor = await api.getDeterminedContractor(
              repairId,
              priorityCode,
              region,
              sorCode,
              repairTime,
              uprn,
              raisedFormattedDate,
              repairCauseId,
              maintenanceResponsibility,
              repaiResponsiblity
            );
          }

          dispatch(getDeterminedContractorDetails(determinedContractor));
          if (determinedContractor) {
            const contractorDetail = determinedContractor?.[0];
            if (
              contractorDetail?.name != "" &&
              contractorDetail?.name != null
            ) {
              setDisabled(false);
            }
            dispatch(getContractorName(contractorDetail?.name));
            isContractorEMA = contractorDetail.statuses.isEMA;
            contractorId = contractorDetail.id;
            const contractorPriorityPayload = await api.getContractorPriority({
              sorCode,
              contractorIdentifier: contractorId,
              priority: priorityCode,
              isRecall: !!isRecall,
              isEMA: isContractorEMA,
            });
            dispatch(getContractorPayload(contractorPriorityPayload));
            let prioritySlot = "";

            const { isWorkingHours, contractorPriority } =
              contractorPriorityPayload;
            if (contractorPriority === "NA" || isRecall) {
              prioritySlot = RepairPriority.NextAvailable;
            }
            if (contractorPriority === "EME" && isWorkingHours) {
              prioritySlot = RepairPriority.Emergency;
            }
            if (contractorPriority === "OOH") {
              prioritySlot = RepairPriority.EmergencyOOH;
            }

            const sorcode = userChosenSOR; 
            if (
              contractorDetail &&
              workOrderObject != undefined &&
              workOrderObject != null
            ) {
              try {
                setIsLoading(true);

                workOrderObject.isOutOfHours =
                  contractorPriority === "OOH" ? true : false;
                workOrderObject.contractorName = contractorDetail?.name;
                if (contractorDetail?.contacts?.[0].addresses?.digitalAddresses[0]?.telecomsAddresses.length > 0) {
                  workOrderObject.contractorPhoneNumber =
                    contractorDetail?.contacts?.[0].addresses?.digitalAddresses[0]?.telecomsAddresses[0]?.number;
                }
                if (sorcode != "") workOrderObject.sorCode = sorcode;

                const siteIdentifier = contractorDetail?.contractorSites
                  ? contractorDetail.contractorSites[0]
                  : null;
                workOrderObject.contractor = siteIdentifier;

                userChosenSupplementaryQuestionsVal == "Yes"
                  ? (workOrderObject.isSupplementary = true)
                  : userChosenSupplementaryQuestionsVal == "No"
                    ? (workOrderObject.isSupplementary = false)
                    : (workOrderObject.isSupplementary = null);

                userChosenVandalismQuestionVal == "Yes" && eligibilityResponseObject?.statuses.isInDefects === true && vandalismConfigValue === true
                  ? (workOrderObject.isVandalism = true)
                  : userChosenVandalismQuestionVal == "No" && eligibilityResponseObject?.statuses.isInDefects === true && vandalismConfigValue === true
                    ? (workOrderObject.isVandalism = false)
                    : (workOrderObject.isVandalism = null);

                userChosenCovidQuestionVal === "Yes" && covidConfigValue
                  ? (workOrderObject.isCovidSelfIsolated = true)
                  : userChosenCovidQuestionVal === "No" && covidConfigValue
                    ? (workOrderObject.isCovidSelfIsolated = false)
                    : (workOrderObject.isCovidSelfIsolated = null);

                userChosenDuplicateQuestion == ""
                  ? (workOrderObject.isPotentialDuplicate = false)
                  : (workOrderObject.isPotentialDuplicate = true);
                userChosenVulnerabilityQuestion != "" &&
                  userChosenVulnerabilityQuestion != null &&
                  userChosenVulnerabilityQuestion != "No"
                  ? (workOrderObject.isVulnerable = true)
                  : (workOrderObject.isVulnerable = false);
                userChosenVulnerabilityAdditionalInformation != ""
                  ? (workOrderObject.VulnerableResponseText =
                    userChosenVulnerabilityAdditionalInformation)
                  : (workOrderObject.VulnerableResponseText = null);

                userChosenSOR != ""
                  ? (workOrderObject.sorCode = userChosenSOR)
                  : (workOrderObject.sorCode = "NA");

                if (isCatalyst) {
                  workOrderObject.isCatalyst = true;
                }

                workOrderObject.isInDefect = eligibilityResponseObject?.statuses.isInDefects
                workOrderObject.isOutOfHours =
                  contractorPriority === "OOH" ? true : false;

                const _worksOrderRequest = new WorksOrderRequest(
                  workOrderObject
                );
                const woRequestParameters =
                  _worksOrderRequest.updateWorkOrderParameter(workOrderObject);
                const apiResponse = await api.updateWorkOrderRepair(
                  woRequestParameters,
                  workOrderObject.id,
                  "eTag"
                );

                dispatch(workOrderRepairObject(workOrderObject));
              } catch (e) {
                console.error(e);
                props.history.push("/genericerror");
              } finally {
                setIsLoading(false);
              }
            }
          }
        } else {
          setDisabled(false);
        }
      } catch (e) {
        props.history.push("/genericerror");
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);
  return (
    <>
      <HeaderJourney></HeaderJourney>
      <div className="container-fluid parent-footer repair-request p-0" id="confirmation-container">
        <div id="confirmation-main-div ">
          <div id="confirmation-main-div" className="padding-repair">
            <h1 className="header-size" id="confirmation-h1">
              {t("Repair_Details_Head")}
            </h1>
            {blockLoading ? (
            <Loader
              type="ThreeDots"
              color="#00BFFF"
              height={70}
              width={50}
              timeout={5000}
            />
          ) : (BlockMsg==false )?
                 <Message id="velQues-span2" severity="error" className="warning-msg">
                  {t("OrbisBlockMsg1")} <br/>
                   {t("OrbisBlockMsg2")}  <strong className="font-bolder">{t("OrbisBlockNumber")}</strong>  {t("OrbisBlockMsg3")} 
                  </Message> : 
                    <Message className="info-msg">
                    {t("Repair_Details_Text1")}  {t("Repair_Details_Text2")}
                      </Message>
           }
          </div>
          {isLoading ? (
            <div className="padding-repair">
            <Loader
              type="ThreeDots"
              color="#00BFFF"             
              height={50}
              width={50}
              timeout={5000}
            /></div>
          ) : (
            <div id="confirmation-div-table">             
              {Object.entries(savedAnswerKeys)
                .filter((e: any) => {
                  if (
                    e[1].reduxKey === "mediaAttached" &&
                    featureMedia === "false"
                  ) {
                    return false;
                  }
                  if(orbisDuplicate.isEmergency === true && 
                    (e[1].reduxKey === "userChosenVulnerabilityQuestion" && e[1].urlPath == "vulnerabilityquestion")
                  ) 
                {
                    return false;
                }
                  if ((e[1]?.urlPath == "vandalismquestion" && eligibilityResponseObject?.statuses?.isInDefects === true && vandalismConfigValue === true) || e[1]?.urlPath != "vandalismquestion") {
                    if ((e[1]?.urlPath == "covidquestion" && covidConfigValue) || e[1]?.urlPath != "covidquestion") {
                      if (e[1]?.urlPath !== "duplicaterepair" || (e[1]?.urlPath == "duplicaterepair" && savedResponses[e[1]?.reduxKey] === "I don't think this is a duplicate")) {
                        return savedResponses[e[1]?.reduxKey];
                      }
                    }
                  }
                })
                .map(renderData)}
            </div>
          )}
        </div>

        <div className="col-md-12 marginTop-auto">
          <div className="row m-0 footer-actionbtn">
            <div className="col btn-top padding-repair mb-0" id="confirmation-main-btn">
              <BackModal id="confirm-backmodal" back={backButtonHandler} />
              <ExitModalAndSave
                id="confirmation-exitmodal"
                exit={exitButtonHandler}
                saveDraftAndExit={saveDraftAndExit}
                closeAfterSaving={closeAfterSaving}
              />
            </div>
            <div className="col text-end pt-24 padding-repair">
              <Button
                id="btn_confirmation"
                className="primary-btn"
                onClick={callPageHandler}
                disabled={disabled}
              >
                {t("continuen_button")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationDetails;
