import { AnyAction } from "redux";
import {
  QUERY_POSTCODE,
  SAVE_TENANCIES,
  SAVE_ASSOCIATED_ASSETS,
  SAVE_CUSTOMER_ID,
  APP_CONFIG_VALUE,
  SAVE_ASSET_ADDRESS,
  SAVE_DEFAULT_ADDRESS,
  SAVE_SELECTED_TENANCICY,
  CUSTOMER_DETAILS,
  WORK_ORDER_REPAIR,
  SAVE_CUSTOMERS_PHONE_NUMBERS,
  SAVE_CUSTOMERID,
  SAVE_CONTRACTOR_NAME,
  SAVE_MAINTRESP,
  SAVE_REPRESP,
  SAVE_HEATINGCASE,
  SAVE_DETERMINED_CONTRCACTOR_DETAILS,
  SAVE_CONTRCACTOR_PAYLOAD,
  SAVE_AVAILABILITY_RESPONSE,
  SESSION_TIME_OUT_MESSAGE,
  SAVE_REFERENCE_NUMBER,
  SAVE_PREFERENCE_NUMBER,
  APP_CONFIG_COVID_VALUE,
  APP_CONFIG_VANDALISM_VALUE,
  APP_CONFIG_CASE_CARD_VALUE
} from "../actionTypes";

const addressReducer = (state: any = [], action: AnyAction): any => {
  switch (action.type) {
    case QUERY_POSTCODE: {
      return {
        addresses: action.payload,
      };
    }
    case SAVE_TENANCIES: {
      return {
        ...state,
        customertenancies: action.payload,
      };
    }
    case SAVE_ASSOCIATED_ASSETS: {
      return {
        ...state,
        customerassociatedassets: action.payload,
      };
    }
    case SAVE_CUSTOMER_ID: {
      return {
        ...state,
        customerid: action.payload,
      };
    }
    case APP_CONFIG_VALUE: {
      return {
        ...state,
        toggleValue: action.payload,
      };
    }
    case APP_CONFIG_COVID_VALUE: {
      return {
        ...state,
        covidtoggleValue: action.payload,
      };
    }
    case APP_CONFIG_VANDALISM_VALUE: {
      return {
        ...state,
        vandalismtoggleValue: action.payload,
      };
    }
    case APP_CONFIG_CASE_CARD_VALUE: {
      return {
        ...state,
        caseCardtoggleValue: action.payload,
      };
    }
    case SAVE_DEFAULT_ADDRESS: {
      return {
        ...state,
        defaultaddresss: action.payload,
      };
    }
    case SAVE_ASSET_ADDRESS: {
      return {
        ...state,
        assetAddress: action.payload,
      };
    }
    case SAVE_SELECTED_TENANCICY: {
      return {
        ...state,
        selectedTenancy: action.payload,
      };
    }
    case WORK_ORDER_REPAIR: {
      return {
        ...state,
        workOrderRepair: action.payload,
      };
    }
    case CUSTOMER_DETAILS: {
      return {
        ...state,
        saveCustomerDetails: action.payload,
      };
    }
    case SAVE_CUSTOMERS_PHONE_NUMBERS: {
      return {
        ...state,
        customerPhoneNumber: action.payload,
      };
    }
    case SAVE_CUSTOMERID: {
      return {
        ...state,
        customerid: action.payload,
      };
    }
    case SAVE_CONTRACTOR_NAME: {
      return {
        ...state,
        contractorname: action.payload,
      };
    }
    case SAVE_REFERENCE_NUMBER: {
      return {
        ...state,
        referenceno: action.payload,
      };
    }
    case SAVE_PREFERENCE_NUMBER: {
      return {
        ...state,
        preferenceno: action.payload,
      };
    }
    case SAVE_DETERMINED_CONTRCACTOR_DETAILS: {
      return {
        ...state,
        determinedcontractordetails: action.payload,
      };
    }

    case SAVE_CONTRCACTOR_PAYLOAD: {
      return {
        ...state,
        getContractorPayload: action.payload,
      };
    }
    case SAVE_MAINTRESP: {
      return {
        ...state,
        maintresp: action.payload,
      };
    }
    case SAVE_REPRESP: {
      return {
        ...state,
        repairresp: action.payload,
      };
    }
    case SAVE_HEATINGCASE: {
      return {
        ...state,
        heatinghotwatercase: action.payload,
      };
    }
    case SAVE_AVAILABILITY_RESPONSE: {
      return {
        ...state,
        contractorAvailabilityResponse: action.payload,
      };
    }
    case SESSION_TIME_OUT_MESSAGE: {
      return {
        ...state,
        showSessionTimeOutMessage: action.payload,
      };
    }
    default:
      return state;
  }
};
export default addressReducer;
