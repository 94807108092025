import React from "react";
import Modal from "./index";
import { ModalProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as api from "../../api";
import { Asset, AssetType, Statuses, UpdateRepair } from "../../models/Repair/Statuses";
import { PremisesTypeId } from "../../models/Customer/Premises";
import Clear from "../../assets/img/Clear.png";
import Button from "../../storybook/Button";


const CancelModelRepair: React.FC<ModalProps> = ({ workOrderId, area, errorCode, props }) => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = React.useState(false);

	const modelOpen = () => {
		setIsOpen(true)
	}
	const modelClose = () => {
		setIsOpen(false)
	}

	const cancelAndExit = () => {
		(async () => {
			try {
				const repair = new UpdateRepair();
				const statuses = new Statuses();
				const assetType = new AssetType();
				const asset = new Asset();
				assetType.assetTypeId =
					PremisesTypeId[area as keyof typeof PremisesTypeId];
				if (area === "Divided Block") {
					assetType.assetTypeId = 5;
				}
				asset.type = assetType;
				repair.asset = asset;
				statuses.Status = "Cancelled";
				repair.statuses = statuses;
				await api.repaireWorkOrderUpdate(
					repair,
					workOrderId
				)
				window.location.reload();				
			} catch (e) {
				console.error(e);
				props.history.push("/genericerror");
			}
		})();
	};
	return (
		<>
			<a id="exitmodal-btn1" data-testid="Cancel-button" className="secondary-ghost-btn" onClick={modelOpen} onKeyDown={modelOpen}>{t("cancel_repair_button")}</a>
			<Modal open={isOpen} data-testid="CancelModel" cssClasses="modalPopUp">
				<div>
					<div className="text-end pb-16"><img src={Clear} data-testid="CancelModelClose" className="cursor-pointer" alt="Cancel" onClick={modelClose}  onKeyDown={modelClose}  /></div>

					{errorCode == "SSR036" ? (
						<div id="exitmodal-div2">
							<h1 className="header-size pt-0">
								{t("SSR036i")}
							</h1>
							<p className="modal-para">{t("SSR036ii")}</p>
						</div>
					) : errorCode == "SSR066" ? (
						<div id="exitmodal-div2">
							<h1 className="header-size pt-0">
								{t("SSR066i")}
							</h1>
							<p className="modal-para">{t("SSR066ii")}</p>
							<p className="modal-para">{t("SSR066ii_sec_txt")}</p>
						</div>
					)
						: errorCode == "SSR008" ? (
							<div id="exitmodal-div2">
								<h1 className="header-size pt-0">
									{t("SSR008i")}
								</h1>
								<p className="modal-para">{t("SSR008ii")}</p>
								<p className="modal-para">{t("SSR066ii_sec_txt")}</p>
							</div>) : (
							<div id="exitmodal-div2">
								<h1 className="header-size pt-0">
									{t("SSR073i")}
								</h1>
								<p className="modal-para">{t("SSR073ii")}</p>
							</div>
						)}

					<div id="exitmodal-div3">
						{errorCode == "SSR036" ? (
								<div className="modalPopup-footerbtn">								
										<div className="col-md-12 marginTop-auto">
											<div className="row m-0">
												<div className="col-lg-3 btn-top bordertop-none cancel-exitBtn">													
													<Button id="exitmodal-btn2" onClick={modelClose} className="secondary-ghost-btn">
													{t("return_repair_button")}
													</Button>
												</div>
												<div className="col-lg-9 text-end bordertop-none">													
													<Button id="exitmodal-btn4" onClick={cancelAndExit} className="primary-btn btn-padding">
													{t("Continue_cancel")}
													</Button>
												</div>
											</div>
										</div>
									</div>
							)
							: (
									<div
									id="backmodal-div3"
									className="modalPopup-footerbtn"
								>
									<div className="col-md-12 marginTop-auto">
										<div className="row m-0">
											<div className="col-lg-12 text-end bordertop-none">
												<Button id="exitmodal-btn2" onClick={modelClose} className="secondary-ghost-btn btn-padding">
													{t("OK")}
												</Button>
											</div>
										</div>
									</div>
								</div>
							)}
					</div>
				</div>
			</Modal>
		</>
	);
};

export default CancelModelRepair;
