import "./index.scss";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import Button from "../../Button/Button";
import * as api from "../../api";
import { uuid } from "uuidv4";
import {
  CreateAppointmentParams,
  getAvailabilityForAppointment,
  patchAppointment,
  postAppointment,
} from "../../api/appointmentApi";
import { RawAvailablity } from "../../models/Appointment";
import {
  getContractorName,
  getPrefferenceNumber,
  getReferenceNumber,
  saveAvailabilityResponse,
} from "../../ducks/redux/actions/getAddress";
import { isNullOrUndefined } from "../../utils/formatting";
import { showMenuTabs, userChosenPropertyType, showFooter} from "../../ducks/redux/actions/userChoices";
import moment from "moment";
import { RescheduleAppointmentModel } from "../../models/RescheduleAppointment";
import ExitModal from "../Modal/ExitModal";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import HeaderJourney from "../../storybook/Header/HeaderJourney"
import PageHeader from "../../storybook/PageHeader/PageHeader";
import Message from "../../storybook/Message";
import BackModal from "../Modal/BackModal";
import { googleAnalyticsTracking } from "../../utils/Analytics";
import Modal from "../Modal";
import { saveSelectedSlotDate, setTriageRepair } from "../../ducks/redux/actions/FinancialReferral";


const AppointmentScheduler: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const range = 5;
  const [track, setTrack] = useState(0);
  const [error, setError] = useState("");
  const [selectedSlot, setSelectedSlot] = React.useState<any>(null);
  const history = useHistory();
  const [schedules, setSchedules] = useState<any>({
    availabilities: null,
  });
  const [buttondisable, setButtonDisable] = React.useState(true);
  const [isRescheduleAppointment, setIsRescheduleAppointment] = React.useState(false);
  const [existingRescheduleMessage, setExistingRescheduleMessage] = React.useState("");
  const [isRescheduleApiDown, setErrorForRescheduleApiDown] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [popup, setPopup] = useState(false)
  const availabilityData = useSelector(
    (state: any) => state.addresses?.contractorAvailabilityResponse
  );
  const customerID = useSelector((state: any) => state.addresses?.customerid);
  const isPreferenceAppointment = useSelector(
    (state: any) => state.addresses?.preferenceno
  );
  const referenceNumberCode = useSelector(
    (state: any) => state.addresses?.referenceno
  );
  const workOrderObject = useSelector(
    (state: any) => state?.addresses?.workOrderRepair
  );
  const repairHistoryData = useSelector(
    (state: any) => state.historicRepairReducer?.repairHistoryData
  );
 
  const phone = useSelector(
    (state: any) => state.userChoicesReducer?.userChosenContactPhone
  );
  const name = useSelector(
    (state: any) => state.userChoicesReducer?.userChosenContactName
  );
 
  const loggedInStatus = useSelector(
    (state: any) => state?.userChoicesReducer?.loginStatus
);
  useEffect(() => {
    dispatch(showMenuTabs(false));
    dispatch(showFooter(false));
    dispatch(getPrefferenceNumber(true));    
    GetJobNumber();
    googleAnalyticsTracking(customerID);
  }, []);
  const [calendarSelect, setCalendarSelect]: any = useState([[]]
 
  );
  const [markSelectedSlot, setMarkSelectedSlot]: any = useState({
    objects: [[{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }],
    [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }],
    [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }],
    [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }],
    [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }]]
  })
 
  useEffect(() => {
    setSchedules({
      availabilities: availabilityData,
    });
  }, [availabilityData]);

  
  const tableActive = (dateIndex: any, scheduleIndex: any, data: any) => {
    for (let i = 0; i <= 4; i++) {
      for (let j = 0; j < 5; j++) {
        if (i == dateIndex && j == scheduleIndex) {
          setCalendarSelect(markSelectedSlot.objects[dateIndex][scheduleIndex]);
        }
      }
    }
  }
  const calBackground = (dateIndex: number, scheduleIndex: number) => {
    if (markSelectedSlot.objects[dateIndex][scheduleIndex] == calendarSelect) {
      return "click-calander sc-sched-slots cal-btn color-new error-span-remove";
    }
    else {
      return "sc-sched-slots cal-btn color-new error-span-remove"
    }
  }
  const changeRange = (back: boolean) => {
    setCalendarSelect(null);
    const maxLength = schedules.availabilities[0].slots.length;
    if (back) {
      const nextTrack = track - range;
      nextTrack < 0 ? setTrack(0) : setTrack(nextTrack);
    } else {
      const nextTrack = track + range;
      if (nextTrack <= maxLength) setTrack(nextTrack);
    }
  };
 
  const [isLoading, setIsLoading] = React.useState(true);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const { t } = useTranslation();

  const msgError = t("SSR062");


  

  const callPageHandler = () => {
    if (
      typeof selectedSlot === "undefined" ||
      selectedSlot === null ||
      selectedSlot === ""
    ) {
      setError(msgError);
    }
    else {
      createAppointmentonFinalStep();
    }
  };
 
  const callReturnToRepairPageHandler = () => {
    props.history.push("/historicRepairs");
  };
  const callNextHandler = () => {
     setPopup(true)
  };


  interface GetAvailabilityData {
    availabilities: RawAvailablity[];
    isPreferences: boolean;
    hasSlaAvailability: boolean;
    earliestAvailabilityType: string;
    title: string;
    statusCode: number;
  }
 
  let referenceNumber = "";
  let operationKey = "";
  let workOrderId = "";
 
  const GetJobNumber = async () => {
    setIsLoading(true);
    const noOfHits = process.env.REACT_APP_API_HITS;
    const timeoutValue = process.env.REACT_APP_API_TIMEOUT;
    const noOfHitsValue = Number(noOfHits);
    const noOfTimeoutValue = Number(timeoutValue);
    let i = 0;
    let jobNumber = "";
    let interval = setInterval(async () => {
      if (i < noOfHitsValue) {
        i++;
        try {
          if (jobNumber == "") {
            if (!isNullOrUndefined(repairHistoryData)) {
              workOrderId = repairHistoryData.repairHistoryWorkOrderID;
              if (repairHistoryData.repairHistoryContractor !== undefined) { dispatch(getContractorName(repairHistoryData.repairHistoryContractor)) }
            }
            if (!isNullOrUndefined(workOrderObject)) {
              workOrderId = workOrderObject.id;
            }
            const repair = await api.getRepair(workOrderId);
            dispatch(userChosenPropertyType(repair.premisesType));
            jobNumber = repair?.jobNo;
 
            if (jobNumber) {
              clearInterval(interval);
              referenceNumber = jobNumber;
              operationKey = uuid();
              try {
                getAvailabilityForAppointment(
                  workOrderId,
                  referenceNumber,
                  operationKey
                ).then((payload: GetAvailabilityData) => {
                  const data = payload?.availabilities;
                  const dataIsPreference = payload?.isPreferences;
                  let statusCode = payload?.statusCode;
                  if (!isNullOrUndefined(repairHistoryData) && repairHistoryData?.repairHistoryOperation === "RescheduleAppointment") {
                    setIsRescheduleAppointment(true);
                    const appointmentStartTime = moment(repair?.appointmentStartDate).format('HH:mm')
                    const appointmentEndTime = data.find(x => x.availabilityDetails.start === appointmentStartTime)?.availabilityDetails.end;
                    const appointmentSlotType = data.find(x => x.availabilityDetails.start === appointmentStartTime)?.availabilityDetails.availabilityTypeLabel;
                    const appointmentDay = moment(repair?.appointmentStartDate).format("dddd Do MMMM");
                    setExistingRescheduleMessage(t("Existing_Appointment_Message") + `${appointmentDay}, ${moment(appointmentStartTime, 'hh:mm a').format('hh:mm a')} to ${moment(appointmentEndTime, 'hh:mm a').format('hh:mm a')}`);
                  }
 
                  dispatch(saveAvailabilityResponse(data));
                  dispatch(getReferenceNumber(referenceNumber));
                  dispatch(getPrefferenceNumber(dataIsPreference));
                  if (statusCode != 200 && statusCode != undefined) {
                    setIsLoading(false);
                    const timerId = setTimeout(() => {
                      props.history.push("/genericerror");
                    }, 1000);
                    return;
                  }
                  if (data != undefined && data?.length > 0) {
                    setIsLoading(false);
                    setButtonDisable(false);
                    props.history.push("/appointmentscheduler");
                    return;
                  } else if (payload?.title === "Cannot book appointment") {
                    setIsLoading(false);
                    setButtonDisable(false);
                    dispatch(setTriageRepair(false));
                    props.history.push("/customer360View");
 
                  }
                });
              } catch (e) {
                console.log("no appointment", e);
                setIsLoading(false);
                dispatch(setTriageRepair(false));
                props.history.push("/customer360View");
              }
            }
          }
        } catch (e) {
          console.log(e);
          const timerId = setTimeout(() => {
            props.history.push("/genericerror");
          }, 1000);
        }
      } else {
        clearInterval(interval);
        dispatch(setTriageRepair(false));
        props.history.push("/customer360View");
      }
    }, noOfTimeoutValue);
    return () => clearInterval(interval);
  };
  
  const tConv24 = (time24: string) => {
    let ts = time24;
    let H = +ts.substr(0, 2);
    let h: any = H % 12 || 12;
    h = h < 10 ? +h : h;
    let ampm = H < 12 ? "AM" : "PM";
    ts = h + ampm;
    return ts;
  };
  const createAppointmentonFinalStep = async () => {
        if (!isNullOrUndefined(repairHistoryData) && repairHistoryData?.repairHistoryOperation === "RescheduleAppointment") {        
        const rescheduleAppointmentParam: RescheduleAppointmentModel = {
          status: "RESCHEDULE",
          reasonCode: "RESCHEDULE_APPOINTMENT",
          remarks: "",
          isPreferred: isPreferenceAppointment,      
          jobNumber: referenceNumberCode,
          appointmentFromDate: selectedSlot.date,
          appointmentToDate: selectedSlot.date,
          externalCreateDateTime: selectedSlot.date,
          availabilityType: selectedSlot.availabilityType,
          isInsideSla: selectedSlot.insideSla,
          contractorSiteCode: repairHistoryData.repairHistoryContractorSitesIdentifier
        }
        setIsRescheduleAppointment(true);
        setErrorForRescheduleApiDown(false)
        setIsNextLoading(true);
        setButtonDisable(true);
        if (!isNullOrUndefined(repairHistoryData) && repairHistoryData?.repairHistoryWorkOrderID != undefined) {
          workOrderId = repairHistoryData.repairHistoryWorkOrderID;
        }
        else if (!isNullOrUndefined(workOrderObject)) {
          workOrderId = workOrderObject.id;
        }
        try {
          const result = await patchAppointment(
            workOrderId,
            rescheduleAppointmentParam
          );
         
          if (result && !result.appointmentId) {  
              setErrorForRescheduleApiDown(true)
              setErrorMessage(t("Reschedule_ApiFailError"))
              if (result?.status === 412) {
               let message = t("SSR071");
               message = message.replace("{0}", name).replace("{1}", phone);
               setErrorMessage(message);
             }
            }
          else
          {
            setErrorForRescheduleApiDown(false)
            dispatch(saveSelectedSlotDate(selectedSlot));
            dispatch(setTriageRepair(false));
            history.push("/customer360View");
            setIsNextLoading(false);
            setButtonDisable(false);
          }
        } catch (e) {
          console.log("Reschedule Appointment", e);
          const timerId = setTimeout(() => {
            props.history.push("/genericerror");
          }, 1000);
        }    
    } else {
      const createAppointmentParams: CreateAppointmentParams = {
        isPreferences: isPreferenceAppointment,
        jobNumber: referenceNumberCode,
        appointmentFromDate: selectedSlot.date,
        availabilityType: selectedSlot.availabilityType,
        isInsideSla: selectedSlot.insideSla,
        reasonCode: "NEW_APPOINTMENT",
        remarks: "",
        appointmentToDate: selectedSlot.date,
        externalCreateDateTime: selectedSlot.date,
        externalReferenceId: ""
      };
      setIsNextLoading(true);
      setButtonDisable(true);
      if (!isNullOrUndefined(repairHistoryData) && repairHistoryData?.repairHistoryWorkOrderID != undefined) {
        workOrderId = repairHistoryData.repairHistoryWorkOrderID;
      }
      else if (!isNullOrUndefined(workOrderObject)) {
        workOrderId = workOrderObject.id;
      }
      try {
        const result = await postAppointment(
          workOrderId,
          createAppointmentParams,
          operationKey
        );
 
        if (result?.statusCode != 500) {
          dispatch(saveSelectedSlotDate(selectedSlot));
          dispatch(setTriageRepair(false));
          history.push("/customer360View");
          setIsNextLoading(false);
          setButtonDisable(false);
        }
        else {
          const timerId = setTimeout(() => {
            history.push("/genericerror");
          }, 1000);
        }
 
      } catch (e) {
        console.log("createAppointment", e);
        const timerId = setTimeout(() => {
          props.history.push("/genericerror");
        }, 1000);
      }
    }
  };
 
  const exitButtonHandler = () => {    
    if (isUndefinedNullOrEmpty(loggedInStatus)) {
      props.history.push("/startpage")
    }
    else {
      props.history.push("/customer360View")
    }
  };
  return (
    <>
    <HeaderJourney></HeaderJourney>
    <div className="container-fluid parent-footer p-0">
       {isRescheduleApiDown ? (
        <>
        <div className="padding-repair">
     
        <div className="gen-error">
          <div className="error" role="alert" id="repair-asset-alert">
            <Message className="warning-msg">  
                {errorMessage}
            </Message>
         
            <div
                className="flex justify-between items-center pb-4 md:pb-8 mt-perc"
                id="repair-exit-btn"
              >
              <div className="flex justify-between items-center">
              <BackModal
                id="reploc-backmodal"
                back={callReturnToRepairPageHandler}
                btn_msg="return_case_button"
              />
                <ExitModal
                  id="repairproperty-exitmodal"
                  exit={exitButtonHandler}
                />
             </div>
            </div>
          </div>
        </div>
        </div>
        </>
      ) : (
        <div className="padding-repair">
          <div id="Cal-main-div">
            <PageHeader>{isRescheduleAppointment ? t("Reschedule_Appointment_Heading") : !isPreferenceAppointment ? t("Scheduler_Heading") : t("SSR062")}</PageHeader>
          </div>
 
          {error && (
            <Message className="warning-msg">
              {error}
            </Message>
          )}
 
          {isLoading ? (
            <div className="pl-2">
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={50}
                width={50}
              />
              <h6>{t("SSR061")}</h6>
            </div>
          ) : (
            <div>
              <div id="Cal-main-div1">
                <h6 className="pl-2 mt-24"> {isRescheduleAppointment ? existingRescheduleMessage : (!isPreferenceAppointment ? t("Pick_Time_Slot") : t("Pick_Slot_Prefference"))} </h6>
              </div>
              {schedules.availabilities && (
                <div className="apScheduler">
                  <div className="flex next-prev-pad justify-between items-center pb-2 md:pb-8 space-right">
                    <div>
                      {track === 0 ? (
                        <a></a>
                      ) : (
                        <a
                          className="text-teal mouse-pointer"
                          onClick={() => changeRange(true)}
                        >
                          <i className="fas fa-chevron-left"></i>
                          {t("Previous")}
                        </a>
                      )}
                    </div>
                    <div>
                      {track + range > schedules.availabilities[0].slots.length ? (
                        <a></a>
                      ) : (
                        <a
                          className="text-teal mouse-pointer"
                          onClick={() => changeRange(false)}
                        >
                          {t("Next")}
                          <i className="fas fa-chevron-right"></i>
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="sc-wrapper">
                    
                    {schedules.availabilities[0].slots
                      .slice(track, track + range)
                      .map(({ date, insideSla }: any, dateIndex: any) => {
                        const dateInfo = new Date(date);
                        const day = ('0' + dateInfo.getDate()).slice(-2);
                        const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(dateInfo);
                        const year = ('' + dateInfo.getFullYear()).slice(-2);
                        const formattedDate = `${day} ${month} ${year}`;
                     
                        return (
                          <div className="sc-schedules">
                            <div className="sc-sched-date sc-mob-date margin-rt">
                              <p className="wd-2">
                                {formattedDate}
                              </p>
                            </div>
                            <div className="align-schedule-timings wt1 mt-top row">
                              {schedules.availabilities.map(
                                (
                                  {
                                    availabilityDetails: {
                                      start,
                                      end,
                                      availabilityTypeLabel,
                                      availabilityType,
                                    },
                                  }: any,
                                  scheduleIndex: any
                                ) => {
                                  return schedules.availabilities[scheduleIndex]
                                    .slots[track + dateIndex].capacity ==
                                    "Not Available" ? (
                                    <div className="sc-sched-slots cal-btn disabled-p color_black error-span-remove margin-top">
                                      -
                                    </div>
                                  ) : (
                                    <button
                                      onClick={() => {
                                        setError("");
                                        setSelectedSlot({
                                          start,
                                          end,
                                          availabilityTypeLabel,
                                          date,
                                          insideSla,
                                          availabilityType,
                                        });
                                        tableActive(dateIndex, scheduleIndex, "setSelectedSlot" + dateIndex + scheduleIndex);
 
                                      }} className={calBackground(dateIndex, scheduleIndex)}
                                      data-testid={`setSelectedSlot` + dateIndex + scheduleIndex}
                                    >
                                      <p className="wd-1">{`${tConv24(
                                        start
                                      )} - ${tConv24(end)}`}</p>
                                    </button>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          )}
 
            <div className="row appointment-btn-grp footer-actionbtn mt-68 mb-30">
              <div className="col-md-6 mt-8">
                      {isRescheduleAppointment  && (
                          <Button
                            id="btn_returntorepair"
                            disabled={buttondisable}
                            onClick={callReturnToRepairPageHandler}
                            className="secondary-ghost-btn"
                          >
                            {t("return_to_repair_button")}
                          </Button>
                 
                      )}
                      {!isRescheduleAppointment && !isPreferenceAppointment && (
                          <a
                            id="btn_nosuitableappoinment"
                            className="secondary-ghost-btn"
                            onClick={callNextHandler}
                          >
                            {t("No_Suitable_Appointment")}
                          </a>
                      )}
                </div>
                <div className="col-md-6 text-end mt-8">
                    <Button
                      id="btn_bookappointment"
                      disabled={buttondisable}
                      onClick={callPageHandler}
                      className="primary-btn"
                    >
                      {t("Book_Appointment")}
                    </Button>
                </div>
            </div>
          <div>
            <span>
              {isNextLoading ? (
                <div>
                  <Loader
                    type="ThreeDots"
                    color="#00BFFF"
                    height={50}
                    width={50}
                    timeout={5000}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </span>
          </div>
         
        </div>
      )} 
    </div>
    <Modal open={popup} cssClasses="modalPopUp">
    <div className="py-4 px-4">
      <div
        className="close"
        onClick={() => setPopup(false)}
        onKeyPress={() => setPopup(false)}
      >
       <h4>X</h4> 
      </div>
      <h3>{t("SSR023")}</h3>
      <p className="mt-4">
        {t("suitable_appointment")}
      </p>
      
      <div className="row footer-actionbtn mt-68 mb-30">
        <div className="col-md-6 mt-8">
            <Button
              data-testid="finishandcreate"
              onClick={()=>setPopup(false)}
              className="secondary-ghost-btn"
            >
              {t("Check_Appointments_Button")}
            </Button>
          </div>
            <div className="col-md-6 text-end mt-8">
                    <Button
                      disabled={buttondisable}
                      onClick={()=> {dispatch(setTriageRepair(false));props.history.push("/customer360View")}}
                      className="primary-btn"
                    >
                       {t("continuen_button")}
                    </Button>
            </div>
          
        </div>
      
    </div>
  </Modal> 
    </>
  );
};
 
export default AppointmentScheduler;
 