import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../api";
import "./index.scss";
import * as ReactBootstrap from "react-bootstrap";
import Loader from "react-loader-spinner";
import { RouteComponentProps } from "react-router-dom";
import Button from "../../Button/Button";
import {
  userChosenDuplicateQuestion,
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  isEditState,
  resumeDraftRepair,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenIsReporter,
  userChosenPropertyAddress,
  userResumeData,
  userChosenPropertyType,
  userChosenUPRN,
  userChosenRepairLocationId,
  userChosenSupplementaryQuestions,
  userChosenEmergencyQuestion,
  userChosenAdditionalDescriptionCOUNT,
  userResumeDraftDataCache,
  userChosenPropertyId,
  userChosenSOR,
  userChosenRepairLocation,
  userChosenHighLevelDescription,
  userChosenResumeRepair,
  loadDuplicateRepairPage,
} from "../../ducks/redux/actions/userChoices";
import BackModal from "../Modal/BackModal";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import {
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { WorksOrdersResponse } from "../../models/WorksOrders";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import {
  saveCustomerDetails,
  saveCustomerphoneNumber,
  selectedTenacyType,
  workOrderRepairObject,
} from "../../ducks/redux/actions/getAddress";
import { RepairChannel } from "../../models/Repair/RepairChannel";
import { RepairStatusField } from "../../models/Repair/Repair";
import HeaderJourney from "../../storybook/Header/HeaderJourney";
import PageHeader from "../../storybook/PageHeader/PageHeader";
import Message from "../../storybook/Message";
import Alert from "@mui/material/Alert";

interface DuplicateRepairProps extends RouteComponentProps<any> {}
const DuplicateRepair: React.FC<DuplicateRepairProps> = (props) => {
  let moment = require("moment");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isResumeLoading, setIsResumeLoading] = React.useState(false);
  const [radiovalue, setRadioValue] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [closeAfterSaving, setCloseAfterSaving] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isResumeDraftRepair, setIsResumeDraftRepair] = useState(false);
  const [BlockMsg, setBlockMsg] = useState<any>();
  const [disableContinueforEnhancedEligiblityCheck, setDisableContinueforEnhancedEligiblityCheck] = useState(false);

  const selectproperty = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyType
  );
  const userChosenEmergencyQuestionVal = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenEmergencyQuestion
  );
  const userChosenSORValue = useSelector(
    (state: any) => state.userChoicesReducer.userChosenSOR
  );
  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );

  const customerID = useSelector((state: any) => state.addresses?.customerid);
  const userChosenPropertyIdValue = useSelector(
    (state: any) => state.userChoicesReducer.userChosenPropertyId
  );
  const userDuplicateQuestion = useSelector(
    (state: any) => state.userChoicesReducer.userChosenDuplicateQuestion
  );
  const fetchedRepairReasonsSelection = useSelector(
    (state: any) => state.dashboardReducer.formState.fetchedRepairReasonsSelect
  );
  const selectedproperty = useSelector(
    (state: any) => state?.dashboardReducer?.formState?.tracks[0]?.assetType
  );
  const priority = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonObject[0]
        ?.priority
  );
  const appConfigValue = useSelector(
    (state: any) => state?.addresses?.toggleValue
  );
  const eligibilityResponseObject = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.eligibilityResponseObject
  );
  const vandalismConfigValue = useSelector(
    (state: any) => state?.addresses?.vandalismtoggleValue
  );
  const covidConfigValue = useSelector(
    (state: any) => state?.addresses?.covidtoggleValue
  );
  const resumeDraftRepairVal = useSelector(
    (state: any) => state.userChoicesReducer.resumeDraftRepair
  );

  const [duplicateRepairResult, setDuplicateRepairResult] =
    useState<WorksOrdersResponse>();

  const userChosenResumeRepairValue = useSelector(
    (state: any) => state.userChoicesReducer.userChosenResumeRepair
  );

  const userCreatedNewRepairValue = useSelector(
    (state: any) => state.userChoicesReducer.userCreatedNewRepair
  );
  const isCatalyst = useSelector(
    (state: any) => state.userChoicesReducer?.isCatalystCustomer
  );
  const orbisDuplicate = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );

  const getIsEmergency = () => {
    if(selectedproperty === "Property" && priority === "Emergency") {
      if (userChosenEmergencyQuestionVal === "Yes, I can provide access") {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if (userDuplicateQuestion != "") {
      setRadioValue(userDuplicateQuestion);
      setIsDisabled(false);
      dispatch(loadDuplicateRepairPage(true));
    }
    (async () => {
      try {
        const propertiesIds = [];
        if (userChosenPropertyIdValue)
          propertiesIds.push(userChosenPropertyIdValue);

        let duplicateRepairStatuses = "Unappointed,Appointed,In Progress,In Diagnosis"
        duplicateRepairStatuses += ',' + RepairStatusField.CatalystTier2Triage
        duplicateRepairStatuses += ',' + RepairStatusField.CatalystEMATriage
        duplicateRepairStatuses += ',' + RepairStatusField.CatalystDefectTriage
        const sorValue = userChosenSORValue == "N/A"? "NA": userChosenSORValue;
        dispatch(userChosenSOR(sorValue));
        const queryParams = {
          propertiesIds,
          sor: sorValue,
          location: "",
          description: "",
          status: duplicateRepairStatuses,
          isDuplicateCheck: true,
        };

        const duplicateRepair = await api.getWorksOrdersApi(queryParams);

        Promise.all([duplicateRepair]).then((results) => {
          results.forEach((element) => {
            if (selectedproperty === "Block" || selectedproperty === "Estate") {
              const resultF = element.worksOrders.filter(
                (wo) =>
                  (wo?.asset?.type?.assetTypeName === "Block" ||
                    wo?.asset?.type?.assetTypeName === "Estate") &&
                  wo?.statuses?.status === "Unappointed"
              );
              if (resultF.length <= 0) {
                props.history.push("/repairadditionaldetails");
                return;
              }
            }
          });
          results.map((items) => {
            setDuplicateRepairResult(items);
            const searchDupeWOResultFilteredOutSelf = items.worksOrders.filter(
              (searchWorkOrder) =>
                searchWorkOrder?.identifiers?.worksOrderIdentifier !=
                workOrderObject?.id
            );
            if (
              items?.totalCount === undefined ||
              items?.totalCount <= 0 ||
              searchDupeWOResultFilteredOutSelf.length <= 0
            ) {
              if (props.history.action === "POP") {
                props.history.goBack();
              } else {
                props.history.push("/repairadditionaldetails");
              }
            } else {
              setIsLoading(false);
            }
          });
        });
      } catch (error) {
        console.log(`Error fetching dubplicate check data ${error}`);
        props.history.push("/genericerror");
      }
    })();
  }, []);

  const handleRadioChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBlockMsg("");
    setDisableContinueforEnhancedEligiblityCheck(false);
    if (EditState === true) {
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(isEditState(false));
      dispatch(userChosenVandalismAdditionalInformation(""));
      dispatch(userChosenVandalismQuestion("No"));
      dispatch(userChosenAdditionalDescription(""));
    }
    setError(true);
    setIsDisabled(true);
    setRadioValue(event.target.value);
    dispatch(userChosenDuplicateQuestion(event.target.value));  

    const advancedPayload = {
      "isEmergency": getIsEmergency(),
      "sor": userChosenSORValue,
      "isPotentialDuplicate": event?.target?.value?.split("&")[0] === t("IDT_its_duplicate"),
      "channel": "customerSelfService"
    }      
    try{
      if(event.target.value.split("&")[0] !== "In Diagnosis"){
        const duplicateBlock = await api.postEnhancedRepairEligibility(advancedPayload);
    setBlockMsg(duplicateBlock?.isEligible) 
    if(duplicateBlock?.isEligible==false){
      setDisableContinueforEnhancedEligiblityCheck(true);
        setBlockMsg('');
    }
    }
  }
    catch(e){
      console.log('error',e);
    } 
    setErrorMessage("Duplicate_Repair_SSR044");  

    setErrorMessage("Duplicate_Repair_SSR044");
    if (event.target.value.split("&")[2] === "true") {
      // ie. isSubmitted = true && (isPotentialDuplicate = true || sor = NA || isVulnerable = true)
      setIsDisabled(true);
      setErrorMessage("SSR075a");
    } else if (event.target.value.split("&")[0] === "In Diagnosis") {
      setIsResumeDraftRepair(true);
      setIsDisabled(false);
      setErrorMessage("SSR074");
    } else if (
      event.target.value.split("&")[0] ===
      RepairStatusField.CatalystDefectTriage
    ) {
      setIsResumeDraftRepair(false);
      setIsDisabled(true);
      setErrorMessage("duplicate_msg_catalyst_triage");
    } else if (
      event.target.value.split("&")[0] ===
        RepairStatusField.CatalystEMATriage ||
      event.target.value.split("&")[0] === RepairStatusField.CatalystTier2Triage
    ) {
      setIsResumeDraftRepair(false);
      setIsDisabled(true);
      setErrorMessage("duplicate_msg_catalyst_triage_ema_tier2");
    } else if (resumeDraftRepairVal) {
      setIsResumeDraftRepair(false);
      setIsDisabled(false);
      setErrorMessage("Duplicate_Repair_SSR045");
    } else if (
      event.target.value.split("&")[0] === "I don't think this is a duplicate"
    ) {
      dispatch(workOrderRepairObject(userCreatedNewRepairValue));
      setIsResumeDraftRepair(false);
      setIsDisabled(false);
      setErrorMessage("Duplicate_Repair_SSR045");
    }
  };

  const GetErrorMessage = (errMessage: string, includeSpace: boolean) => {
    if(errMessage.includes("SSR075a")){
    return (
        <>  
        <div className="alert mb-8 p-0" role="alert">
          <Message className="info-msg">
              {includeSpace && " "}
              {t(errMessage)}
            </Message>
          </div>
        </>
      ) }
      else {
     return (
        <>
        <div className="alert mb-8 p-0" role="alert">
          <Message className="info-msg">
            {includeSpace && " "}
            {t(errMessage)}
          </Message>
        </div>
      </>
      )    
     }
  };

  const backButtonHandler = () => {
    dispatch(userChosenDuplicateQuestion(""));
    if (!(priority === "Emergency" && selectedproperty === "Property")) {
      if (
        fetchedRepairReasonsSelection[0]?.additionalData.length > 0 &&
        fetchedRepairReasonsSelection[0]?.additionalData[0]?.description != ""
      ) {
        props.history.replace("/supplementaryquestion");
      } else {
        props.history.replace("/repairlocation");
      }
    } else {
      props.history.push("/repairemergency");
    }
  };

  const exitButtonHandler = () => {
    props.history.push("/customer360View");
  };

  const saveDraftAndExit = () => {
    if (radiovalue === "") {
      props.history.replace("/customer360View");
    } else {
      setError(false);
      //update workorder
      if (workOrderObject != null) {
        UpdateWorkOrder();
      }
      setCloseAfterSaving(true);
      props.history.replace("/customer360View");
    }
  };

  const workOrderObject = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );

  const UpdateWorkOrder = async () => {
    try {
      userDuplicateQuestion == ""
        ? (workOrderObject.isPotentialDuplicate = false)
        : (workOrderObject.isPotentialDuplicate = true);

      const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
      const woRequestParameters =
        _worksOrderRequest.updateWorkOrderParameter(workOrderObject);

      await api.updateWorkOrderRepair(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      );

      dispatch(workOrderRepairObject(workOrderObject));
    } catch (e) {
      console.error(e);
      props.history.push("/genericerror");
    } finally {
    }
  };

  const callPageHandler = async () => {
    if (isResumeDraftRepair) {
      setIsResumeLoading(true);
      const workOrderId = radiovalue.split("&")[1];
      if (workOrderObject != null && workOrderId !== workOrderObject.id) {
        const getWorkOrderResult: any = await api.getRepair(workOrderObject.id);
        const deleteWorkOrderResponse: any = await api.deleteWorkOrder(
          workOrderObject.id,
          getWorkOrderResult?.eTag?.toString()
        );
      }
      const workOrderResponse = await api.getWorkOrder(workOrderId);
      resumeRepairHandler(workOrderResponse);
    } else if (!radiovalue) {
      setErrorMessage("Duplicate_Repair_SSR055");
    } else {
      dispatch(userChosenResumeRepair(false));
      dispatch(
        userChosenDuplicateQuestion("I don't think this is a duplicate")
      );
      if (workOrderObject != null) {
        UpdateWorkOrder();
      }
      props.history.push("/repairadditionaldetails");
    }
  };

  const resumeRepairHandler = async (draftRepair: any) => {
    dispatch(resumeDraftRepair(true));
    let assetrResponse = null;

    const customerResponse = await api.getCustomer(
      draftRepair.customerIdentifier
    );
    dispatch(saveCustomerDetails(customerResponse));
    dispatch(
      saveCustomerphoneNumber(customerResponse?.addresses?.telecomsAddresses)
    );
    dispatch(userChosenIsReporter(draftRepair.reporterToBeContacted));
    if (draftRepair.reporterContactDetails != null) {
      const name = draftRepair.reporterContactDetails?.name?.trim();
      dispatch(
        userChosenContactPhone(draftRepair.reporterContactDetails.contactNumber)
      );
      dispatch(userChosenContactName(name));
      dispatch(userChosenRelationDetails(draftRepair.reportedBy));
      dispatch(
        userChosenContactDetails(
          "I would like to provide number for this repair."
        )
      );
      draftRepair.reporterContactDetails.name = null;
    } else {
      dispatch(userChosenRelationDetails("Customer"));
      dispatch(userChosenContactPhone(""));
      dispatch(
        userChosenContactDetails(
          customerResponse?.addresses?.telecomsAddresses?.[0].number
        )
      );
    }
    draftRepair.isDraft = null;

    dispatch(workOrderRepairObject(draftRepair));
    const {
      id,
      premisesId,
      repairLocationId,
      repairLocation,
      repairReason,
      isSupplementary,
      isEmergency,
      isPotentialDuplicate,
      additionalInformation,
      isVandalism,
      repairCauseId,
      crimeReferenceNumber,
      isVulnerable,
      VulnerableResponseText,
      isCovidSelfIsolated,
      reporterContactDetails,
      premisesAddress,
      premisesUniqueReference,
      originalWorkOrderSorCode,
      area,
    } = draftRepair;

    if (premisesId) {
      assetrResponse = await api.getAssetsByid(premisesId);
    }
    if (
      assetrResponse?.addresses?.postalAddresses[0].formattedAddress != null
    ) {
      dispatch(
        userChosenPropertyAddress(
          assetrResponse?.addresses?.postalAddresses[0].formattedAddress
        )
      );
    }

    dispatch(selectedTenacyType(area));

    const premiseType =
      area === "Block" || area === "Estate" || area === "Divided block"
        ? "Communal"
        : area;
    const responsedata = await api.searchRepairReasons(
      repairReason,
      premiseType,
      isCatalyst
    );
    const selectedReason = responsedata.filter(
      (reason: any) => reason.description === repairReason
    );
    if (
      selectedReason[0]?.additionalData &&
      selectedReason[0]?.additionalData.length &&
      selectedReason[0]?.additionalData[0]?.description != ""
    ) {
      const resumeData = {
        resumeHLRepair: selectedReason[0]?.description,
        resumeHLSubQuestion: selectedReason[0]?.additionalData[0]?.description,
        resumeSelectedProperty: area,
        resumePriority: "", //props.Priority,
      };
      dispatch(userResumeData(resumeData));
    } else {
      const resumeData = {
        resumeHLRepair: "",
        resumeHLSubQuestion: "",
        resumeSelectedProperty: area,
        resumePriority: "", //props.Priority,
      };
      dispatch(userResumeData(resumeData));
    }
    dispatch(userChosenPropertyId(premisesId));
    dispatch(userChosenPropertyType(area));
    //dispatch(userChosenPropertyAddress(repairReason));
    dispatch(userChosenUPRN(premisesUniqueReference));
    dispatch(userChosenSOR(originalWorkOrderSorCode));
    repairLocation &&
      dispatch(userChosenRepairLocation(repairLocation)) &&
      dispatch(userChosenRepairLocationId(repairLocationId));
    repairReason && dispatch(userChosenHighLevelDescription(repairReason));
    isSupplementary === true
      ? dispatch(userChosenSupplementaryQuestions("Yes"))
      : isSupplementary === false
      ? dispatch(userChosenSupplementaryQuestions("No"))
      : dispatch(userChosenSupplementaryQuestions(""));
    isEmergency &&
      dispatch(userChosenEmergencyQuestion(t("Repair_Emergency_Radio1_Text")));
    additionalInformation &&
      dispatch(userChosenAdditionalDescription(t(additionalInformation))) &&
      dispatch(
        userChosenAdditionalDescriptionCOUNT(additionalInformation.length)
      );
    isVandalism
      ? dispatch(userChosenVandalismQuestion("Yes")) &&
        dispatch(userChosenVandalismAdditionalInformation(crimeReferenceNumber))
      : dispatch(userChosenVandalismQuestion("No"));
    isVulnerable
      ? dispatch(userChosenVulnerabilityQuestion("Yes")) &&
        dispatch(
          userChosenVulnerabilityAdditionalInformation(VulnerableResponseText)
        )
      : dispatch(userChosenVulnerabilityQuestion("No"));
    isCovidSelfIsolated === true
      ? dispatch(userChosenCovidQuestion("Yes"))
      : isCovidSelfIsolated === false
      ? dispatch(userChosenCovidQuestion("No"))
      : dispatch(userChosenCovidQuestion(""));
    const resumeDraftDataCache = {
      repairLocation,
      repairLocationId,
      repairReason,
      supplementaryQuestions:
        isSupplementary === true
          ? "Yes"
          : isSupplementary === false
          ? "No"
          : "",
      emergencyQuestion: isEmergency && t("Repair_Emergency_Radio1_Text"),
      additionalInformation,
      vandalismQuestion: isVandalism ? "Yes" : "No",
      crimeReferenceNumber: isVandalism ? crimeReferenceNumber : "",
      vulnerabilityQuestion: isVulnerable ? "Yes" : "No",

      vulnerableResponseText: isVulnerable ? VulnerableResponseText : "",
      covidQuestion:
        isCovidSelfIsolated === true
          ? "Yes"
          : isCovidSelfIsolated === false
          ? "No"
          : "",
      reporterContactDetails,
    };
    dispatch(userResumeDraftDataCache(resumeDraftDataCache));
    setIsResumeLoading(false);
    if (!repairLocation || !repairReason) {
      dispatch(userChosenHighLevelDescription(""));
      dispatch(userChosenRepairLocationId(0));
      props.history.push("/repairlocation");
    } else if (additionalInformation.trim().length <= 0) {
      props.history.push("/repairadditionaldetails");
    } else if (
      isVandalism === null &&
      eligibilityResponseObject?.statuses.isInDefects === true &&
      vandalismConfigValue === true
    ) {
      props.history.push("/vandalismquestion");
    } else if (isVulnerable === null) {
      props.history.push("/vulnerabilityquestion");
    } else if (isVulnerable === true && VulnerableResponseText === null) {
      props.history.push("/vulnerabilityquestion");
    } else if (
      area === "Property" &&
      isCovidSelfIsolated === null &&
      covidConfigValue === "true"
    ) {
      props.history.push("/covidquestion");
    } else if (reporterContactDetails != null) {
      if (
        draftRepair.reporterContactDetails?.name?.trim()?.length === 0 ||
        draftRepair.reporterContactDetails?.contactNumber?.trim()?.length === 0
      ) {
        if (isCovidSelfIsolated === null && covidConfigValue) {
          draftRepair.reporterContactDetails.name = null;
          props.history.push("/covidquestion");
        } else {
          draftRepair.reporterContactDetails.name = null;
          props.history.push("/customerdetails");
        }
      } else {
        props.history.push("/confirmationdetails");
      }
    } else if (draftRepair.reportedBy.trim().length <= 0) {
      props.history.push("/customerdetails");
    } else {
      if (draftRepair.reporterContactDetails != null) {
        const name = draftRepair.reporterContactDetails?.name?.trim();
        dispatch(
          userChosenContactPhone(
            draftRepair.reporterContactDetails.contactNumber
          )
        );
        dispatch(userChosenContactName(name));
        dispatch(userChosenRelationDetails(draftRepair.reportedBy));
        dispatch(
          userChosenContactDetails(
            "I would like to provide number for this repair."
          )
        );
      } else {
        //dispatch(userChosenContactDetails(draftRepair.co));
        dispatch(userChosenRelationDetails("Customer"));
        dispatch(
          userChosenContactPhone(
            customerResponse?.addresses?.telecomsAddresses?.[0].number
          )
        );
        dispatch(
          userChosenContactDetails(
            customerResponse?.addresses?.telecomsAddresses?.[0].number
          )
        );
      }
      props.history.push("/confirmationdetails");
    }
  };

  const alldata = (item: any, index: any) => {
    const isTriage = 
      item.statuses.isPotentialDuplicate === true ||
      item.statuses.isVulnerable === true ||
      item.job.sorCode === "N/A" || item.job.sorCode === "NA" ||
      item.job.description?.includes("heating or hot water")
    const isSubmitted = item.statuses.isSubmitted
    const isSubmittedTriage = isTriage && isSubmitted

    if(userChosenResumeRepairValue)
    {
      return (      
        <div id="duprepair-main-div" className="parent">
          <div id="duprepair-div1" className="dup-table">
            <FormControlLabel
              id="duprepair-label1"
              key={index}
              control={
                <Radio
                  id="duprepair-radio1"
                  name={item.identifiers.worksOrderIdentifier}
                  data-testid={item.identifiers.worksOrderIdentifier}
                  title=""
                  role="radio"
                />
              }
              label=""
              value={item.statuses.status + "&" + item.identifiers.worksOrderIdentifier + "&" + isSubmittedTriage }
              // value={item.statuses.status}
            />
          </div>

          <div
            id="duprepair-div2"
            className="text-base global-txt border-collapse w-full"
          >
            <ReactBootstrap.Table
              bordered
              id="duprepair-table"
              className="border-collapse w-full"
            >
              <TableBody
                id="duprepair-tbbody"
                className="table-spc border-collapse w-full"
              >
                {/* -----------Mobile view -----*/}

                <TableCell
                  id="duprepair-tr1-td1"
                  className="mobile-tablex supp-table text-base global-txt p-3 border border-grey hidden lg:table-cell text-left font-light"
                >
                  {t("Date_Creation")}
                  <br></br>
                  {moment(item.raisedDate).format("MMMM Do YYYY, h:mm:ss A")}
                </TableCell>
                <TableCell
                  id="duprepair-tr1-td2"
                  className="mobile-tablex supp-table text-base global-txt p-3 border border-grey hidden lg:table-cell text-left font-light"
                >
                  {t("High_Level_Description")}
                  <br></br>
                  {item.job.trade == "Unknown" &&
                  item.channel != RepairChannel.CustomerSelfService &&
                  item.channel != RepairChannel.ERGO &&
                  (appConfigValue == "true" || appConfigValue == "false")
                    ? t("RepairDescription_ErrorMessage")
                    : (item.channel === RepairChannel.CustomerSelfService ||
                        item.channel === RepairChannel.ERGO) &&
                      appConfigValue == "true"
                    ? item.job.description
                    : (item.channel === RepairChannel.CustomerSelfService ||
                        item.channel === RepairChannel.ERGO) &&
                      appConfigValue == "false"
                    ? item.job.description?.split(".")[0]
                    : item.job.trade}
                </TableCell>
                <TableCell
                  id="duprepair-tr1-td3"
                  className="mobile-tablex supp-table text-base global-txt p-3 border border-grey hidden lg:table-cell text-left font-light"
                >
                  {t("Priority")} <br></br>
                  {item.statuses.isEmergency === true
                    ? "Emergency"
                    : item.statuses.isEmergency === false
                    ? "Next Available"
                    : item.statuses.isEmergency === true &&
                      item.statuses.isOutOfHours === true
                    ? "Emergency (OOH)"
                    : null}
                </TableCell>
                <TableCell
                  id="duprepair-tr1-td4"
                  className="mobile-tablex supp-table text-base global-txt p-3 border border-grey hidden lg:table-cell text-left font-light"
                >
                  {t("Status_Text")} <br></br>
                  {item.statuses.isSubmitted && item.statuses.status === "In Diagnosis" &&
                  (item.statuses.isPotentialDuplicate === true ||
                    item.statuses.isVulnerable === true ||
                    item.job.sorCode === "N/A" ||
                    item.job.sorCode === "NA")
                    ? "Under Review"
                    : item.statuses.status === "In Diagnosis"
                    ? "Draft"
                    : item.statuses.status}
                </TableCell>
                {/* End Mobile View */}
                <TableRow
                  id="duprepair-tr2"
                  key={index}
                  style={{ marginTop: 10 }}
                >
                  <TableCell
                    id="duprepair-tr2-tr1"
                    className="dup-desk-hide supp-table text-base global-txt w-full lg:w-auto p-3 pt-10 lg:pt-3 border border-b border-grey text-left block lg:table-cell relative lg:static"
                  >
                    {moment(item.raisedDate).format("MMMM Do YYYY, h:mm:ss A")}
                  </TableCell>

                  <TableCell
                    id="duprepair-tr2-tr2"
                    className="deskt supp-table text-base global-txt"
                  >
                    {item.job.trade == "Unknown" &&
                    item.channel != RepairChannel.CustomerSelfService &&
                    item.channel != RepairChannel.ERGO &&
                    (appConfigValue == "true" || appConfigValue == "false")
                      ? t("RepairDescription_ErrorMessage")
                      : (item.channel === RepairChannel.CustomerSelfService ||
                          item.channel === RepairChannel.ERGO) &&
                        appConfigValue == "true"
                      ? item.job.description
                      : (item.channel === RepairChannel.CustomerSelfService ||
                          item.channel === RepairChannel.ERGO) &&
                        appConfigValue == "false"
                      ? item.job.description?.split(".")[0]
                      : item.job.trade}
                  </TableCell>

                  <TableCell
                    id="duprepair-tr2-tr3"
                    className=" deskt supp-table text-base global-txt"
                  >
                    {item.statuses.isEmergency === true
                      ? "Emergency"
                      : item.statuses.isEmergency === false
                      ? "Next Available"
                      : item.statuses.isEmergency === true &&
                        item.statuses.isOutOfHours === true
                      ? "Emergency (OOH)"
                      : null}
                  </TableCell>

                  <TableCell
                    id="duprepair-tr2-tr4"
                    className="deskt supp-table text-base global-txt"
                  >
                    {item.statuses.isSubmitted && item.statuses.status === "In Diagnosis" &&
                    (item.statuses.isPotentialDuplicate === true ||
                      item.statuses.isVulnerable === true ||
                      item.job.sorCode === "N/A" ||
                      item.job.sorCode === "NA")
                      ? "Under Review"
                      : item.statuses.status === "In Diagnosis"
                      ? "Draft"
                      : item.statuses.status}
                  </TableCell>
                </TableRow>
              </TableBody>
            </ReactBootstrap.Table>
          </div>
        </div>
      );
    } else if (
      workOrderObject != null &&
      workOrderObject?.id !== item.identifiers.worksOrderIdentifier &&
      !(
        (item?.asset?.type?.assetTypeName === "Block" ||
          item?.asset?.type?.assetTypeName === "Estate") &&
        item?.statuses?.status === "In Diagnosis"
      )
    ) {
      return (
        <div id="duprepair-main-div" className="parent">
          <div id="duprepair-div1" className="dup-table">
            <FormControlLabel
              id="duprepair-label1"
              key={index}
              control={
                <Radio
                  id="duprepair-radio1"
                  name={item.identifiers.worksOrderIdentifier}
                  data-testid={item.identifiers.worksOrderIdentifier}
                  title=""
                  role="radio"
                />
              }
              label=""
              value={item.statuses.status + "&" + item.identifiers.worksOrderIdentifier + "&" +isSubmittedTriage }            
            />
          </div>
          <div
            id="duprepair-div2"
            className="text-base global-txt border-collapse w-full"
          >
            <ReactBootstrap.Table
              bordered
              id="duprepair-table"
              className="border-collapse w-full"
            >
              <TableBody
                id="duprepair-tbbody"
                className="table-spc border-collapse w-full"
              >
                {/* -----------Mobile view -----*/}

                <TableCell
                  id="duprepair-tr1-td1"
                  className="mobile-tablex supp-table text-base global-txt p-3 border border-grey hidden lg:table-cell text-left font-light"
                >
                  <h6>{t("Date_Creation")}</h6>
                  <br></br>
                  {moment(item.raisedDate).format("MMMM Do YYYY, h:mm:ss A")}
                </TableCell>
                <TableCell
                  id="duprepair-tr1-td2"
                  className="mobile-tablex supp-table text-base global-txt p-3 border border-grey hidden lg:table-cell text-left font-light"
                >
                  <h6>{t("High_Level_Description")}</h6>
                  <br></br>
                  {item.job.trade == "Unknown" &&
                  item.channel != RepairChannel.CustomerSelfService &&
                  item.channel != RepairChannel.ERGO &&
                  (appConfigValue == "true" || appConfigValue == "false")
                    ? t("RepairDescription_ErrorMessage")
                    : (item.channel === RepairChannel.CustomerSelfService ||
                        item.channel === RepairChannel.ERGO) &&
                      appConfigValue == "true"
                    ? item.job.description
                    : (item.channel === RepairChannel.CustomerSelfService ||
                        item.channel === RepairChannel.ERGO) &&
                      appConfigValue == "false"
                    ? item.job.description?.split(".")[0]
                    : item.job.trade}
                </TableCell>
                <TableCell
                  id="duprepair-tr1-td3"
                  className="mobile-tablex supp-table text-base global-txt p-3 border border-grey hidden lg:table-cell text-left font-light"
                >
                  <h6>{t("Priority")}</h6>
                  <br></br>
                  {item.statuses.isEmergency === true
                    ? "Emergency"
                    : item.statuses.isEmergency === false
                    ? "Next Available"
                    : item.statuses.isEmergency === true &&
                      item.statuses.isOutOfHours === true
                    ? "Emergency (OOH)"
                    : null}
                </TableCell>
                <TableCell
                  id="duprepair-tr1-td4"
                  className="mobile-tablex supp-table text-base global-txt p-3 border border-grey hidden lg:table-cell text-left font-light"
                >
                  <h6>{t("Status_Text")}</h6>
                  <br></br>
                  {item.statuses.isSubmitted && item.statuses.status === "In Diagnosis" &&
                  (item.statuses.isPotentialDuplicate === true ||
                    item.statuses.isVulnerable === true ||
                    item.job.sorCode === "N/A" ||
                    item.job.sorCode === "NA")
                    ? "Under Review"
                    : item.statuses.status === "In Diagnosis"
                    ? "Draft"
                    : item.statuses.status}
                </TableCell>
                {/* End Mobile View */}
                <TableRow
                  id="duprepair-tr2"
                  key={index}
                  style={{ marginTop: 10 }}
                >
                  <TableCell
                    id="duprepair-tr2-tr1"
                    className="dup-desk-hide supp-table text-base global-txt w-full lg:w-auto p-3 pt-10 lg:pt-3 border border-b border-grey text-left block lg:table-cell relative lg:static"
                  >
                    {moment(item.raisedDate).format("MMMM Do YYYY, h:mm:ss A")}
                  </TableCell>

                  <TableCell
                    id="duprepair-tr2-tr2"
                    className="deskt supp-table text-base global-txt"
                  >
                    {item.job.trade == "Unknown" &&
                    item.channel != RepairChannel.CustomerSelfService &&
                    item.channel != RepairChannel.ERGO &&
                    (appConfigValue == "true" || appConfigValue == "false")
                      ? t("RepairDescription_ErrorMessage")
                      : (item.channel === RepairChannel.CustomerSelfService ||
                          item.channel === RepairChannel.ERGO) &&
                        appConfigValue == "true"
                      ? item.job.description
                      : (item.channel === RepairChannel.CustomerSelfService ||
                          item.channel === RepairChannel.ERGO) &&
                        appConfigValue == "false"
                      ? item.job.description?.split(".")[0]
                      : item.job.trade}
                  </TableCell>

                  <TableCell
                    id="duprepair-tr2-tr3"
                    className=" deskt supp-table text-base global-txt"
                  >
                    {item.statuses.isEmergency === true
                      ? "Emergency"
                      : item.statuses.isEmergency === false
                      ? "Next Available"
                      : item.statuses.isEmergency === true &&
                        item.statuses.isOutOfHours === true
                      ? "Emergency (OOH)"
                      : null}
                  </TableCell>

                  <TableCell
                    id="duprepair-tr2-tr4"
                    className="deskt supp-table text-base global-txt"
                  >
                    {item.statuses.isSubmitted && item.statuses.status === "In Diagnosis" &&
                    (item.statuses.isPotentialDuplicate === true ||
                      item.statuses.isVulnerable === true ||
                      item.job.sorCode === "N/A" ||
                      item.job.sorCode === "NA")
                      ? "Under Review"
                      : item.statuses.status === "In Diagnosis"
                      ? "Draft"
                      : item.statuses.status}
                  </TableCell>
                </TableRow>
              </TableBody>
            </ReactBootstrap.Table>
          </div>
        </div>
      );
    }
  };

  return (
    <>
    <HeaderJourney></HeaderJourney>          
    <div id="duprepair-container" className="container-fluid parent-footer repair-request p-0" role="main">
      {isLoading ? (
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={50}
          width={50}
          timeout={5000}
        />
      ) : (
        <>
        <div className="padding-repair">
          <div id="duprepair-label2"  className="mb-30">
            <PageHeader>
              {t("Duplicate_Repair")}{" "}
            </PageHeader>
          </div>
          <div
            id="duprepair-div3"
            className="mb-4"
          >
            <h6>{t("Duplicate_Repair_Text2")} </h6>
          </div>
           {BlockMsg !== false && error && !disableContinueforEnhancedEligiblityCheck ? (
                GetErrorMessage(errorMessage, true)
              ) : (
                <div></div>
              )}

            

              <div className="table-wid header-table">
                <ReactBootstrap.Table
                  borderless
                  id="duprepair-table"
                  className="table-header-width"
                >
                  <TableBody
                    id="duprepair-tbbody"
                    className="table-spc border-collapse w-full"
                  >
                    <TableRow
                      className="p-3 border border-grey hidden lg:table-cell text-left font-light"
                      id="duprepair-tr1"
                    >
                      <TableCell
                        id="duprepair-tr1-td1"
                        className=" deskt supp-table text-base hidden lg:table-cell padding-cell"
                      >
                        {t("Date_Creation")}
                      </TableCell>

                      <TableCell
                        id="duprepair-tr1-td2"
                        className="deskt supp-table text-base hidden lg:table-cell padding-cell"
                      >
                        {t("High_Level_Description")}
                      </TableCell>

                      <TableCell
                        id="duprepair-tr1-td3"
                        className="deskt supp-table text-base hidden lg:table-cell padding-cell2"
                      >
                        {t("Priority")}
                      </TableCell>

                      <TableCell
                        id="duprepair-tr1-td4"
                        className="deskt supp-table text-base hidden lg:table-cell"
                      >
                        {t("Status_Text")}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </ReactBootstrap.Table>
              </div>
              <div
                id="duprepair-div4"
                data-testid="duplicateRepairs"
                className="repair-text table-wid"
              >
                <div id="duprepair-div5">
                  <RadioGroup
                    id="duprepair-radiogroup"
                    data-testid="duprepair-radiogroup"
                    onChange={handleRadioChange}
                    value={radiovalue}
                    className="question-btn"
                  >
                    {duplicateRepairResult &&
                    duplicateRepairResult?.totalCount > 0
                      ? duplicateRepairResult?.worksOrders
                          ?.sort(
                            (a: any, b: any) =>
                              new Date(b.raisedDate).valueOf() -
                              new Date(a.raisedDate).valueOf()
                          )
                          .map(alldata)
                      : null}

                <FormControlLabel
                  id="duprepair-label1"
                  className="none-div"
                  control={
                    <Radio
                      id="duprepair-radio2"
                      data-testid="NoneOfTheAbove"
                      placeholder="NoneOfTheAbove"
                      aria-label="None of the above"
                    />
                  }
                  label="None of the above"
                  value="I don't think this is a duplicate"
                />
              </RadioGroup>
            </div>
          </div>

{disableContinueforEnhancedEligiblityCheck && (
                  <Message id="velQues-span2" severity="error" className="warning-msg">
                   {t("OrbisBlockMsg1")} <br/>
                   {t("OrbisBlockMsg2")}  <strong className="font-bolder">{t("OrbisBlockNumber")}</strong>  {t("OrbisBlockMsg3")} 
                  </Message> 
 )}
          </div>
        </>
      )}
        <div className="col-md-12 marginTop-auto">
          <div className="row m-0 footer-actionbtn">
            <div className="col btn-top padding-repair mb-0">
              <BackModal id="duprepair-backmodal" back={backButtonHandler} />
              <ExitModalAndSave
                id="duprepair-exitmodal"
                exit={exitButtonHandler}
                saveDraftAndExit={saveDraftAndExit}
                closeAfterSaving={closeAfterSaving}
              />
            </div>

            <div className="col text-end pt-24 padding-repair">
              <Button
                id="duprepair-btn1"
                onClick={callPageHandler}
                disabled={BlockMsg === false ? true : isDisabled || disableContinueforEnhancedEligiblityCheck? true: false}
                className="primary-btn"
              >
                {t("continuen_button")}
              </Button>
            </div>
          </div>
        </div>
        {isResumeLoading ? (
          <div>
            <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};
export default DuplicateRepair;
