import React, { useEffect, useState } from "react";
import { RepairStatusField } from "../../models/Repair/Repair";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import staticSortableFields from "../../models/Repair/SortableFields";
import { PremisesType } from "../../models/Customer/Premises";
import Repair, { RepairsResponse, RepairStatus } from "../../models/Repairs";
import * as api from "../../api";
import InfiniteScroll from "react-infinite-scroll-component";
import filtericon from "../../assets/img/filtericon.png";
import "./index.scss";
import Clear from "../../assets/img/Clear.png";
import {
  MobilePagination,
  Pagination,
  Paginationbox,
  Search,
  WebPagination
} from "./components";
import { RouteComponentProps } from "react-router-dom";

import {
  searchForRepairs,
  getContractorsData,
  repairHistoryCurrentPage,
  repairHistoryCurrentResultsPerPage,
  repairDefaultAssocitaedAsset,
  repairHistoryData,
  repairSearchTerm,
  defaultSearchRepairStatus,
  defaultSearchArea,
  defaultSearchSortByOrder
} from "../../ducks/redux/actions/historicRepair";
import {
  showMenuTabs,
  showFooter,
  userRepairWorkOrder,
} from "../../ducks/redux/actions/userChoices";
import RepairSelectOptions from "../../models/Repair/RepairSelectOptions";
import {
  AssocitaedAssetsType,
  RelatedPremisesType,
} from "../../models/Property/AdminUnits";
import {
  formatDate,
  formatDateWithMonthInLetters,
  isNullOrUndefined,
} from "../../utils/formatting";
import Tenancies from "../../models/Customer/Tenancies";
import { Offcanvas } from "react-bootstrap";
import { CustomerResponse, TenancySummary } from "../../models/Customer";
import { RepairChannel } from "../../models/Repair/RepairChannel";
import LoadingSpinner from "../../storybook/LoadingSpinner";
import { workOrderRepairObject } from "../../ducks/redux/actions/getAddress";

interface HistoricRepairs1Props extends RouteComponentProps<any> { }
const HistoricRepairs: React.FC<HistoricRepairs1Props> = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [defaultSortOrder, setdefaultSortOrder] = useState("raisedDate desc");
  const [defaultRepairStatus, setdefaultRepairStatus] = useState("All");
  const [defaultassocitaedAsset, setdefaultassocitaedAsset] = useState("");
  const [associtaedAsset, setassocitaedAsset] = useState("");
 
  const [searchTerm, setsearchTerm] = useState("");
  const [priority, setpriority] = useState("");
  
  const [selectedArea, setselectedArea] = useState("All");
  const [Area, setArea] = useState(false);
  const [totalCount, settotalCount] = useState(0);
  const [currentPage, setcurrentPage] = useState(0);
  const [resultsPerPage, setresultsPerPage] = useState(10);
  const [skip, setskip] = useState(0);
  
 
  const [isLoading, setIsLoading] = useState(true);
  
  const assets = (state: any) => state?.addresses?.customerassociatedassets;
  const customerID = useSelector((state: any) => state.addresses?.customerid);
  
  const appConfigValue = useSelector(
    (state: any) => state?.addresses?.toggleValue
  );

  const array: Repair[] = [];

  const [CustResponse, setCustomerResponse] = useState<CustomerResponse>();
  const arrayRepairStatus: RepairStatus[] = [];
  const arrayArea: Array<{ key: string; value: string }> = [];
  const [premisesListArea, setpremisesListArea] = useState(arrayArea);
  const [associtaedAssetList, setassocitaedAssetsList] = useState(arrayArea);
  const sortOptions = staticSortableFields.map((f) => ({
    key: f.displayName,
    value: f.sortableKey,
  }));
  let PropertyId = useSelector(
    (state: any) => state.userChoicesReducer.userChosenPropertyId
  );
  const repairStatusSelectOptions = RepairSelectOptions.map((f) => ({
    key: f.key,
    value: f.value,
  }));
  const repairHistoryDataValue = useSelector(
    (state: any) => state.historicRepairReducer?.repairHistoryData
  );
  const repairHistoryCurrentPageValue = useSelector(
    (state: any) => state.historicRepairReducer?.repairHistoryCurrentPage
  );
  const repairHistoryResultsPerPageValue = useSelector(
    (state: any) => state.historicRepairReducer?.repairHistoryCurrentResultsPerPage
  );
  const repairDefaultAssocitaedAssetValue = useSelector(
    (state: any) => state.historicRepairReducer?.repairDefaultAssocitaedAsset
  );
  const repairSearchTermValue = useSelector(
    (state: any) => state.historicRepairReducer?.repairSearchTerm
  );

  const defaultSearchRepairStatusValue = useSelector(
    (state: any) => state.historicRepairReducer?.defaultSearchRepairStatus
  );
  const defaultSearchAreaValue = useSelector(
    (state: any) => state.historicRepairReducer?.defaultSearchArea
  );
  const defaultSearchSortByOrderValue = useSelector(
    (state: any) => state.historicRepairReducer?.defaultSearchSortByOrder
  );

  const [repairs, setRepairList] = useState(array);
  const [contractorsList, setContractorsList] = useState([]);

 
  let premisesId = "";
  let associtaedAssets: any[] = [];
 

  useEffect(() => {
    dispatch(showMenuTabs(true));
    (async () => {
      try {
        setIsLoading(true);
        const contractorResponse = await api.getContractors();
        setContractorsList(contractorResponse);
        dispatch(getContractorsData(contractorResponse));
        const customerResponse = await api.getCustomer(customerID);
        setCustomerResponse(customerResponse);

        const activeTenanciesSummaries =
          customerResponse.tenanciesSummaries.filter(
            (ts) =>
              ts.tenancySummary.statuses.find(
                (s) => s.type === "isTenancyActive"
              )?.value === true
          );
        const activeTenanciesPremisesIds: Tenancies[] = activeTenanciesSummaries
          .map((ts: any) => ts.tenancySummary.premisesIds)
          .flat();
        const promises = activeTenanciesPremisesIds.map((item: any) => {
          return api.getAssociatedAssets(item);
        });
        Promise.all(promises).then(async (results) => {
          //Filter results as per premisesid

          activeTenanciesPremisesIds?.map(async (assetid: any) => {
            results.forEach((element) => {
              const resultF = element.filter(
                (addressdata) => addressdata.id === assetid
              );

              if (resultF !== null) associtaedAssets.push(resultF);
            });
          });
          const associtaedAssetsList: AssocitaedAssetsType[] = [];

          associtaedAssets?.map((addressdata: any) => {
            addressdata?.map((address: any) => {
              const associtaedAssetsTypeEntry: AssocitaedAssetsType =
                new AssocitaedAssetsType();

              associtaedAssetsTypeEntry.key = address?.id;
              associtaedAssetsTypeEntry.value =
                address?.addresses?.postalAddresses[0]?.formattedAddress;
              associtaedAssetsList.push(associtaedAssetsTypeEntry);
            });
          });
          if (associtaedAssetsList?.length > 1) {
            setArea(true);
          } else {
            setArea(false);
          }

          PropertyId = repairDefaultAssocitaedAssetValue ?? PropertyId;

          if (PropertyId != "") {
            setdefaultassocitaedAsset(PropertyId);
            setassocitaedAsset(PropertyId);
            dispatch(repairDefaultAssocitaedAsset(PropertyId));
          } else {
            PropertyId = associtaedAssetsList[0].key;
            setdefaultassocitaedAsset(associtaedAssetsList[0].key);
            setassocitaedAsset(associtaedAssetsList[0].key);
            dispatch(repairDefaultAssocitaedAsset(associtaedAssetsList[0].key));
          }

          setassocitaedAssetsList(associtaedAssetsList);

          const premisesList: RelatedPremisesType[] = [];
          const premisesEntry: RelatedPremisesType = new RelatedPremisesType();
          let propertiesIds: string[] = [];
          let addressIds: string[] = [];
          premisesEntry.premisesType = PremisesType.Default;
          premisesEntry.premisesTypeId = PremisesType.Default;
          premisesList.push(premisesEntry);
          const addressesData = await api.getAssociatedAssets(PropertyId);

          addressesData.forEach((element) => {
            const premisesEntry: RelatedPremisesType = new RelatedPremisesType();
            premisesEntry.premisesType = element.assetType as PremisesType;
            premisesEntry.premisesTypeId = element.id;
            premisesList.push(premisesEntry);
            propertiesIds.push(element.id);
          });
          const areaOptions = premisesList.map((f) => ({
            key: f.premisesType,
            value: f.premisesTypeId,
          }));

          setpremisesListArea(areaOptions);

          if (!isNullOrUndefined(repairHistoryDataValue?.repairHistoryOperation)) {
            const page = repairHistoryCurrentPageValue,
              resPerPage = repairHistoryResultsPerPageValue;
            setIsLoading(true);
            setcurrentPage(page);
            setresultsPerPage(resPerPage);
            PropertyId = repairDefaultAssocitaedAssetValue;

            setskip(resPerPage * page);
            setsearchTerm(repairSearchTermValue);
            setdefaultSortOrder(defaultSearchSortByOrderValue);
            const _repairSearchTermValue = (repairSearchTermValue.length > 0) ? repairSearchTermValue : "";
            const _defaultSearchRepairStatusValue = (defaultSearchRepairStatusValue.length > 0) ? defaultSearchRepairStatusValue : "All"
            const _defaultSearchAreaValue = (defaultSearchAreaValue.length > 0) ? defaultSearchAreaValue : "All"
            const _defaultSearchSortByOrderValue = (defaultSearchSortByOrderValue.length > 0) ? defaultSearchSortByOrderValue : "raisedDate desc"
            fetchGetRepairsData(
              resPerPage,
              _defaultSearchSortByOrderValue,
              _defaultSearchRepairStatusValue,
              resPerPage * page,
              _repairSearchTermValue,
              priority,
              _defaultSearchAreaValue,
              PropertyId
            );
            dispatch(repairSearchTerm(""));
            dispatch(defaultSearchRepairStatus(""));
            dispatch(defaultSearchArea(""));
            dispatch(defaultSearchSortByOrder(""));
          }
          else {
            const status = RepairStatusField.Appointed +
              "," +
              RepairStatusField.PendingApproval +
              "," +
              RepairStatusField.OnHold +
              "," +
              RepairStatusField.Cancelled +
              "," +
              RepairStatusField.Completed +
              "," +
              RepairStatusField.Unappointed +
              "," +
              RepairStatusField.InProgress +
              "," +
              RepairStatusField.InDiagnosis +
              "," +
              RepairStatusField.ReportedToEMA +
              "," +
              RepairStatusField.CatalystTier2Triage +
              "," +
              RepairStatusField.CatalystEMATriage +
              "," +
              RepairStatusField.CatalystDefectTriage;

            let tenancyStartDate =
              activeTenanciesSummaries[0]?.tenancySummary.tenancyStartDate;

            if (tenancyStartDate == undefined) {
              tenancyStartDate = "";
            }

            const queryParams = {
              propertiesIds,
              orderBy: "raisedDate desc",
              raisedSince: tenancyStartDate,
              priority: "",
              status,
              description: "",
            };

            const worksOrdersRepair = await api.getWorksOrdersApi(queryParams);
            Promise.all([worksOrdersRepair]).then((results: any) => {
              results.map((items: any) => {
                const data = new RepairsResponse(items);
                setRepairList(data.models);
                settotalCount(data.totalCount);               
                setIsLoading(false);
                dispatch(searchForRepairs(data));
              });
            });
            const ResumeRepairWO = await api.getWorkOrdersForResumeRepair(queryParams);
            Promise.all([ResumeRepairWO]).then((results: any) => {
              setIsLoading(false)
              results.map((items: any) => {
                const data = new RepairsResponse(items);
                dispatch(userRepairWorkOrder(data));
              });
            });
          }

        });

      } catch (e) {
        setIsLoading(false);
        console.error(e);
        props.history.push("/genericerror");
      }

    })();
  }, []);

  const onChangePage = (e: any, page: number) => handleChangePage(page);

  //#region "functions"
  const repairHistoryHandler = (e: HTMLDivElement) => {
    const historyData = CreateHistoryData(e)    
    dispatch(repairHistoryData(historyData));
    dispatch(showMenuTabs(false));  
    props.history.push("/repairhistorydetails");
  };

  const CreateHistoryData = (e: any) => {
    return {           
      RepairHistoryRaisedDate: isNullOrUndefined(e.raisedDate) ? "-" : formatDate(e.raisedDate),
      RepairHistoryArea: isNullOrUndefined(e.area) ? "-" : e.area,
      RepairHistoryDescription: e.description?.split(".")[0] || '',
      RepairHistoryAppointmentDate: formatDateWithMonthInLetters(e.appointmentDate || ''),
      RepairHistoryPriority: e.priority || '',
      RepairHistoryContractor: e.contractor || '',
      RepairHistoryworkOrderID: e.id || '',
      RepairHistorystatus: e.status || '',
      RepairHistoryrepairReference: e.jobNo || '',
      RepairHistoryarea: e.area || '',
      RepairHistoryfullDescription: e.description || '',     
      RepairHistoryContractorsList: contractorsList,
      RepairHistorysiteIdentifier: e.siteIndentifire || "",
      RepairHistoryassocitaedAsset: defaultassocitaedAsset,     
      repairHistoryAppointmentStartDate: e.appointmentStartDate,
      repairHistoryAppointmentEndDate: e.appointmentEndDate,     
      repairHistoryWorkOrderID: e.id,     
    };
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent) => {
    const resultsPerPageUpdated = (e.target as any).value;
    setIsLoading(true);
    setresultsPerPage(resultsPerPageUpdated);
    dispatch(repairHistoryCurrentResultsPerPage(resultsPerPageUpdated));
    setskip(0);
    setcurrentPage(0);
    fetchGetRepairsData(
      resultsPerPageUpdated,
      defaultSortOrder,
      defaultRepairStatus,
      0,
      searchTerm,
      priority,
      selectedArea,
      defaultassocitaedAsset
    );
  };

  const handleSubmit = (s: string): void => {

    if (s !== "") { setIsLoading(true); }
    setsearchTerm(s)
    dispatch(repairSearchTerm(s));
    dispatch(defaultSearchRepairStatus(""));
    dispatch(defaultSearchArea(""));
    fetchGetRepairsData(
      resultsPerPage,
      defaultSortOrder,
      defaultRepairStatus,
      skip,
      s,
      priority,
      selectedArea,
      defaultassocitaedAsset
    );
  };
  const handleResetSubmit = (s: string): void => {
    setcurrentPage(0);
    setsearchTerm("")
    dispatch(repairSearchTerm(""));
    dispatch(defaultSearchRepairStatus(""));
    dispatch(defaultSearchArea(""));
    fetchGetRepairsData(
      resultsPerPage,
      defaultSortOrder,
      defaultRepairStatus,
      skip,
      "",
      priority,
      selectedArea,
      defaultassocitaedAsset
    );
  };
 
  const handleChangeSortOrder = (e: { target: { value: any } }) => {
    setdefaultSortOrder(e.target.value);
    setIsLoading(true);
    dispatch(defaultSearchSortByOrder(e.target.value));
    dispatch(repairSearchTerm(""));
    dispatch(defaultSearchRepairStatus(""));
    dispatch(defaultSearchArea(""));
    fetchGetRepairsData(
      resultsPerPage,
      e.target.value,
      defaultRepairStatus,
      skip,
      searchTerm,
      priority,
      selectedArea,
      defaultassocitaedAsset
    );
  };
  const handleChangeAssocitae = (e: { target: { value: any } }) => {
    setselectedArea("All");
    setdefaultassocitaedAsset(e.target.value);
    dispatch(repairDefaultAssocitaedAsset(e.target.value));
    fetchGetRepairsData(
      resultsPerPage,
      defaultSortOrder,
      defaultRepairStatus,
      skip,
      searchTerm,
      priority,
      selectedArea,
      e.target.value
    );
  };
  const handleChangeRepairStatus = (e: { target: { value: any } }) => {
    setdefaultRepairStatus(e.target.value);
  };

  const handleChangeArea = (e: { target: { value: any } }) => {
    setselectedArea(e.target.value);
  };

  const handleReset = () => {
    setIsLoading(true);
    setresultsPerPage(10);
    setdefaultSortOrder("raisedDate desc");
    setdefaultRepairStatus("All");
    setskip(0);
    setsearchTerm("");
    dispatch(repairSearchTerm(""));
    dispatch(defaultSearchRepairStatus(""));
    dispatch(defaultSearchArea(""));
    setselectedArea("All");
    setdefaultassocitaedAsset(associtaedAsset);
    fetchGetRepairsData(
      10,
      "raisedDate desc",
      "All",
      0,
      "",
      "",
      "All",
      associtaedAsset
    );
  };
  const handleFilterSubmit = () => {
    setIsLoading(true);
    setskip(0);
    setcurrentPage(0);
    dispatch(repairSearchTerm(""));
    dispatch(defaultSearchRepairStatus(defaultRepairStatus));
    dispatch(defaultSearchArea(selectedArea));
    fetchGetRepairsData(
      resultsPerPage,
      defaultSortOrder,
      defaultRepairStatus,
      0,
      searchTerm,
      priority,
      selectedArea,
      defaultassocitaedAsset
    );
    setShow(false);
  };
  const handleChangePage = (page: number) => {
    setIsLoading(true);

    setcurrentPage(page);
    setskip(resultsPerPage * page);
    dispatch(repairHistoryCurrentPage(page));
    fetchGetRepairsData(
      resultsPerPage,
      defaultSortOrder,
      defaultRepairStatus,
      resultsPerPage * page,
      searchTerm,
      priority,
      selectedArea,
      defaultassocitaedAsset
    );
  };
  const fetchData = () => {
    // Called for Mobile view infinite scroll functionality
    setresultsPerPage(100);
    dispatch(repairHistoryCurrentResultsPerPage(totalCount));
    dispatch(repairHistoryCurrentPage(0));
    fetchGetRepairsData(
      totalCount,
      defaultSortOrder,
      defaultRepairStatus,
      skip,
      searchTerm,
      priority,
      selectedArea,
      defaultassocitaedAsset
    );
  };
 
  const fetchGetRepairsData = (
    resultsPerPage: number,
    defaultSortOrder: string,
    defaultRepairStatus: string,
    skip: number,
    searchTerm: string,
    priority: string,
    selectedArea: string,
    defaultassocitaedAsset: string
  ) => {
    let status = "";
    const propertiesIds: string[] = [];
    if (defaultRepairStatus == "Active") {
      status =
        RepairStatusField.Appointed +
        "," +
        RepairStatusField.PendingApproval +
        "," +
        RepairStatusField.OnHold +
        "," +
        RepairStatusField.Unappointed +
        "," +
        RepairStatusField.InProgress +
        "," +
        RepairStatusField.InDiagnosis +
        "," +
        RepairStatusField.CatalystTier2Triage +
        "," +
        RepairStatusField.CatalystEMATriage +
        "," +
        RepairStatusField.CatalystDefectTriage;
    }
    else if (defaultRepairStatus == "Unappointed") {
      status =
        RepairStatusField.Unappointed +
        "," +
        RepairStatusField.ReportedToEMA
    }
    else if (defaultRepairStatus == RepairStatusField.RepairStatusFieldCatalyst) {
      status =
        RepairStatusField.CatalystDefectTriage +
        "," +
        RepairStatusField.CatalystEMATriage +
        "," +
        RepairStatusField.CatalystTier2Triage
    }
    else if (defaultRepairStatus == "All") {
      status =
        RepairStatusField.Appointed +
        "," +
        RepairStatusField.PendingApproval +
        "," +
        RepairStatusField.OnHold +
        "," +
        RepairStatusField.Cancelled +
        "," +
        RepairStatusField.Completed +
        "," +
        RepairStatusField.Unappointed +
        "," +
        RepairStatusField.InProgress +
        "," +
        RepairStatusField.InDiagnosis +
        "," +
        RepairStatusField.CatalystTier2Triage +
        "," +
        RepairStatusField.CatalystEMATriage +
        "," +
        RepairStatusField.CatalystDefectTriage;
    } else {
      const RepairStatusEnum = defaultRepairStatus as RepairStatusField;
      status = RepairStatusEnum;
    }
    try {
      (async () => {
        let propertiesIds: string[] = [];
        if (selectedArea == "All") {
          const AssetList = await api.getAssociatedAssets(
            defaultassocitaedAsset
          );
          const premisesList: RelatedPremisesType[] = [];
          const premisesEntry: RelatedPremisesType = new RelatedPremisesType();

          let addressIds: string[] = [];
          premisesEntry.premisesType = PremisesType.Default;
          premisesEntry.premisesTypeId = PremisesType.Default;
          premisesList.push(premisesEntry);

          AssetList?.forEach((element: any) => {
            const premisesEntry: RelatedPremisesType =
              new RelatedPremisesType();
            premisesEntry.premisesType = element.assetType as PremisesType;
            premisesEntry.premisesTypeId = element.id;
            premisesList.push(premisesEntry);
            premisesId = element.id;
            propertiesIds.push(premisesId);
          });

          const areaOptions = premisesList.map((f) => ({
            key: f.premisesType,
            value: f.premisesTypeId,
          }));
        } else {
          propertiesIds.push(selectedArea);
        }
        let tenancyStartDate = "";
        const activeTenanciesSummaries =
          CustResponse?.tenanciesSummaries.filter(
            (ts) =>
              ts.tenancySummary.statuses.find(
                (s) => s.type === "isTenancyActive"
              )?.value === true
          );

        if (
          activeTenanciesSummaries != undefined &&
          activeTenanciesSummaries != null
        ) {
          let TenancySummaryEntry = activeTenanciesSummaries.find(
            (st) => st.tenancySummary.premisesIds[0] === defaultassocitaedAsset
          );

          if (TenancySummaryEntry != undefined && TenancySummaryEntry != null) {
            if (TenancySummaryEntry?.tenancySummary.tenancyStartDate != null) {
              tenancyStartDate =
                TenancySummaryEntry?.tenancySummary.tenancyStartDate;
            }
          }
        }
        if (api.getRepairs) {
          const propertyRepairs = await api.getRepairs({
            resultsPerPage,
            orderBy: defaultSortOrder,
            status,
            skip,
            searchTerm,
            raisedSince: tenancyStartDate,
            priority,
            propertiesIds,
          });
          setRepairList(propertyRepairs.models);
          settotalCount(propertyRepairs.totalCount);
          dispatch(searchForRepairs(propertyRepairs));
          setIsLoading(false);
          if (!isNullOrUndefined(repairHistoryDataValue?.repairHistoryOperation)) {

            const isMobileView = document.getElementById('scrollableDiv')?.offsetParent != null;
            const parentHTMLBody = isMobileView ? document.getElementById('scrollableDiv') : document.getElementById('paginationDiv');

            const repairHistoryWorkOrderID = "#hisRepDet-p1" + repairHistoryDataValue.repairHistoryWorkOrderID;
            const el = parentHTMLBody?.querySelector(repairHistoryWorkOrderID) as HTMLElement | null;
            el?.click();
          }
        }
      })();
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };
  const backButtonHandler = () => {
    props.history.push("/customer360View");
  };

  const callRaiseRepair = async () => {
    dispatch(showMenuTabs(false));
    dispatch(showFooter(false));
    dispatch(workOrderRepairObject(null));
    props.history.push("/repairproperty");
  };

  //#endregion "functions"

  return (
    <>
      <WebPagination>
        <div role="main" className="container-fluid p-0">
          {!isLoading ? (
            <div id="paginationDiv" className="padding-repair position-relative">
              <button onClick={callRaiseRepair} className="primary-btn raise-repair">{t("Create_repair")}</button>
              <h1 className="rephistory-heading">{t("Repair_history")}</h1>
              <div className="row">
                {Area ? (
                  <>
                    <div className="col-md-6 pe-0 history-dd">
                      <select
                        data-testid="repairs-search-address1"
                        className="text-select repair-dd"
                        value={defaultassocitaedAsset}
                        id="repairs-search-address1"
                        onChange={handleChangeAssocitae}
                      >
                        {associtaedAssetList.map((e, key) => {
                          return (
                            <option key={key} value={e.key}>
                              {e.value}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </>
                ) : (
                  <div
                    className="col-md-6 pe-0 history-dd"
                    data-testid="repairs-search-address1"
                    id="repairs-search-address1"
                  >
                    {associtaedAssetList.map((e, key) => {
                      return (
                        <div className="text-tenancy tenancy-padding">
                        {e.value}
                      </div>
                      );
                    })}
                  </div>
                )}
                <div className="row search-field">
                  <div className="col-md-6 pe-0">
                    <div id="custom-search-input">
                      <div id="searchId">
                        <label id="searchLabel" className="w-100">
                          <Search
                            defaultValue={searchTerm}
                            data-testid="repairs-search"
                            onSubmit={handleSubmit}
                            placeholder="Search"
                            onClear={handleResetSubmit}
                            className="search-input"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-1">
                    <select
                      data-testid="repairs-search-sort1"
                      value={defaultSortOrder}
                      id="repairs-search-sort1"
                      onChange={handleChangeSortOrder}
                      aria-label="Repair Search"
                      className="sorting-dd"
                    >
                      {staticSortableFields.map((e, key) => {
                        return (
                          <option key={key} value={e.sortableKey}>
                            {e.displayName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-2">
                    <button
                      data-testid="repairs-search-Filter"
                      onClick={handleShow}
                      onKeyDown={handleShow}
                      className="filter-dd cursor-pointer"
                    >
                      {t("Filter")}
                      <img src={filtericon} alt="Filter" className="repair-filtericon" />
                    </button>
                  </div>
                  <div className="col-3">
                    <button
                      onClick={handleReset}
                      onKeyDown={handleReset}
                      data-testid="repairs-search-Reset"
                      className="filter-dd clear-filter cursor-pointer"
                    >
                      {t("Clear_filters")}
                    </button>
                  </div>
                </div>

                {/* < -- historic repair details start web view --> */}
                <div className="row history-table">
                  <div className="col-md-12">
                    <div className="history-result">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">{t("historic_repairs_title")}</th>
                            <th scope="col" className="histoy-desc">{t("historic_repairs_description")}</th>
                            <th scope="col" className="width-20">{t("historic_repairs_area")}</th>
                            <th scope="col">{t("historic_repairs_dates")}</th>
                            <th scope="col" className="width-20">{t("historic_repairs_status")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!repairs?.length ? (
                            <tr>
                              <td colSpan={5} className="text-center" data-testid='repairs_search_no_results'>
                                {t("repairs_search_no_results")}
                              </td>
                            </tr>
                          ) : (
                            repairs.map((el: any) => (
                              <tr id={"Repair_Id_tr_" + el.id} onClick={() => repairHistoryHandler(el)} >
                                <td id={"Repair_Id_" + el.id} >

                                  <strong>
                                    <span>
                                      {" "}
                                      {isNullOrUndefined(el.jobNo) ? " " : el.jobNo}{" "}
                                    </span>
                                  </strong>
                                </td>

                                <td>
                                  {el.trade == "Unknown" &&
                                    el.channel != RepairChannel.CustomerSelfService &&
                                    el.channel != RepairChannel.ERGO &&
                                    (appConfigValue == "true" || appConfigValue == "false")
                                    ? t("RepairDescription_ErrorMessage")
                                    : (el.channel === RepairChannel.CustomerSelfService ||
                                      el.channel === RepairChannel.ERGO) && appConfigValue == "true"
                                      ? el.description
                                      : (el.channel === RepairChannel.CustomerSelfService ||
                                        el.channel === RepairChannel.ERGO) && appConfigValue == "false"

                                        ? el.description?.split(".")[0]
                                        : el.trade}
                                </td>

                                <td>
                                  {isNullOrUndefined(el.area) ? "-" : el.area}
                                </td>

                                <td>
                                  {isNullOrUndefined(el.raisedDate)
                                    ? "-"
                                    : formatDate(el.raisedDate)}{" "}
                                </td>

                                <td>
                                  {el.status === "In Diagnosis"
                                    ? "Draft"
                                    : (el.status === RepairStatusField.Unappointed || el.status === "Reported to EMA")
                                      ? RepairStatusField.Assignedcontractor
                                      : (el.status === RepairStatusField.CatalystTier2Triage || el.status === RepairStatusField.CatalystDefectTriage || el.status === RepairStatusField.CatalystEMATriage) ? RepairStatusField.RepairStatusFieldCatalyst : el.status}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* < -- Side Filter view --> */}

                <Offcanvas placement="end" show={show} onHide={handleClose}>
                  <div id="mySidenav">
                    <a onClick={handleClose} onKeyDown={handleClose}>
                      &times;
                    </a>
                    <div>
                      <div>
                        <Offcanvas.Header>
                          <div>
                            <h2>
                              {t("Filter")}
                            </h2>
                          </div>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <div>
                            <div>
                              <strong>{t("historic_repairs_area")}</strong>
                            </div>
                            <select
                              data-testid="repairs-search-property1"
                              value={selectedArea}
                              id="repairs-search-property1"
                              onChange={handleChangeArea}
                            >
                              {premisesListArea.map((e, key) => {
                                return (
                                  <option key={key} value={e.value}>
                                    {e.key}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          <div>
                            <div>
                              <strong>{t("historic_repairs_status")}</strong>
                            </div>
                            <select
                              data-testid="repairs-search-status1"
                              value={defaultRepairStatus}
                              id="repairs-search-status1"
                              onChange={handleChangeRepairStatus}
                            >
                              {repairStatusSelectOptions.map((e, key) => {
                                return (
                                  <option key={key} value={e.value}>
                                    {e.key}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          <div>
                            <button
                              onClick={handleFilterSubmit}
                              data-testid="repairs-search-button"
                              className="primary-btn"
                            >
                              Search
                            </button>
                          </div>
                        </Offcanvas.Body>
                      </div>
                    </div>
                  </div>
                </Offcanvas>
                {/* < -- Pagination view --> */}
                <div>
                  <Paginationbox>
                    <label>
                      <Pagination
                        colSpan={7}
                        count={totalCount}
                        page={currentPage}
                        rowsPerPage={resultsPerPage}
                        rowsPerPageOptions={[10, 20, 100]}
                        onPageChange={onChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </label>
                  </Paginationbox>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </WebPagination>
      <MobilePagination>
        <div
          id="scrollableDiv"
          style={{
            height: 800,
            overflowY: "scroll",
            overflow: "auto",
            flexDirection: "column",
          }}
          data-testid="scrollableDiv" className="mob-view-only padding-repair "
        >
          <InfiniteScroll
            dataLength={repairs?.length}
            next={fetchData}
            style={{ display: "flex", flexDirection: "column" }}
            hasMore={true}
            loader={" "}
            scrollableTarget="scrollableDiv"
          >
            <div role="main">
              {!isLoading ? (
                <div>
                  <p id="backmodal-p" className="d-none">
                    <a onClick={backButtonHandler} onKeyDown={backButtonHandler} >Back
                    </a>
                  </p>

                  <h1 className="rephistory-heading">{t("Repair_history")}</h1>

                  <div>
                    <div className="position-relative">
                      <button onClick={callRaiseRepair} className="primary-btn raise-repair">{t("Create_repair")}</button>
                      {Area ? (
                        <select
                          data-testid="repairs-search-address"
                          value={defaultassocitaedAsset}
                          id="repairs-search-address"
                          className="text-select repair-dd"
                          onChange={handleChangeAssocitae}
                        >
                          {associtaedAssetList.map((e, key) => {
                            return (
                              <option key={key} value={e.key}>
                                {e.value}
                              </option>
                            );
                          })}
                        </select>
                      ) : (
                        <div
                          data-testid="repairs-search-address1"
                          id="repairs-search-address1"
                        >
                          {associtaedAssetList.map((e, key) => {
                            return (
                              <div className="text-tenancy pt-60">
                              {e.value}
                            </div>
                            );
                          })}
                        </div>
                      )}
                    </div> 
                    <div>  
                      <div id="custom-search-input">
                        <div id="searchId">
                          <label id="searchLabel" className="w-100">
                            <Search
                              defaultValue={searchTerm}
                              onSubmit={handleSubmit}
                              placeholder="Search"
                              onClear={handleResetSubmit}
                              className="search-input"
                            />
                          </label>
                        </div>
                      </div>

                      {/* ======= Sort by dropdown ========= */}

                      <div className="pb-24">
                        <select
                          data-testid="repairs-search-sort"
                          value={defaultSortOrder}
                          id="repairs-search-sort"
                          onChange={handleChangeSortOrder}
                          aria-label="Repair Search"
                          className="sorting-dd"
                        >
                          {staticSortableFields.map((e, key) => {
                            return (
                              <option key={key} value={e.sortableKey}>
                                {e.displayName}
                              </option>
                            );
                          })}
                        </select>
                        <a
                          data-testid="repairs-search-Filter1"
                          onClick={handleShow}
                          className="mobile-filtericon"
                          onKeyDown={handleShow}
                        >
                          <img src={filtericon} alt="Filter" className="cursor-pointer" />
                        </a>
                      </div>                     
                    </div>

                    {/* < -- historic repair details start web view --> */}

                    {!repairs?.length ? (
                      <div>
                        {t("repairs_search_no_results")}
                      </div>
                    ) : (
                      repairs.map((el) => (

                        <div className={el.status === "Completed" ? "status-green" : el.status === "Cancelled" ? "status-red" : "status-yellow"}>
                          <div id={"Repair_Id_" + el.id} className="row">
                            <div className="col-4 pe-0">
                              <h5>
                                {isNullOrUndefined(el.jobNo) ? " " : el.jobNo}
                              </h5>
                            </div>
                            <div className="col-8 pe-0 text-end pt-1">
                              <p>
                                {el.status === "In Diagnosis"
                                  ? "Draft"
                                  : (el.status === RepairStatusField.Unappointed || el.status === "Reported to EMA")
                                    ? RepairStatusField.Assignedcontractor
                                    : (el.status === RepairStatusField.CatalystTier2Triage || el.status === RepairStatusField.CatalystDefectTriage || el.status === RepairStatusField.CatalystEMATriage) ? RepairStatusField.RepairStatusFieldCatalyst : el.status}
                              </p>
                            </div>
                          </div>

                          <div>
                            <h6>{t("historic_repairs_description")}</h6>
                            <p>{el.trade == "Unknown" &&
                              el.channel != RepairChannel.CustomerSelfService &&
                              el.channel != RepairChannel.ERGO &&
                              (appConfigValue == "true" || appConfigValue == "false")
                              ? t("RepairDescription_ErrorMessage")
                              : (el.channel === RepairChannel.CustomerSelfService ||
                                el.channel === RepairChannel.ERGO) && appConfigValue == "true"
                                ? el.description
                                : (el.channel === RepairChannel.CustomerSelfService ||
                                  el.channel === RepairChannel.ERGO) && appConfigValue == "false"

                                  ? el.description?.split(".")[0]

                                  : el.trade}
                            </p>

                            <h6>{t("historic_repairs_dates")}</h6>
                            <p>{isNullOrUndefined(el.raisedDate)
                              ? "-"
                              : formatDate(el.raisedDate)}
                            </p>
                            <h6>{t("historic_repairs_area")}</h6>
                            <p>{isNullOrUndefined(el.area) ? "-" : el.area}</p>
                          </div>
                        <button onClick={() => repairHistoryHandler(el)} className="secondary-ghost-btn mt-24 w-100" id={"Repair_Id_button_" + el.id} >{t("View_details")}</button>
                        </div>
                      ))
                    )}

                    {/* < -- Side Filter view --> */}

                    <Offcanvas placement="end" show={show} onHide={handleClose}>
                      <div id="mySidenav" className="filter-section">
                            <Offcanvas.Header>
                                <h4>
                                  {t("Filter")} 
                                  <span>
                                  <img src={Clear} alt="Close" className="cursor-pointer" onClick={handleClose} onKeyDown={handleClose} />
                                  </span>
                                </h4>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                  <h6 className="pt-30">{t("historic_repairs_area")}</h6>
                                <select
                                  data-testid="repairs-search-property"
                                  value={selectedArea}
                                  id="repairs-search-property"
                                  onChange={handleChangeArea}
                                  className="mt-12 sorting-dd"
                                >
                                  {premisesListArea.map((e, key) => {
                                    return (
                                      <option key={key} value={e.value}>
                                        {e.key}
                                      </option>
                                    );
                                  })}
                                </select>

                                  <h6 className="pt-16">{t("historic_repairs_status")}</h6>
                                <select
                                  data-testid="repairs-search-status"
                                  value={defaultRepairStatus}
                                  id="repairs-search-status"
                                  onChange={handleChangeRepairStatus}
                                  className="mt-12 sorting-dd"
                                >
                                  {repairStatusSelectOptions.map((e, key) => {
                                    return (
                                      <option key={key} value={e.value}>
                                        {e.key}
                                      </option>
                                    );
                                  })}
                                </select>

                              <div className="pt-20 text-end">
                                <button
                                  onClick={handleFilterSubmit}
                                  data-testid="repairs-search-button1"
                                  className="primary-btn"
                                >
                                {t("Search")}  
                                </button>
                              </div>
                            </Offcanvas.Body>
                      </div>
                    </Offcanvas>
                  </div>
                </div>
              ) : (
                <div className="text-center">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </InfiniteScroll>
        </div>
      </MobilePagination>
    </>
  )
}

export default HistoricRepairs;
